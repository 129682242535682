(function () {
	'use strict';

	angular.module('l4b')
		.factory('StorageService', StorageService);

	StorageService.$inject = ['Constants'];

	function StorageService(Constants) {

		var service = {
			set: set,
			get: get,
			getDefault: getDefault,
			getRawAll: getRawAll,
			remove: remove,
			removeAllSession: removeAllSession,
			getRemove: getRemove,
			localSet: localSet,
			localGet: localGet
		};

		var 
			storage = sessionStorage,
			memoryStorage = {};

		// PUBLIC
		function get(key) {
			validateKey(key);

			return getItem(key);
		}

		function getRawAll() {
			return storage;
		}

		function getDefault(key, defaultValue, extendDefault) {
			var obj = get(key);
			if (obj === undefined || obj === null) {
				return defaultValue;
			}

			if (extendDefault) {
				obj = angular.extend(defaultValue, obj);
			}

			return obj;
		}

		function set(key, value) {
			validateKey(key);

			if (typeof value === 'object') {
				value = JSON.stringify(value);
			}

			storage.setItem(key, value);
		}

		function remove(key) {
			validateKey(key);

			removeItem(key);
		}

		function removeAllSession() {
			var keepKeys = [
				Constants.SK.Lang,
				Constants.SK.AccountLogin,
				Constants.SK.Login,
				Constants.SK.LandingEnrollmentNumber,
				Constants.SK.DefaultLogInType,
				Constants.SK.LandingLogInType,
				Constants.SK.Brand,
				Constants.SK.QueryParams,
				Constants.SK.Configuration,
				Constants.SK.FlowExecutionToken
			].map(function (key) {
				return key;
			});

			for (var key in storage) {
				if (keepKeys.indexOf(key) !== -1) {
					continue;
				}

				removeItem(key);
			}
			
			memoryStorage = {};
		}

		function getRemove(key) {
			var value = get(key);
			remove(key);
			return value;
		}

		// PRIVATE
		function localSet(key, value) {
			memoryStorage[key] = value;
		}

		function localGet(key) {
			return memoryStorage[key];
		}
		
		function validateKey(key) {
			if (typeof (key) !== 'string' || key === null || key === undefined || key.length === 0) {
				throw new Error('Invalid session key');
			}
		}

		// STORAGE

		function getItem(key) {
			try {
				var item = storage.getItem(key);
				
				//(use strict) => Octal numeric literals and escape characters are not allowed: validation
				if (!isNaN(item)) {
					return item;
				}
				
				return JSON.parse(item);
			} catch (e) {
				return storage.getItem(key);
			}
		}

		function removeItem(key) {
			storage.removeItem(key);
		}
		
		return service;
	}
})();
