(function () {
    'use strict';

    angular.module('l4b').config(Router);

    function Router($stateProvider, Constants) {

        $stateProvider

            .state(Constants.Page.Login, {
                url: '/login',
                templateUrl: 'html/login/login.html',
                controller: 'LoginController',
                controllerAs: 'vm',
                params: {
                    isSessionExpired: null,
                    tabSelected: null,
                    token: null
                }
            })

            .state(Constants.Page.PersonalInformation, {
                url: '/personalInformation',
                templateUrl: 'html/personalInformation/personalInformation.html',
                controller: 'PersonalInformationController',
                controllerAs: 'vm',
                params: {
                    token: null
                }
            })

            .state(Constants.Page.ChildInformation, {
                url: '/childInformation',
                templateUrl: 'html/childInformation/childInformation.html',
                controller: 'ChildInformationController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.IncomeEligibility, {
                url: '/incomeEligibility',
                templateUrl: 'html/incomeEligibility/incomeEligibility.html',
                controller: 'IncomeEligibilityController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.InvalidAddress, {
                url: '/invalidAddress',
                templateUrl: 'html/invalidAddress/invalidAddress.html',
                controller: 'InvalidAddressController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.ShippingAddress, {
                url: '/shippingAddress',
                templateUrl: 'html/shippingAddress/shippingAddress.html',
                controller: 'ShippingAddressController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.ProgramEligibility, {
                url: '/programEligibility',
                templateUrl: 'html/programEligibility/programEligibility.html',
                controller: 'ProgramEligibilityController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.BenefitSelection, {
                url: '/benefitSelection',
                templateUrl: 'html/landing/benefitSelection.html',
                controller: 'BenefitSelectionController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.Landing, {
                url: '/landing',
                templateUrl: 'html/landing/landing.html',
                controller: 'LandingController',
                controllerAs: 'vm',
                params: {
                    token: null
                }
            })

            .state(Constants.Page.ApplicationCompleted, {
                url: '/applicationCompleted',
                templateUrl: 'html/applicationCompleted/applicationCompleted.html',
                controller: 'ApplicationCompletedController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.Exit, {
                url: '/exit',
                templateUrl: 'html/exitPage/exitPage.html',
                controller: 'ExitPageController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.Error, {
                url: '/error',
                templateUrl: 'html/error/error.html',
                controller: 'ErrorController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.About, {
                url: '/about',
                controller: 'AboutController',
                templateUrl: 'html/about/about.html',
                controllerAs: 'vm'
            })
            
			.state(Constants.Page.TechnicalSupport, {
                url: '/technicalSupport',
                templateUrl: 'html/technicalSupport/technicalSupport.html',
                controller: 'SidePagesController',
                controllerAs: 'vm'
            })


            .state(Constants.Page.AboutUs, {
                url: '/aboutUs',
                templateUrl: 'html/aboutUs/aboutUs.html'
            })

            .state(Constants.Page.NatVerLanding, {
                url: '/natVerLanding/{data}',
                templateUrl: 'html/natVerLanding/natVerLanding.html',
                controller: 'NatVerLandingController',
                controllerAs: 'vm',
                params: {
                    data: null
                }
            })            
            ;
		}
})();
