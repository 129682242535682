(function () {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentationStatus', {
            templateUrl: 'html/shared/requiredDocumentationStatus/requiredDocumentationStatus.html',
 			bindings: {
				nextStep: '&'
			},			
			controller: RequiredDocumentationStatusController,
			controllerAs: 'vm'
        });				

    RequiredDocumentationStatusController.$inject = ['GuidedProofService', 'RestService', 'Constants', 'StorageService'];

    function RequiredDocumentationStatusController(GuidedProofService, RestService, Constants, StorageService) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            submit: submit,
            uploadProofs: uploadProofs,
            
			uploadedProofs: GuidedProofService.GetFilesSubmitted(),
			showUploadedProofs: showUploadedProofs,
			
            missingProofs: GuidedProofService.GetMissingProofs(),
			showMissingProofs: showMissingProofs,
			showChooseFiles: true
        });

		function init() {

			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_REQUIERED_DOCUMENTATION_STATUS_PRESENTATION_STARTED);

			vm.showChooseFiles |= vm.missingProofs.reduce(function (acc, proof) { return acc || proof.canChooseUpload; }, false);
		}			

		
		function uploadProofs() {
			GuidedProofService.RestartSkippedProofs();
			
			vm.nextStep({ options: { uploadProofs: true }});
		}

        function submit() {
			
			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_REQUIERED_DOCUMENTATION_STATUS_PROOF_SUBMITTED);
			
            if (vm.showUploadedProofs()) {
                vm.nextStep({ options: { submit: true }});
			}
            else {
                vm.nextStep();
			}
        }
		
		function showUploadedProofs() {
			return vm.uploadedProofs && vm.uploadedProofs.length > 0;
		}
		
		function showMissingProofs() {
			return vm.missingProofs && vm.missingProofs.length > 0;
		}
    }

})();