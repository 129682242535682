(function() {
	'use strict';
	
	angular.module('l4b')
	.component('programTable', {
		templateUrl: 'html/shared/programTable/programTable.html',
		bindings: {
			messages: '=',
			isIncome: '='
		},
		controllerAs: 'vm'
	});
})();