(function () {
    'use strict';

    angular.module('l4b')
        .controller('ChildInformationController', ChildInformationController);

    ChildInformationController.$inject = ['$scope', 'Constants', 'DTOFactory', 'Utils', 'StorageService', 'FlowService', 'RestService', 'FeaturesService'];

    function ChildInformationController($scope, Constants, DTOFactory, Utils, StorageService, FlowService, RestService, FeaturesService) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            patterns: setPattern(),
            application: DTOFactory.Application(),
            nextStep: nextStep,
            back: back,
            toolTipSSN: false,
            // maskUserDataSsn: false,
			// maskUserDataValueSsn: 'password',
            // toggleMaskDataSsn: toggleMaskDataSsn,
            // maskUserDataDob: false,
			// maskUserDataValueDob: 'password',
            // toggleMaskDataDob: toggleMaskDataDob,
            messages: []
        });
        
        function init() {
			vm.application.Dependent = DTOFactory.Dependent(true);
            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_DEPENDANT-INFORMATION-STARTED'
            );
        }

        function setPattern() {
            return {
                name: Constants.Pattern.NAME,
                lastName: Constants.Pattern.LAST_NAME,
                SSN: Constants.Pattern.SSNRegex,
				middleName: Constants.Pattern.MIDDLE_NAME,
                suffix: Constants.Pattern.SUFFIX
            };
        }

        function back() {
            FlowService.previousStep();
        }

        function nextStep(form) {
            if (!Utils.validateForm(form)) return;

            StorageService.set(Constants.SK.Application, vm.application);

            RestService.setDependent(onSetDependantSuccess);
        }

        function onSetDependantSuccess(data) {
            
            var error = StorageService.get(Constants.SK.Error);
            if (error) {
                FlowService.nextStep();
                return;
            }

            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_DEPENDANT-INFORMATION-COMPLETED'
            );

            //Set featuresInfo in Session.
            FeaturesService.update(data);

            FlowService.nextStep();
        }

  //       function toggleMaskDataSsn() {

  //           vm.maskUserDataSsn = !vm.maskUserDataSsn;
		// 	vm.maskUserDataValueSsn = vm.maskUserDataSsn ? 'password' : 'text';
  //       }
        
		// function toggleMaskDataDob() {

  //           vm.maskUserDataDob = !vm.maskUserDataDob;
		// 	vm.maskUserDataValueDob = vm.maskUserDataDob ? 'password' : 'text';
  //       }
    }
})();
