
(function () {
	'use strict';

	angular.module('l4b')
		.component('navigator', {
			templateUrl: 'html/shared/navigator/navigator.html',
			controller: 'NavigatorController',
			controllerAs: 'vm'
		});

	angular.module('l4b')
		.controller('NavigatorController', NavigatorController);

	NavigatorController.$inject = ['$window', '$state', 'Constants', 'StorageService', 'FlowService', '$rootScope', '$scope', '$location', 'Utils', 'MyAccountService', 'BrandService'];

	function NavigatorController($window, $state, Constants, StorageService, FlowService, $rootScope, $scope, $location, Utils, MyAccountService, BrandService) {

		var vm = this;

		angular.extend(vm, {
			$onInit: init,
			keywords: '',
			changeLanguage: changeLanguage,
			goToPage: goToPage,
			goToMyAccountPage: goToMyAccountPage,
			goToFaq: goToFaq,
			goToUrl: Utils.goToUrl,
			goToSection: goToSection,
			showDropDown: showDropDown,
			toggleMenu: toggleMenu,
			accountMenuIsOpened: false,
			assistProgramMenuIsOpened: false,
			supportMenuIsOpened: false,
			menuStatus: 'closed',
			brand: BrandService.getCurrent().Name,
			
			showSubmit: true,
			showUpdate: true,
			showRecertify: true,
			showCancel: true,
		});

		function init(){
			$scope.$on('set-menu-options', function(event, data) {
				if(data) {
					vm.showSubmit = data.showSubmit;
					vm.showUpdate = data.showUpdate;
					vm.showRecertify = data.showRecertify;
					vm.showCancel = data.showCancel;
				}
			});
		}
		
		function goToFaq() {
			Utils.goToFaq();
		}

		function goToSection() {
			Utils.scrollTo('about-us-GEN');
        }

		function goToPage(page) {
			$state.go(Constants.Page[page]);

			// if there is an air message displayed, it must be closed after redirecting.
			$rootScope.$broadcast('close-air-message');
		}

		function goToMyAccountPage() {
			// If already in my account, do nothing
			if (FlowService.getCurrentState().match(new RegExp(Constants.Page.MyAccount))) {
				return;
			}

			// Third parameter is send to reload controllers when redirecting to tabs in my account.
			MyAccountService.GoToMyAccount();

			// if there is an air message displayed, it must be closed after redirecting.
			$rootScope.$broadcast('close-air-message');
		}

		function changeLanguage() {
			var pathname = $window.location.pathname;
			var hash = $location.path();
			var params = '';
			
			if ($location.$$url.match(/\/[^?]+[?]/)) {
				params = $location.$$url.replace(/\/[^?]+([?].*)/, '$1');
			}
			
			if (pathname.indexOf('/RetrieveDocument/') !== -1) {
				pathname = pathname.replace('/RetrieveDocument/', Constants.Url.Spanish);
			}
			else if (pathname.indexOf(Constants.Url.English) !== -1) {
				pathname = pathname.replace(Constants.Url.English, Constants.Url.Spanish);
			}
			else if (pathname.indexOf(Constants.Url.Spanish) !== -1) {
				pathname = pathname.replace(Constants.Url.Spanish, Constants.Url.English);
			}

			$window.location = pathname + '#!' + hash + params;
		}

		function toggleMenu(){
			addClickEvent();

			if(vm.menuStatus == 'closed'){
				vm.menuStatus = 'open';
			}
			else {
				vm.menuStatus = 'closed';
			}
		}

		function showDropDown(dropDownName) {
			var allDropDowns = ['account', 'support', 'assistProgram'];

			addClickEvent();

			angular.forEach(allDropDowns, function (value) {
				if (dropDownName == value && vm[value + "MenuIsOpened"] == false) {

					vm[value + "MenuIsOpened"] = true;
				}
				else {
					vm[value + "MenuIsOpened"] = false;
				}

			});
		}

		//Local utils
		function addClickEvent() {
			$window.onclick = function (event) {
				$scope.$apply(function () {
					closeAllDropDowns();

					if(!isElement(event, 'btnMenu')) {
						closeToggleMenu();
					}
				});
			};
		}

		function closeAllDropDowns() {
			vm.accountMenuIsOpened = false;
			vm.assistProgramMenuIsOpened = false;
			vm.supportMenuIsOpened = false;
		}

		function closeToggleMenu() {
			if(vm.menuStatus) {
				vm.menuStatus = 'closed';
			}
		}

		function isElement(event, elementName) {
			var activeElement = event.target.name;
			return activeElement === elementName;
		}
	}
})();
