(function () {
    'use strict';

    angular.module('l4b')
        .controller('LandingController', LandingController);

    function LandingController(FlowService,RestService, Utils, StepsService, StorageService, Constants, FlowExecutionService, DTOFactory) {

        var vm = this;

        angular.extend(vm, {
			$onInit: init,
            nextStep: nextStep,
            showAcpQuestion: false,
            showForwardQuestion: false,
            isNewCustomer: false,
            isMobility: false,
            answerAcpQuestion: answerAcpQuestion,
            answerForwardQuestion: answerForwardQuestion,
            application: DTOFactory.Application()
        });
		
		function init() {
            
			StepsService.initSteps();
            FlowService.updateCurrentStep();
            vm.tokenParam = Utils.getQueryString('token');
            var businessLine = Utils.getQueryString('businessLine');

            if(vm.tokenParam) {
                validateAccessToken();      
            } else if(businessLine) {
                RestService.initFromScratch(businessLine)
                .then(function(token){
                    vm.tokenParam = token.data;
                    StorageService.set(Constants.SK.Token, vm.tokenParam);
                    validateAccessToken();
                })
            } else {
                FlowService.goToExit();
            }

		}

        function validateAccessToken() {
            RestService
            .validateAccessToken(vm.tokenParam, function(res)  {
                StorageService.set(Constants.SK.Error, res.data.code);
                 FlowService.goToExit(); 
                })
            .then(function(response) {
                StorageService.removeAllSession();
                //If token provided reset application after remove session
                vm.application = DTOFactory.Application();
                
                StorageService.set(Constants.SK.AccessTokenData, response.data);
                vm.showAcpQuestion = true;
                vm.showForwardQuestion = false;
                vm.isNewCustomer = response.data.customerType == "N";
                vm.isMobility = response.data.businessLine == "MOBILITY";
                vm.cartId = response.data.cartId;
                vm.businessLine = response.data.businessLine;   
                  
                vm.application.BusinessLineCode = response.data.businessLine;
                StorageService.set(Constants.SK.Application, vm.application);        
            })
        }

        function nextStep() {
            FlowService.nextStep();
        }

        function answerAcpQuestion(acpResponse) {
            if(acpResponse) {
                clearErrors();
                RestService.setAccessTokenFlowFlag(vm.tokenParam, false, onSelectBenefitSuccess);
            } else {
                vm.showAcpQuestion = false;
                vm.showForwardQuestion = true;
            }
        }
        function answerForwardQuestion(forwardResponse) {
            if(forwardResponse) {
                clearErrors();

                var config = StorageService.get(Constants.SK.Configuration);
                var verizonForwardNewCustomerAllowedBusinessLines = config.verizonForwardNewCustomerAllowedBusinessLines.split(',');
                var allowNew = verizonForwardNewCustomerAllowedBusinessLines.includes(vm.businessLine);
                var notAllowedForVerizonForward = vm.isNewCustomer && !vm.isMobility && !allowNew;

                if(notAllowedForVerizonForward) {
                    FlowExecutionService.logFlowExecutionEvent('SYSTEM_APPLICATION-CREATION_VERIZON_FORWARD_NEW_CUSTOMER_REJECTED', vm.cartId);
                    StorageService.set(Constants.SK.Error, Constants.Error.VERIZON_FORWARD_NEW_CUSTOMER_REJECTED);
                    FlowService.goToExit();
                    return;
                }

                RestService.setAccessTokenFlowFlag(vm.tokenParam, true, onSelectBenefitSuccess);
            } else {
                StorageService.set(Constants.SK.Error, Constants.Error.VERIZON_FORWARD_REJECTED);
                FlowService.goToExit();
            }
        }

        function clearErrors() {
            StorageService.remove(Constants.SK.Error);
        }

        function onSelectBenefitSuccess(){
            vm.nextStep();
        }
    }
})();
