(function () {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentationUpload', {
            templateUrl: 'html/shared/requiredDocumentationUpload/requiredDocumentationUpload.html',
            bindings: {
                nextStep: '&'
            },
            controller: RequiredDocumentationUploadController,
            controllerAs: 'vm',       
        });

    RequiredDocumentationUploadController.$inject = ['Constants', 'StorageService', 'GuidedProofService', 'RestService'];

    function RequiredDocumentationUploadController(Constants, StorageService, GuidedProofService, RestService) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            fileToUpload: null,
            noProofClick: noProofClick,
            onChange: onChange,
            onError: onError,
            takePhoto: takePhoto,
            showChooseFiles: true,
            proofData: null,
            allowedFileFormats: ".png, .jpeg, .jpg, .pdf",
            allowedFileMimeType: "image/png,image/jpeg,image/jpg,application/pdf",
            useExistingProof: false,
            createProofFromAnother: createProofFromAnother,
			allowToReuse: false,
            metadata: null
        });

        function init() {
			
            RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_PRESENTATION_COMPLETED);
		
            loadAllowedFormatsConfig();

            var proofObj = GuidedProofService.GetNextProofNeeded();
            vm.metadata = GuidedProofService.GetMissingProofs();
            if (proofObj) {
                vm.allowToReuse = GuidedProofService.CanReuseFrom();
				vm.proofData = proofObj;
                vm.showChooseFiles |= vm.proofData.canChooseUpload;
            }
            else {
                vm.nextStep();
            }
        }
        
        function loadAllowedFormatsConfig(){
            var config = StorageService.get(Constants.SK.Configuration);
            if(config){
                vm.allowedFileFormats = config.allowedFileFormats || vm.allowedFileFormats;
                vm.allowedFileMimeType = config.allowedFileMimeType || vm.allowedFileMimeType;
            }
        }

        function noProofClick() {

            RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_NOT_PROVIDED);

            GuidedProofService.SkipProof();

            vm.nextStep({ options: { skip: true } });
        }

        function onChange() {
            RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_PROVIDED);

            StorageService.set(Constants.SK.FileData, vm.fileToUpload);

            GuidedProofService.ShowImageReviewPage(vm.proofData.isAdditional);

            vm.nextStep({ options: { review: true } });
        }

        function onError(errorText) {

            StorageService.set(Constants.SK.FileData, { error: errorText });

            GuidedProofService.ShowImageReviewPage(vm.proofData.isAdditional);

            vm.nextStep({ options: { review: true } });
        }

        function takePhoto() {
            RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_PROVIDED);
            GuidedProofService.ShowCameraPage(vm.proofData.isAdditional);

            vm.nextStep({ options: { takePhoto: true } });
        }

        function createProofFromAnother() {
            GuidedProofService.CreateFromAnother();
			
			vm.nextStep({ options: { createFromAnother: true } });
        }
    }

})();