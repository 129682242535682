(function () {
    'use strict';

    angular.module('l4b')
        .service('DeviceService', DeviceService);

    function DeviceService(
        $window
    ) {
        var self = this;

        self.isIos = function () {
            return self.isIphone() || self.isIpad() || self.isIpod();
        };
    
        self.isIphone = function () {
            return $window.navigator.userAgent.toLowerCase().search("iphone") > -1;
        };
    
        self.isIpad = function () {
            return $window.navigator.userAgent.toLowerCase().search("ipad") > -1;
        };
    
        self.isIpod = function () {
            return $window.navigator.userAgent.toLowerCase().search("ipod") > -1;
        };
    
        self.isAndroid = function () {
            return $window.navigator.userAgent.toLowerCase().search("android") > -1;
        };
    
        self.isBrowser = function () {
            return !self.isIos() && !self.isAndroid();
        };
    }
})();