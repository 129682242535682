(function() {
    'use strict';

    angular.module('l4b')
        .factory('ResponseAdapter', ResponseAdapter);

    ResponseAdapter.$inject = ['StorageService', 'Constants', 'Utils', 'DTOFactory'];

    function ResponseAdapter(StorageService, Constants, Utils, DTOFactory) {

        var service = {
			getCaptchaToken: getCaptchaToken,
            initEnrollment: initEnrollment,
            applyEnrollment: applyEnrollment,
			setDependent: setDependent,
			getPlanForEnrollment: getPlanForEnrollment,
            getDisclaimers: getDisclaimers,
            getAddress: getAddress,
            getLegalNotes: getLegalNotes, 
            createEnrollment: createEnrollment,
            getApplication: getApplication,
            getDeEnrollReasons: getDeEnrollReasons,
			getZipCodeMetadata: getZipCodeMetadata
        };

        return service;
        
        /*
        --------------------------------------------------------------------------------------------
                Emerios DTOs to Legacy DTOs
        --------------------------------------------------------------------------------------------
        */

		function upperFirst(s) {
			return s.replace(/^([a-z])/, function(x) {
				return x.toUpperCase();
			});
		}
		
        function getCaptchaToken(response){
            if(response.status === 200)
                return {
                    status: Constants.Status.OK,
                    token: response.data
                };
            else
                return {
                    status: Constants.Status.TOKEN,
                };
        }

        function initEnrollment(response) {

            var adaptedResponse = {
                token: StorageService.get(Constants.SK.Token), //this token was already set on the login endpoint
				applicationToken: response.data.token,
                benefitTypeCode: response.data.benefitTypeCode
            };

            return adaptedResponse;
        }

        function applyEnrollment(response) {
            var adaptedResponse = response;

            if (adaptedResponse.data.flags && adaptedResponse.data.flags.invalid_address && !adaptedResponse.data.address.isPoBox) {
                adaptedResponse.useAddressEscalation = true;
            }

			if (!adaptedResponse.featuresInformation) {
				adaptedResponse.featuresInformation = {};
            }
            
            if (response.data.suggestedAddress) {
                adaptedResponse.addressValidationStatus = Constants.Status.SUGGEST;
                adaptedResponse.suggestedAddressLine1 = response.data.suggestedAddress.line1;
                adaptedResponse.suggestedAddressLine2 = response.data.suggestedAddress.line2;
                adaptedResponse.suggestedCity = response.data.suggestedAddress.city;
            }
			adaptedResponse.address = response.data.address;
			adaptedResponse.featuresInformation.NatVerApi = response.data.natVerApi;
			
			adaptedResponse.plan = getPlanForEnrollment(response.data.products[0].product);
			
			adaptedResponse.appliedUsingNatVerAppId = response.data.appliedUsingNatVerAppId;
			
			adaptedResponse.nladTransactionInfo = getNladTransactionInfo(response.data.nladTransactionInfo);
			
			adaptedResponse.useTransferException = Utils.find(response.data.escalations || [], function(x) {
				return x == Constants.Quest.Escalation.TransferLimitApplicant;
			});
			
			adaptedResponse.eligibleForTransferDate = response.data.nladApplication.eligibleForTransferDate;
            adaptedResponse.BenefitTypeCode = response.data.benefitTypeCode;
						
            return adaptedResponse;
        }
		
		function setDependent(response) {
            var adaptedResponse = response;

			if (!adaptedResponse.featuresInformation) {
				adaptedResponse.featuresInformation = {};
            }
			
			adaptedResponse.featuresInformation.NoFreePhone = !response.data.canRequestFreePhone;
			
            return adaptedResponse;
        }
	
        function getPlanForEnrollment(response) {
            var KeyCodes = Constants.Quest.KeyCodes;
			
			var plan = DTOFactory.Plan();

			plan.Caption = getFromProperties(KeyCodes.Caption, response.properties);
			plan.Category = response.category;
			plan.Data = getFromProperties(KeyCodes.Data, response.properties);
			plan.Description = response.description;
			plan.Name = response.name;
			plan.PlanId = response.productId;
			plan.ServiceType = getFromProperties(KeyCodes.ServiceType, response.properties);

			return plan;
        }
		
        function getNladTransactionInfo(response) {
			
			if (!response) {
				return null;
			}
			
			var nlad = DTOFactory.NladTransactionInfo();

			nlad.NladFailureReason = response.nladFailureReason;
			nlad.IsNladSuccessfulTransaction = response.isNladSuccessfulTransaction;

			return nlad;
        }		

        function getDisclaimers(response){
            StorageService.set(Constants.SK.Quest.Disclaimers, response.data);

            var disclaimers = [];
            angular.forEach(response.data, function(disclaimer){
                disclaimers.push({
                    code: disclaimer.code,
                    text: disclaimer.text,
                    required: disclaimer.isRequired
                });
            });

            return disclaimers;
        }

        function getLegalNotes(response){
            var legalNotes = [];
            angular.forEach(response.data, function(legalNote){
                legalNotes.push({
                    code: legalNote.code,
                    text: legalNote.text
                });
            });

            return legalNotes;
        }

        function createEnrollment(response) {
            var applicationDTO = response.data;
            return {
                enrollmentNumber: applicationDTO.externalApplicationId,
                applicationId: applicationDTO.id,
				nladApplication: applicationDTO.nladApplication,
                natVerApiTransactionInfo: applicationDTO.natVerApiTransactionInfo,
                RedirectUrl: applicationDTO.redirectUrl,
                hasPendingProof: applicationDTO.hasPendingProof,
                Status: {
                    Code: applicationDTO.status.code,
                    Name: applicationDTO.status.Name,
                },
                BenefitTypeCode: applicationDTO.benefitTypeCode,
                Requirements: applicationDTO.requirements,
                BusinessLineCode: applicationDTO.businessLineCode
            };
        }

        function getApplication(response) {
			response.status = Constants.Status.OK;

			//This "login" dto is used on the proof catalog and required documentation components.
			StorageService.set(Constants.SK.Login, adaptLogin(response));

			var enrollment = response.data;

			var application = DTOFactory.Application();

			application.ApplicationId = enrollment.id;
			application.ApplicationToken = enrollment.token;
			application.EnrollmentNumber = enrollment.externalApplicationId;
			application.PhoneNumber = enrollment.phoneNumber;
			application.Status.Code = enrollment.status.code;
			application.Status.Name = enrollment.status.name;
			application.BenefitTypeCode = enrollment.benefitTypeCode;
			application.NladApplication.NatVerApplicationId = enrollment.nladApplication.natVerApplicationId;
			application.AppliedUsingNatVerAppId = enrollment.appliedUsingNatVerAppId;
			application.NatVerApiTransactionInfo = enrollment.natVerApiTransactionInfo;
			application.CustomerTypeCode = enrollment.customerTypeCode;
			application.RedirectUrl = enrollment.redirectUrl;
			application.hasPendingProof = enrollment.hasPendingProof;
			application.BusinessLineCode = enrollment.businessLineCode;
			application.Plan = getPlanForEnrollment(enrollment.products[0].product);
            application.Requirements = enrollment.requirements;
            application.AccessTokenDto = enrollment.accessTokenDto;
			
            if (enrollment.statusChanges) {
                application.StatusChanges = enrollment.statusChanges.map(function (item) {
                    return {
                        Date: item.creationDatetime,
                        Status: {
							Code: item.status.code,
							Name: item.status.name
						},
                        StatusChangeSource: item.statusChangeSource,
                        StatusChangeReason: item.statusChangeReason
                    };
                });
            }			
			
			application.Applicant.FirstName = enrollment.applicant.firstName;
			application.Applicant.MiddleName = enrollment.applicant.middleName; 
			application.Applicant.LastName = enrollment.applicant.lastName;
			application.Applicant.LastFourSsn = enrollment.applicant.lastFourSsn;
			application.Applicant.DOB = enrollment.applicant.dob ? enrollment.applicant.dob.substr(0, 10) : '';
			
			application.Applicant.Email = enrollment.contactInfo.email;
			application.Applicant.AgreeInfoUpdate = enrollment.contactInfo.agreeInfoUpdate;
			
			application.Address = getAddress(enrollment.address);
			application.ShippingAddress = getAddress(enrollment.shippingAddress);

			if (enrollment.dependent) {
                application.Dependent = {};
				application.Dependent.FirstName = enrollment.dependent.firstName;
				application.Dependent.MiddleName = enrollment.dependent.middleName; 
				application.Dependent.LastName = enrollment.dependent.lastName;
				application.Dependent.LastFourSsn = enrollment.dependent.lastFourSsn;
				application.Dependent.DOB = enrollment.dependent.dob ? enrollment.dependent.dob.substr(0, 10) : '';
			}
			else {
				application.Dependent = null;
			}
			
			response.data = application;

			return response;
        }
		
		function getAddress(address) {
			
			if (!address) {
				return null;
			}
			
			return {
				Line1: address.line1,
				Line2: address.line2,
				ZipCode: address.zipcode,
				City: address.city,
				State: address.state,
				StateName: address.stateName,
				IsTemporary: address.isTemporary,
				IsTribalLand: address.isTribalLand,
				EligibleTribalLands: address.eligibleTribalLands
			};
		}

        function getDeEnrollReasons(data) {
            var dataLength = data.length,
                adaptedResponse = [];

            for(var index = 0; index < dataLength; index++) {
                adaptedResponse.push({
                    name: data[index].text,
                    code: data[index].code
                });
            }

            return adaptedResponse;
        }

        /*
        --------------------------------------------------------------------------------------------
                Local utils
        --------------------------------------------------------------------------------------------
        */

        function adaptLogin(response) {
            var enrollment = response.data;
            var adaptedResponse = {
                enrollmentNumber: enrollment.externalApplicationId,
                token: enrollment.token,
                status: response.status, //"OK",
                plan: getPlanForEnrollment(enrollment.products[0].product),
                enrollmentId: enrollment.id,
                zipCode: enrollment.address.zipcode,
                state: enrollment.address.state,
                eligibleType: enrollment.programs && enrollment.programs.length ? enrollment.programs.map(function (item) { return item.code; }).join() : 'income',
                verificationData: {
                    FirstQualificationDate: enrollment.firstQualificationDate
                },
                incomeOption: enrollment.incomeSelection
            };

            return adaptedResponse;
        }

        function getFromProperties(keyCode, properties){
            var foundObj = Utils.find(properties, function (prop) {
                return (angular.equals(prop.keyCode, keyCode));
            });

            return (foundObj && foundObj.value);
        }

		function getZipCodeMetadata(response) {
			
			var adaptedResponse = response.data;
			
			var featInformation = {};
			for (var i in adaptedResponse.featuresInformation) {
				var featCode = upperFirst(i);
				
				featInformation[featCode] = adaptedResponse.featuresInformation[i];
			}

			adaptedResponse.featuresInformation = featInformation;
			
			return adaptedResponse;
		}
    }
})();
