(function () {
    'use strict';

    angular.module('l4b')
        .directive('matchValidator', MatchValidator);

    MatchValidator.$inject = [];

    function MatchValidator() {
        return {
            require: 'ngModel',
            link: function(scope, elm, attr, ctrl) {
                var confirmation = elm.inheritedData('$formController')[attr.matchValidator];

                ctrl.$parsers.push(function(value) {
					ctrl.$setValidity('match', null);
					
					if (value && confirmation.$viewValue) {
						ctrl.$setValidity('match', value.toLowerCase() === confirmation.$viewValue.toLowerCase());
					}
					
					return value;
                });

                confirmation.$parsers.push(function(value) {
                    ctrl.$setValidity('match', null);
					
					if (value && ctrl.$viewValue) {
                        ctrl.$setValidity('match', value.toLowerCase() === ctrl.$viewValue.toLowerCase());
                    }
					
                    return value;
                });
            }
        };
    }
})();
