(function () {
    'use strict';

    angular.module('l4b')
        .controller('CameraViewController', CameraViewController);

    CameraViewController.$inject = ['Constants', '$state', 'StorageService', 'FlowService'];

    function CameraViewController(Constants, $state, StorageService, FlowService) {
        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            nextStep: nextStep
        });

        function init() {
        }

        function nextStep(options) {
			FlowService.nextStep(options);
        }

    }
})();
