(function () {
	'use strict';

	angular.module('l4b')
	.controller('InvalidAddressController', InvalidAddressController);

	InvalidAddressController.$inject = ['DTOFactory', 'RestService', 'FlowService', 'StorageService', 'Constants', 'Utils', 'FeaturesService'];
	
	function InvalidAddressController(DTOFactory, RestService, FlowService, StorageService, Constants, Utils, FeaturesService) {

		var vm = this;

		angular.extend(vm,
		{
			$onInit: init,
            application: DTOFactory.Application(),		
			addressValidation: DTOFactory.AddressValidation(),
			patterns: setPattern(),
			nextStep: nextStep,
			goHome: goHome,
			showPoBoxError: false,
			maxLengths: Constants.LengthRestrictions,
			isVerizonForwardFlow: false
		});

		function init() {
			vm.isVerizonForwardFlow = vm.application.BenefitTypeCode == Constants.BenefitType.VZF;
		}
		
		function nextStep(form) {
			if (!Utils.validateForm(form)) {
				return;
			}

			StorageService.set(Constants.SK.Application, vm.application);
			RestService.applyEnrollment(applyEnrollmentSuccess);
		}

		function applyEnrollmentSuccess(data) {

            var error = StorageService.get(Constants.SK.Error);
            if (error) {
				FlowService.nextStep();
				return;
            }

            if (data && data.addressValidationStatus === Constants.Status.SUGGEST) {
                vm.suggestedAddress = {
                    line1: data.suggestedAddressLine1,
                    line2: data.suggestedAddressLine2,
                    city: data.suggestedCity
                };
                return;
            }

			vm.application.Plan = data.plan;
			vm.application.DeviceOption = data.deviceOption;
			vm.application.NladTransactionInfo = data.nladTransactionInfo;
			vm.application.NladApplication.UseTransferException = data.useTransferException;
			vm.application.NladApplication.EligibleForTransferDate = data.eligibleForTransferDate;
			vm.application.AppliedUsingNatVerAppId = data.appliedUsingNatVerAppId;
			vm.application.ShippingAddress = null;
			vm.application.Dependent = null;
			
			StorageService.set(Constants.SK.Application, vm.application);

            //Set featuresInfo in Session.
            FeaturesService.update(data);

            if (data.token) {
                StorageService.set(Constants.SK.Token, data.token);
            }

            FlowService.nextStep();
		}
		
		function goHome() {
			FlowService.goHome();
        }
		
        function setPattern() {
            return {
                address: Constants.Pattern.ADDRESS
            };
		}
	}
})();
