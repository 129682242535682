(function() {
    'use strict';

    angular.module('l4b')
        .controller('ApplicationCompletedController', ApplicationCompletedController);

    ApplicationCompletedController.$inject = ['Constants', 'StorageService', 'Utils', 'FlowService', 'RestService', 'StepsService', 'GTMService', 'GlobalizationService', 'DTOFactory'];

    function ApplicationCompletedController(Constants, StorageService, Utils, FlowService, RestService, StepsService, GTMService, GlobalizationService, DTOFactory) {

        var 
			vm = this,
			application = DTOFactory.Application();
			var proofCodes = [Constants.Requirement.ProgramProof.Code, Constants.Requirement.FormerAcpProof.Code, Constants.Requirement.IncomeProof.Code];

        angular.extend(vm,
        {
            $onInit: reloadData,
            enrollmentNumber: '',
			refresh: refresh,
			accountLoginDTO: DTOFactory.AccountLogin(),
			isSuccess: false,
			isVerizonForwardFlow: application.BenefitTypeCode == Constants.BenefitType.VZF,
			hasPendingProof: application.Requirements.some(function(x){ return proofCodes.includes(x.code) }),
			isLoaded: false,
			goHome: goHome
        });

		function reloadData() {
			init();
		}

		function goToMyAccountSubmitDocumentation() {
            vm.accountLoginDTO.ZipCode = application.Address.ZipCode;
            vm.accountLoginDTO.Dob = application.Applicant.DOB;
            vm.accountLoginDTO.LastName = application.Applicant.LastName;
			vm.accountLoginDTO.ExternalApplicationId = application.EnrollmentNumber;
            vm.accountLoginDTO.ForgotEnrollment = false;
            StorageService.set(Constants.SK.AccountLogin, vm.accountLoginDTO);
            var loginStateParams = { token: application.AccessToken };
            StorageService.set(Constants.SK.LoginStateParams, loginStateParams);

            FlowService.goToMyAccount({tabSelected: Constants.Page.MyAccountSubmitDocumentation});
            
        }

        function init() {
			
			RestService.trackApplicationEvent(
				'SYSTEM_APPLICATION-CREATION_APPLICATION-SUCCESS-PAGE-PRESENTED'
			);
			RestService.trackApplicationEvent(
				'SYSTEM_APPLICATION-CREATION_APPLICATION-COMPLETED'
			);
			
            refresh();
		
			Utils.pushDataLayer();

			gtag('event', 'conversion', {'send_to': 'AW-847158311/nAC3CMnEqNcCEKe4-pMD'}); 

			GTMService.pushApplicationCompleted();
			
			if(vm.isVerizonForwardFlow && vm.hasPendingProof) {
				goToMyAccountSubmitDocumentation();
				return;
			}

			vm.apiValues = Utils.getNatVerApiValues();
			var config = StorageService.get(Constants.SK.Configuration);
            vm.isAcpWindDownEnabled = config.isAcpWindDownEnabled;

			
			var natVerErrors = vm.apiValues.errorResponse 
				|| vm.apiValues.pendingCertification 
				|| vm.apiValues.pendingEligibility 
				|| vm.apiValues.pendingResolution 
				|| vm.apiValues.pendingReview;

			vm.isLoaded = true;
			
			vm.isSuccess = !natVerErrors;
        }
		
		function refresh() {			
			
			vm.enrollmentNumber = application.EnrollmentNumber;
		
			FlowService.updateCurrentStep(Constants.Page.ApplicationCompleted);

			var isRefreshingPage = FlowService.getPreviousState() == FlowService.getCurrentState();
			
			if (isRefreshingPage && !application.AppliedUsingNatVerAppId) {
				RestService.natVerApiStatusCheckByAppId(application.ApplicationId, GlobalizationService.getLang(), natVerApiStatusCheckSuccess);
			}
					
			FlowService.updatePreviousState();
		}
		
		function natVerApiStatusCheckSuccess(data) {
			
			if (!data) {
				return;
			}
			
			application.NatVerApiTransactionInfo = data;
			
			StorageService.set(Constants.SK.Application, application);
			
			vm.setApiValues();
		}		
		
		function goHome() {
            FlowService.goHome();
        }

    }
})();
