(function () {
    'use strict';

    angular.module('l4b').config(ZipCodeRouting);

    function ZipCodeRouting($stateProvider, Constants) {

        $stateProvider

            .state('zipCode', {
                url: '',
                templateUrl: 'html/main/main.html',
                abstract: true,
                authorizations: [Constants.AuthProviders.ZIP_CODE]
            });
    }
})();