(function () {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentation', {
            templateUrl: 'html/shared/requiredDocumentation/requiredDocumentation.html',
 			bindings: {
				nextStep: '&'
			},			
			controller: RequiredDocumentationController,
			controllerAs: 'vm'
        });
		
    RequiredDocumentationController.$inject = ['GuidedProofService', 'RestService', 'Constants'];

    function RequiredDocumentationController(GuidedProofService, RestService, Constants) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            next: next,
            metadata: null
        });
		
		function init() {
			
			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_REQUIERED_DOCUMENTATION_PRESENTATION_STARTED);

            GuidedProofService
				.SyncRequirements()
				.then(function() {
					vm.metadata = GuidedProofService.GetMissingProofs();
					vm.nextStep();
				});	
		}		
		
		function next() {
			
			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_REQUIERED_DOCUMENTATION_PRESENTATION_COMPLETED);
			vm.nextStep();
		}
    }
})();