(function() {
	'use strict';
	
	angular.module('l4b')
	.component('spinner', {
	  templateUrl: 'html/shared/spinner/spinner.html',
	  bindings: {
	    loading: '<'
	  }
	});

})();