(function () {
    'use strict';

    angular.module('l4b')
        .service('AuthProvidersService', AuthProvidersService);

    function AuthProvidersService($injector, $rootScope) {

        this.register = function () {
            $rootScope.$on('$stateChangeStart', onStateChangeStart);
        };

        function onStateChangeStart(e, toState, toParams) {
            var authProviders = getProviders(toState);

            for (var i = 0; i < authProviders.length; i++) {
                var $ap = $injector.get(authProviders[i]);
                if (!$ap.authorize(toState, toParams)) {
                    e.preventDefault();
                    return;
                }
            }
        }

        function getProviders(state) {
            if (!state) {
                return [];
            }

            return (state.authorizations || []).concat(getProviders(state.$$state().parent));
        }
    }
})();
