(function () {
    'use strict';

    angular.module('l4b')
        .factory('FlowService', FlowService);

    function FlowService($cookies, $state, StorageService, Constants, Utils, StepsService, $window, DTOFactory, FeaturesService) {

        var
            nextStepFor = {},
            previousStepFor = {};

        function getCurrentState() {
            return $state.current.name;
        }
		
        function getPreviousState() {
            return StorageService.get(Constants.SK.PreviousStep);
        }

        function updatePreviousState() {
            return StorageService.set(Constants.SK.PreviousStep, getCurrentState());
        }

        function go(where, options) {
            
			updatePreviousState();
			
			options = options || {};
			
			if (!options.avoidUpdateStep && !where.match(/^myAccount\./)) {
				updateCurrentStep(where);
			}

			return $state.go(where, options);
        }

        function nextStep(options) {
			
			if (!sharedCheckErrors()) {
				return;
			}
			
            nextStepFor[getCurrentState()](options);
        }

        function previousStep() {
            previousStepFor[getCurrentState()]();
        }

        function updateCurrentStep(where) {
            var state = where || getCurrentState();

            StepsService.setCurrentStep(state);
        }

        // Steps
        nextStepFor[Constants.Page.Home] = function () {
            return sharedStartFlow();
        };

        nextStepFor[Constants.Page.Landing] = function () {
            var tokenParam = Utils.getQueryString('token');
            if(!tokenParam) {
                tokenParam = StorageService.get(Constants.SK.Token);
            }
            return go(Constants.Page.PersonalInformation, {token: tokenParam});
        };
        
        nextStepFor[Constants.Page.RequiredDocumentation] = function (options) {
			sharedSubmitDocumentationFlow(options);
		};
		
        nextStepFor[Constants.Page.MyAccountSubmitDocumentation] = function (options) {
			sharedSubmitDocumentationFlow(options);
		};

        nextStepFor[Constants.Page.PersonalInformation] = function () {
            if (StorageService.get(Constants.SK.Error)) {
                return go(Constants.Page.Exit);
            }

			// If applying with NatVerApplicationId, then jump eligibility step
			var application = DTOFactory.Application();
			
			if (application.AppliedUsingNatVerAppId) {
				return go(Constants.Page.ApplicantEligibility);
			}
            if(application.ShippingAddress != null){
                return go(Constants.Page.ShippingAddress);
            }
            var qualification = StorageService.get(Constants.SK.Qualification) || {};

            if (qualification.Type == Constants.QualificationType.Income) {
                return go(Constants.Page.IncomeEligibility);
            }
            else {
                return go(Constants.Page.ProgramEligibility);
            }
        };

        nextStepFor[Constants.Page.ApplicantEligibility] = function () {
            var useDependent = StorageService.get(Constants.SK.Qualification).UseDependent;

            if (useDependent) {
                go(Constants.Page.ChildInformation);
                return;
            }
            if(Utils.isLifelineEnabled()) {
                goToBenefitSelection();
            } else {
                goToReview();
            }
        };

        nextStepFor[Constants.Page.ChildInformation] = function () {
            if (Utils.hasBlockingNladError()) {
                return go(Constants.Page.Exit);
            }

            if (StorageService.get(Constants.SK.Error)) {
                return go(Constants.Page.Exit);
            }

            if(Utils.isLifelineEnabled()) {
                goToBenefitSelection();
            } else {
                goToReview();
            }
            
        };

        nextStepFor[Constants.Page.BenefitSelection] = function () {
            goToReview();
        };

        previousStepFor[Constants.Page.ChildInformation] = function () {

            var qualification = StorageService.get(Constants.SK.Qualification);

            var forceUseDependent = Utils.find(qualification.Programs, function (program) {
                return program.forceUseDependent;
            });

            if (forceUseDependent) {
                return go(Constants.Page.ProgramEligibility);
            }

            go(Constants.Page.ApplicantEligibility);
        };

        nextStepFor[Constants.Page.IncomeEligibility] = function () {
            if(Utils.isLifelineEnabled()) {
                goToBenefitSelection();
            } else {
                goToReview();
            }
        };

        previousStepFor[Constants.Page.IncomeEligibility] = function () {
            go(Constants.Page.ProgramEligibility);
        };

        nextStepFor[Constants.Page.ProgramEligibility] = function () {

            if (!FeaturesService.get().AllowDependent){
                return goToReview();
            }

            var qualification = DTOFactory.Qualification();
            var forceUseDependent = Utils.find(qualification.Programs, function (program) {
                return program.forceUseDependent;
            });

            var dependent = StorageService.get(Constants.SK.Dependent);
            if (dependent || forceUseDependent) {
                return go(Constants.Page.ChildInformation);
            }

            return go(Constants.Page.ApplicantEligibility);
        };

        nextStepFor[Constants.Page.InvalidAddress] = function () {

			nextStepFor[Constants.Page.PersonalInformation]();
        };

        nextStepFor[Constants.Page.TransferException] = function (nextOptions) {
            return nextStepTransferExceptions(nextOptions);
        };

        nextStepFor[Constants.Page.Review] = function () {

			sharedFlowEnrollmentCompleted();
        };
		
        nextStepFor[Constants.Page.Search] = function () {
            $state.go($state.current, {}, { reload: true });
        };

        nextStepFor[Constants.Page.ShippingAddress] = function () {
            if (StorageService.get(Constants.SK.Error)) {
                return go(Constants.Page.Exit);
            }
            var qualification = StorageService.get(Constants.SK.Qualification) || {};

            if (qualification.Type == Constants.QualificationType.Income) {
                return go(Constants.Page.IncomeEligibility);
            }
            else {
                return go(Constants.Page.ProgramEligibility);
            }
        };

        previousStepFor[Constants.Page.ShippingAddress] = function () {
            go(Constants.Page.InvalidAddress);
        };

        nextStepFor[Constants.Page.HowToQualify] = function (options) {
            goHome(options);
        };

        nextStepFor[Constants.Page.About] = function () {
            go(Constants.Page.PersonalInformation);
        };

        nextStepFor[Constants.Page.Exit] = function () {
            if (StorageService.get(Constants.SK.Error)) {
                return go(Constants.Page.Exit);
            }
        };

        nextStepFor[Constants.Page.ServiceCancellation] = function () {
            go(Constants.Page.ServiceCancelConfirm);
            return;
        };

        nextStepFor[Constants.Page.Login] = function () {
            if (StorageService.get(Constants.SK.Error))
                return go(Constants.Page.Exit);

            goToMyAccount();
        };
		
        // Shared Steps

        function sharedStartFlow() {
			
            return go(Constants.Page.PersonalInformation);
        }

		function sharedCheckErrors() {
			
			var error = StorageService.get(Constants.SK.Error);
			
			if (!error) {
				return true;
			}
			
			switch (error) {
			
				case Constants.Error.INVALID_ADDRESS:
					var nextStep = Constants.Page.ShippingAddress;
				
					go(nextStep);
					break;
					
				case Constants.Error.PERSON_:
					go(Constants.Page.InvalidAddress);
					break;
					
				default:
					goToExit();
					break;

			}
			
            return false;
		}

        function sharedFlowEnrollmentCompleted() {
            var enrollment = DTOFactory.Application();
            if (enrollment.errors) {
                go(Constants.Page.Exit);
                return;
            }

            $cookies.remove(Constants.Cookie.ZipCodeToRedirect);

            return go(Constants.Page.ApplicationCompleted);
        }
		
        function sharedSubmitDocumentationFlow(options) {
            
			var stepCode = null;
			var currentStepCode = StorageService.get(Constants.SK.UploadProofStep);
			
			switch (currentStepCode) {
				case Constants.UploadProofStep.RequiredDocumentation:
					stepCode = Constants.UploadProofStep.RequiredDocumentationUpload;
					break;
					
				case Constants.UploadProofStep.RequiredDocumentationUpload:
					if (options && options.takePhoto) {
						stepCode = Constants.UploadProofStep.CameraView;
					}
					else if (options && options.review) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationReview;
					}					
					else if (options && options.createFromAnother) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationSubmit;
					}
					else if (options && options.skip && Utils.areProofsStillNeeded()) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationUpload;
					}
					else {
						stepCode = Constants.UploadProofStep.RequiredDocumentationStatus;
					}
					
					break;
					
				case Constants.UploadProofStep.CameraView:
                    if (options && options.review) {
                        stepCode = Constants.UploadProofStep.RequiredDocumentationReview;
                    }
					else {
                        stepCode = Constants.UploadProofStep.RequiredDocumentationUpload;
                    }
					
					break;					
					
				case Constants.UploadProofStep.RequiredDocumentationReview:
					if (options && options.askAdditionalFile) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationAdditional;
					}
					if (options && options.doSubmit) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationSubmit;
					}
					else {
						stepCode = Constants.UploadProofStep.RequiredDocumentationUpload;
					}
					
					break;
					
				case Constants.UploadProofStep.RequiredDocumentationAdditional:
					if (options && options.askAdditionalFile) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationUpload;
					}
					else {
						stepCode = Constants.UploadProofStep.RequiredDocumentationStatus;
					}				
					
					break;
					
				case Constants.UploadProofStep.RequiredDocumentationStatus:
					if (options && options.uploadProofs) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationUpload;
					}
					else if (options && options.restart) {
						stepCode = Constants.UploadProofStep.RequiredDocumentation;
					}					
                    else if (Constants.Page.MyAccountSubmitDocumentation == getCurrentState()) {
                        goToMyAccount({ 
                            fromLogin: false,
                            hash: Utils.GUID()
                        });
                    }
                    else if (options && options.submit) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationSubmit;
					}
					else {
                        goToMyAccount({ 
                            fromLogin: false,
                            hash: Utils.GUID(),
                            tabSelected: Constants.Page.MyAccountDetails
                        });
					}
					
					break;

				case Constants.UploadProofStep.RequiredDocumentationSubmit:
					if (options && options.nextProof) {
						stepCode = Constants.UploadProofStep.RequiredDocumentationUpload;
					}
					else if (options && options.error) {
                        goToMyAccount({ 
                            fromLogin: false,
                            hash: Utils.GUID(),
                            tabSelected: Constants.Page.MyAccountDetails
                        });
					}
					else {
						stepCode = Constants.UploadProofStep.RequiredDocumentationSubmitSuccess;
					}
					
					break;
					
				case Constants.UploadProofStep.RequiredDocumentationSubmitSuccess:
					if (options && options.restart) {
						stepCode = Constants.UploadProofStep.RequiredDocumentation;
					}
					else {
                        goToMyAccount({ 
                            fromLogin: false,
                            hash: Utils.GUID(),
                            tabSelected: Constants.Page.MyAccountDetails
                        });
					}
					
					break;					
			}
			
			StorageService.set(Constants.SK.UploadProofStep, stepCode);
        }		
        		
		function goToMyAccountSubmitDocumentation() {
			$state.go(Constants.Page.MyAccountSubmitDocumentation);
        }
        function goHome(options) {
            $state.go(Constants.Page.Login, options);
        }

        function goToZipCode(previous) {
            go(Constants.Page.Zipcode, previous);
        }

        function goToMyAccount(options) {
            go(Constants.Page.MyAccount, options);
        }

        function goToLogin(options) {
            Utils.removeLoginFromStorage();
            go(Constants.Page.Login, options);
        }

        function goToProgramEligibility() {
            go(Constants.Page.ProgramEligibility, {
				avoidUpdateStep: true
			});
        }

        function goToIncomeEligibility() {
            go(Constants.Page.IncomeEligibility, {
				avoidUpdateStep: true
			});
        }

        function goToPersonalInformation() {
            go(Constants.Page.PersonalInformation);
        }

        function goToMaintenance() {
            $state.go(Constants.Page.Maintenance);
        }

        function goToCheckEbb() {
            var url = Constants.Url.CheckAcp;
            $window.open(url, '_blank');
        }

        function goToReview() {
            var application = DTOFactory.Application();

            if (application.NladApplication.UseTransferException) {
                return go(Constants.Page.TransferException);
            }
			
			go(Constants.Page.Review);
        }

        function goToBenefitSelection() {
			$state.go(Constants.Page.BenefitSelection);
        }

        function goToExit() {
            $state.go(Constants.Page.Exit);
        }

        function nextStepTransferExceptions(nextOptions) {

            if (nextOptions.aboveSelected) {
                StorageService.set(Constants.SK.Error, Constants.Error.TRANSFER_LIMIT);
                return go(Constants.Page.Exit);
            } 
			
			return go(Constants.Page.Review);
        }

        // Public

        return {
            nextStep: nextStep,
            previousStep: previousStep,
            goHome: goHome,
            goToZipCode: goToZipCode,
            goToMyAccount: goToMyAccount,
            goToLogin: goToLogin,
            updateCurrentStep: updateCurrentStep,
            updatePreviousState: updatePreviousState,
            getPreviousState: getPreviousState,
            getCurrentState: getCurrentState,
            goToMaintenance: goToMaintenance,
            goToProgramEligibility: goToProgramEligibility,
            goToIncomeEligibility: goToIncomeEligibility,
            goToPersonalInformation: goToPersonalInformation,
            goToCheckEbb: goToCheckEbb,
            goToReview: goToReview,
            goToExit: goToExit,
            goToBenefitSelection: goToBenefitSelection,
            goToMyAccountSubmitDocumentation: goToMyAccountSubmitDocumentation
        };		
    }
})();
