(function () {
    'use strict';

    angular.module('l4b')
        .service('BrandService', BrandService);

    function BrandService($window, Constants, StorageService) {
        var self = this;

        self.register = function () {
			
            var domain = $window.location.hostname;
			
			var brandObj = null;
			
            for (var brand in Constants.Brand) {
                if (Constants.Brand[brand].Domain == domain) {
                    brandObj = Constants.Brand[brand];
                }
            }
			
			StorageService.set(Constants.SK.Brand, brandObj || getDefault());
		};

        // Brand

        self.isDefault = function () {
            return self.getCurrent().Code === Constants.Brand.VZ.Code;
        };

        self.getCurrent = function () {
            var current = StorageService.get(Constants.SK.Brand);
            if (!current) {
                current = getDefault();

                StorageService.set(Constants.SK.Brand, current);
            }

            return current;
        };

        self.getURLWebPage = function(){            
            return Constants.Brand.VZ.UrlWebPage;
        };

        function getDefault() {
            return Constants.Brand.VZ;
        }
    }
})();
