(function () {
    'use strict';

    angular.module('l4b')
        .component('reviewPhoto', {
            templateUrl: 'html/shared/reviewPhoto/reviewPhoto.html',
 			bindings: {
				nextStep: '&'
			},			
			controller: ReviewPhotoController,
			controllerAs: 'vm'
        });

    ReviewPhotoController.$inject = ['Constants', 'StorageService'];

    function ReviewPhotoController(Constants, StorageService) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            retake: retake,
			accept: accept,
            imageSrc: StorageService.localGet(Constants.SK.CameraData)
        });

        function init() {
        }

        function retake() {
            
            vm.nextStep({ options: { retake: true }});
        }
        
		function accept() {
            
            vm.nextStep({ options: { imageSrc: vm.imageSrc }});
        }
    }
})();

