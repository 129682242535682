(function () {
    'use strict';

    angular.module('l4b')
        .controller('ServiceCancellationController', ServiceCancellationController);

    function ServiceCancellationController(Constants, GlobalizationService, MyAccountService, RestService, DTOFactory, Utils, GeolocationService) {
        var vm = this,
            application;

        angular.extend(vm, {
            // Hooks
            $onInit: init,

            benefitTypes: [],
			
			Constants: Constants,

            reasons: [],
            error: false,
            submitted: false,
            warning: false,

            // Methods
            cancelService: cancelService,
            goBack: MyAccountService.GoToMyAccount,
			
			firstNameChanged: firstNameChanged,
			lastNameChanged: lastNameChanged
        });

        function init() {

            application = DTOFactory.Application();
            if (!canCancel()) {
                return MyAccountService.GoTo(Constants.Page.MyAccountDetails);
            }
			
			vm.firstNameParam = application.Applicant.FirstName;
			vm.lastNameParam = application.Applicant.LastName;

            getDeEnrollReasons();
            loadBenefitTypes();
        }

        function getDeEnrollReasons() {
            RestService.getDeEnrollReasons(onSuccessGetReasons);
        }

        function hasActiveBenefitType(benefitTypeCode, statuses) {
			var requiredApp = [application, application.relatedApplication].find(function(app) {
				return app && app.BenefitTypeCode == benefitTypeCode;
			});
			
            return requiredApp && statuses.indexOf(requiredApp.Status.Code) >= 0;
        }

        function loadBenefitTypes() {
			
			var isAcpEnrolled = hasActiveBenefitType(Constants.BenefitType.ACP, [Constants.Status.ENROLLED]);

			if (isAcpEnrolled) {
				vm.benefitTypes.push(Constants.BenefitType.ACP);
			}

            if (vm.benefitTypes.length === 1) {
                vm.selectedBenefitType = vm.benefitTypes[0];
            }
        }

        function onSuccessGetReasons(reasons) {
            vm.reasons = reasons;
        }

        function cancelService(formObj) {
            if (!Utils.validateForm(formObj)) {
                return;
            }

            if (!vm.warning) {
                vm.warning = true;
                return;
            }
			
			var request = {
				Comment: '',
				BenefitTypeCode: vm.selectedBenefitType,
				ChangeReasonCode: vm.selectedReason ? vm.selectedReason.code : null,
				ChangeSourceCode: 'CustomerRequest',
				lang: GlobalizationService.getLang().toUpperCase(),
				Latitude: null,
				Longitude: null
			};

            GeolocationService.register(true)
				.then(function() {
					var geolocation = DTOFactory.Geolocation();
					
					request.Latitude = geolocation.latitude;
					request.Longitude = geolocation.longitude;
				})
				.finally(function() {
					RestService
						.serviceCancellation(request)
						.then(function () {
		
							// Refresh application
							MyAccountService.GetApplication(function () {
		
								MyAccountService.GoTo(Constants.Page.MyAccountDetails, {
									messageCode: Constants.PublicSite.MyAccountMessageCode.ServiceCancelled
								});
		
							});
						})
						.catch(function () {
							vm.error = true;
						});
					
				});           
        }

        function canCancel() {
            return application.Status.Code == Constants.Status.ENROLLED;
        }
		
        function firstNameChanged(form){
            form.firstName.$setValidity('validationFirstNameError', form.firstName.$modelValue == vm.firstNameParam);
        }
		
        function lastNameChanged(form){
            form.lastName.$setValidity('validationLastNameError', form.lastName.$modelValue == vm.lastNameParam);
        }		
    }
})();
