(function () {
	'use strict';

	angular.module('l4b')
		.component('airMessage', {
			templateUrl: 'html/shared/airMessage/airMessage.html',
			controller: AirMessageController,
			controllerAs: 'vm'
		});

	function AirMessageController($scope, $timeout, $window, $rootScope, StorageService, Constants, Utils, GlobalizationService, FlowService, BrandService, TimeoutService) {

		var vm = this,
			lang = GlobalizationService.getLang();

		angular.extend(vm, {
			$onInit: init,
			isCA: false,
			goToAirMessage: goToAirMessage,
			closeAirMessage: closeAirMessage,
			showAirMessage: false,
			showButton: true,
			secondaryBtn: false,
			showCloseButton: true,
			showCounter: false,
			counterSeconds: 0,
			counterType: '',
			buttonDismiss: false,
			airMessageStatus: Constants.AirMessageType.INFO,
			airMessage: null,
			airMessageSecondary: null,
			airMessageButtonText: null,
			airMessageButtonTextSecondary: null,
			airMessageLink: null,
			isAllowedPage: false,
			isAllowedPages: isAllowedPages,
			secondaryButtonClick: secondaryButtonClick,
			section: ''
		});

		function init() {
			$scope.$on('zipcode-changed', function (event, data) {
				$timeout(function () {
					loadData(data);
				});
			});

			$scope.$on('show-air-message', function (event, args) {
				$timeout(function () {
					openAirMessage(args);
				});
			});

			$scope.$on('close-air-message', function () {
				$timeout(function () {
					closeAirMessage();
				});
			});

			var requestedZipcode = StorageService.get(Constants.SK.RequestedZipcode);
			loadData(requestedZipcode);
		}

		function loadData(data) {
			if (data) {
				checkState(data);
			}

			if (vm.showAirMessage) {
				getStoredValues();
			}
		}

		function checkState(data) {
			vm.isCA = data.State === Constants.State.California;
		}

		function openAirMessage(options) {
			vm.airMessageStatus = Constants.AirMessageType.INFO;
			vm.airMessageLink = {
				href: null,
				target: '_blank'
			};
			vm.section = '';

			if (options) {
				if(options.callback) {
					vm.callback = options.callback;
				}
				vm.airMessageLink = options;
				vm.airMessage = options.message || GlobalizationService.get('AIR_MESSAGE_TEXT_DEFAULT', lang);
				vm.airMessageButtonText = options.buttonText || GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);
				if (options.showButton != undefined) {
					vm.showButton = options.showButton;
				}
				vm.showCounter = options.showCounter;

				if (vm.showCounter) {
					vm.showCloseButton = false;
					vm.counterSeconds = options.counterSeconds;
					vm.counterType = GlobalizationService.get('AIR_MESSAGE_COUNTDOWN_SECONDS', lang);
					countDown();
				}
			}
			else {
				getStoredValues();
			}

			vm.showAirMessage = true;
		}

		function getStoredValues() {
			vm.section = StorageService.get(Constants.SK.AirMessageSection);

			var sectionList = Constants.Section;

			switch (vm.section) {
				case sectionList.MyDevice:
					vm.airMessageLink.href = Constants.Url.MyPhone[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_MY_DEVICE', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);
					break;
				case sectionList.HearingAidCompatibility:
					vm.airMessageLink.href = Constants.Url.HearingAid;
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_ACCESIBILITY', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_YES', lang);
					break;
				case sectionList.WindowsPhoneAccesibility:
					vm.airMessageLink.href = Constants.Url.WindowsPhoneAccesibility[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_ACCESIBILITY', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_YES', lang);
					break;
				case sectionList.AppleAccesibility:
					vm.airMessageLink.href = Constants.Url.AppleAccesibility[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_ACCESIBILITY', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_YES', lang);
					break;
				case sectionList.GoogleAccesibility:
					vm.airMessageLink.href = Constants.Url.GoogleAccesibility[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_ACCESIBILITY', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_YES', lang);
					break;
				case sectionList.ShopGlobal:
					vm.airMessageLink.href = vm.isCA ? Constants.Url.ShopGlobalCA[lang] : Constants.Url.ShopGlobal[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_SHOP_GLOBAL', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);
					break;
				case sectionList.UpgradePhoneByop:
					vm.airMessageLink.href = vm.isCA ? Constants.Url.UpgradePhoneByopCA[lang] : Constants.Url.UpgradePhoneByop[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_UPGRADE_PHONE_BYOP', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);
					break;
				case sectionList.UpgradePlan:
					vm.airMessageLink.href = vm.isCA ? Constants.Url.UpgradePlanCA[lang] : Constants.Url.UpgradePlan[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_DEFAULT', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);
					break;
				case sectionList.ListOfRates:
					vm.airMessageLink.href = vm.isCA ? Constants.Url.ListOfRatesCA : Constants.Url.ListOfRates[lang];
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_DEFAULT', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);
					break;
				case sectionList.MyAccountLogin:
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_SESSION_EXPIRED', lang);
					vm.showButton = false;
					break;
				case sectionList.MyAccountLoginZipcodeChanged:
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_ZIPCODE_CHANGED', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_DISMISS', lang);
					vm.showCloseButton = false;
					vm.buttonDismiss = true;
					break;
				case sectionList.FccGovHearAidCompatibility:
					vm.airMessageLink.href = Constants.Url.FccGovHearAidCompatibility;
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_TEXT_ACCESIBILITY', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);
					break;
				case sectionList.MyServiceTimeOut:
					vm.airMessageStatus = Constants.AirMessageType.WARNING;	
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_SESSION_TIMEOUT_WARNING', lang);
					vm.airMessageButtonTextSecondary = GlobalizationService.get('AIR_MESSAGE_BUTTON_TEXT_CONTINUE', lang);

					vm.showButton = false;
					vm.secondaryBtn = true;
					vm.showCloseButton = false;
					break;
				case sectionList.KioskTimeoutEnrollment:
					vm.airMessageStatus = Constants.AirMessageType.WARNING;	
					vm.airMessage = GlobalizationService.get('AIR_MESSAGE_KIOSK_TIMEOUT', lang);
					vm.airMessageSecondary = GlobalizationService.get('AIR_MESSAGE_KIOSK_TIMEOUT_SECONDARY', lang);
					vm.airMessageButtonText = GlobalizationService.get('AIR_MESSAGE_BUTTON_CONTINUE_SESSION', lang);
					vm.airMessageButtonTextSecondary = GlobalizationService.get('AIR_MESSAGE_BUTTON_END_SESSION', lang);
					vm.counterType = GlobalizationService.get('AIR_MESSAGE_COUNTDOWN_SECONDS', lang);
					
					vm.showButton = true;
					vm.secondaryBtn = true;				
					vm.showCloseButton = false;

					vm.showCounter = true;
					vm.counterSeconds = Constants.SK.AirMessageKioskSeconds;					
					countDown();
					break;
			}
		}

		function goToAirMessage() {
			if (vm.section == Constants.Section.KioskTimeoutEnrollment) {
				$timeout(function () {
					TimeoutService.checkIdleKiosk();
				}, 100);
			}
			else if (!vm.buttonDismiss) {
				if (!vm.airMessageLink.href) return;

				if (vm.airMessageLink.target && vm.airMessageLink.target === '_blank') {
					$window.open(vm.airMessageLink.href);
				} else {
					$window.location.href = vm.airMessageLink.href;
				}
			}

			closeAirMessage();
		}

		function closeAirMessage() {
			vm.showAirMessage = false;

			StorageService.remove(Constants.SK.AirMessageSection);

			vm.airMessageLink = null;
			vm.airMessage = null;
			vm.airMessageButtonText = null;
			vm.showButton = true;
			vm.showCloseButton = true;
			vm.buttonDismiss = false;
			vm.secondaryBtn = false;
		}

		function isAllowedPages() {
			return true;
		}

		function countDown() {
			$timeout(function () {
				if (vm.counterSeconds > 0) {
					vm.counterSeconds--;
					countDown();
					return;
				}
				if (vm.airMessageLink && vm.airMessageLink.href) {
					$window.open(vm.airMessageLink.href, vm.airMessageLink.target);
				}
				if(vm.callback) {
					vm.callback();
				}
			}, 1000);
		}

		function secondaryButtonClick() {
			$timeout(function () {
				closeAirMessage();
				if (vm.section == Constants.Section.KioskTimeoutEnrollment) {
					FlowService.goHome();
				}
				if (vm.section == Constants.Section.MyServiceTimeOut) {
					TimeoutService.setTimeOutMyService();
				}				
			}, 100);
		}
	}
})();