(function () {
    'use strict';

    angular.module('l4b')
        .component('cameraView', {
            templateUrl: 'html/shared/cameraView/cameraView.html',
            bindings: {
				isDocument: '<',
                nextStep: '&'
            },
            controller: CameraViewController,
            controllerAs: 'vm'
        });

    CameraViewController.$inject = ['Constants', '$scope', 'StorageService'];

    function CameraViewController(Constants, $scope, StorageService) {
        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            back: next,
            takePhoto: takePhoto,
            cameraLoading: true
        });

        function init() {
        }

        function next(review) {

            $scope.$broadcast('turnOffCamera');

			vm.nextStep({ options: { review: review }});
        }

        function takePhoto() {
            $scope.$broadcast('takePhoto', function(imageSrc) {
				
				StorageService.localSet(Constants.SK.CameraData, imageSrc);
				
				next(true);
			});
        }

    }
})();
