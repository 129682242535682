(function () {
    'use strict';

    angular.module('l4b').config(ApplyRouting);

    function ApplyRouting($stateProvider, Constants) {

        $stateProvider

            .state('mainFlow', {
                url: '',
                templateUrl: 'html/main/apply.html',
                abstract: true,
                authorizations: [Constants.AuthProviders.APPLY]
            })

            .state(Constants.Page.ApplicantEligibility, {
                url: '/applicantEligibility',
                templateUrl: 'html/modules/apply/applicantEligibility/applicantEligibility.html',
                controller: 'ApplicantEligibilityController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.Review, {
                url: '/review',
                templateUrl: 'html/modules/apply/reviewApplication/reviewApplication.html',
                controller: 'ReviewApplicationController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.TransferException, {
                url: '/transferException',
                templateUrl: 'html/modules/apply/transferException/transferException.html',
                controller: 'TransferExceptionsController',
                controllerAs: 'vm'          
            })
            ;
    }
})();
