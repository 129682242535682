(function () {
	'use strict';

	angular.module('l4b')
		.component('mainAddress', {
			templateUrl: 'html/shared/mainAddress/mainAddress.html',
			bindings: {
				address: '<',
				disableInputs: '<',
				addressForm: '<',
				allowChangeState: '<',
				suggestedAddress: '<'
			},			
			controller: MainAddressController,
			controllerAs: 'vm',
			
		});

	MainAddressController.$inject = ['Constants'];

	function MainAddressController(Constants) {

		var vm = this;

		angular.extend(vm, {
			patterns: setPattern()
		});
		
        function setPattern() {
            return {
                address: Constants.Pattern.ADDRESS
            };
        }		
	}
})();
