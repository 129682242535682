(function () {
    'use strict';

    angular.module('l4b')
        .controller('ReviewPhotoController', ReviewPhotoController);

    ReviewPhotoController.$inject = ['Constants', 'FlowService', 'StorageService', '$timeout', 'RestService'];

    function ReviewPhotoController(Constants, FlowService, StorageService, $timeout, RestService) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            nextStep: nextStep,
            retry: uploadPicture,
			cancel: cancel,
            goHome: goHome,
            ReviewStep: Constants.ReviewStep,
            currentStep: Constants.ReviewStep.Review
        });

        function init() {
        }

        function nextStep(options) {
            if (options && options.retake) {
                FlowService.nextStep(options);
                return;
            }
			
            uploadPicture(options);
        }

        function onSuccess() {                   

            FlowService.nextStep();
        }

        function onError() {
            vm.currentStep = vm.ReviewStep.Error;
        }

        function cancel() {
            vm.currentStep = vm.ReviewStep.Cancel;
        }

        function goHome() {
            $timeout(FlowService.goHome(), 200);
        }

        function uploadPicture(options) {
            var applicationToken = StorageService.get(Constants.SK.Enrollment).applicationToken;

            var payload = getPicturePayload(options.imageSrc, Constants.Requirement.ApplicantPicture.Code);

            RestService.submitProof(payload, applicationToken, onSuccess, onError);
        }

        function getPicturePayload(pic, requirementCode) {
            var mainImg = pic.split(',')[1];
            return {
                MainImage: mainImg,
                ContentType: "image/",
                SecondaryImage: null,
                ContentType_SecondaryImage: "image/",
                PreclassifiedRequirementCodes: [requirementCode]
            };

        }
    }
})();

