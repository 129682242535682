(function () {
	'use strict';

	angular.module('l4b')
		.controller('SidePagesController', SidePagesController);

	SidePagesController.$inject = ['BrandService'];

	function SidePagesController(BrandService)
	{
		var vm = this;

		angular.extend(vm, {
			$onInit: init
		});

		function init(){
			var brand = BrandService.getCurrent();
			
			vm.currentNumber = brand.PhoneNumber;
		}		
	}
})();
