(function () {
    'use strict';

    angular.module('l4b')
        .factory('FlowExecutionService', FlowExecutionService);

    function FlowExecutionService(RestService, StorageService, Constants, $q) {

        var _initFlowPromise = null;

        return {
            register: register,
            logFlowExecutionEvent: logFlowExecutionEvent,
            onFlowInitialized: onFlowInitialized
        };

        function register(geolocationInitPromise) {
            initFlowExecution(geolocationInitPromise);
        }
        
        function initFlowExecution(geolocationInitPromise) {

            var deferred = $q.defer();
            _initFlowPromise = deferred.promise;

            if (mustInitializeFlowExecution()) {
                geolocationInitPromise
                    .finally(function(){
                        RestService.initFlowExecution(
                            function onSuccess(){

                            },
                            function onError(){
                                // ignore silently?
                            }).then(function(){
                                deferred.resolve();
                            });

                    });
            } else {
                deferred.resolve();
            }

        }

        function onFlowInitialized() {
            //  the flow init promise is returned, so we can hook your code to it
            //  in case we need it (for instance the call to zipcode has to pass the flow token or
            //  the events won't be tracked)
            return _initFlowPromise;
        }

        function logFlowExecutionEvent(eventTypeCode, payload) {

            _initFlowPromise.then(function(){

                RestService.logFlowExecutionEvent(
                    eventTypeCode,
                    payload,
                    function onSuccess(){
                    },
                    function onError(){
                        // ignore silently?
                    });

            });

        }

        function mustInitializeFlowExecution() {
            var token = StorageService.get(Constants.SK.FlowExecutionToken);

            var hasToken = !!token;
            var isHomePage = window.location.hash == '' 
                || window.location.hash.match(/^#!\/home/i);

            return isHomePage || !hasToken;

        }

    }
})();
