(function() {
	'use strict';
	
	angular.module('l4b')
	.component('enrollmentHeaderFlow', {
	  templateUrl: 'html/shared/headerFlow/enrollmentHeaderFlow.html',
	  controller: HeaderFlowController,
	  controllerAs: 'vm'
	});

	HeaderFlowController.$inject=['FlowService', 'Constants'];

	function HeaderFlowController(FlowService, Constants) {
		var vm = this;
		
		angular.extend(vm,{
			goHome: goHome,
			isPersonalInfoScreen: isPersonalInfoScreen,
			isLanding: isLanding
		});

		function goHome(){
			FlowService.goHome();
		}

		function isPersonalInfoScreen(){
			return FlowService.getCurrentState() == Constants.Page.PersonalInformation;
		}

		function isLanding(){
			return FlowService.getCurrentState() == Constants.Page.Landing;
		}
	}

})();