(function () {
	'use strict';

	angular.module('l4b')
		.component('phoneBox', {
			templateUrl: 'html/shared/phoneBox/phoneBox.html',
			controllerAs: 'vm',
			bindings: {
				image: '<',
				name: '<',
				discount: '<',
				shortDescription: '<',
				longDescription: '<'
			}
		});
})();