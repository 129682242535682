(function () {
    'use strict';

    angular.module('l4b')
        .controller('MyAccountDetailsController', MyAccountDetailsController);

    function MyAccountDetailsController($scope, $stateParams, $window, $document, $rootScope, Utils, Constants, StorageService, RestService, MyAccountService, GlobalizationService, FeaturesService, FlowService, $timeout) {
        var vm = this;

        angular.extend(vm, {
            // Injects
            Constants: Constants,

            // Properties
            natVerEligibilityChecked: false,
            natVerEligibilityThanksHidden: false,
            natVerCanValidateEligibility: false,
            isVerizonForwardFlow: false,
            hasPendingProof: false,
            // Methods
            natVerCheckEligibility: natVerCheckEligibility,
            natVerEligibilityHideThanks: natVerEligibilityHideThanks,
            goToMyAccountSubmit: goToMyAccountSubmit,

            //natVer properties
            apiValues: null,

            isNatVerApiActive: FeaturesService.get().NatVerApi,

            goToNvApiPortal: goToNvApiPortal,

            $onInit: init,
            enrollment: null,
            canCancel: false,
            actionMessage: null,
            showUpdateTitle: true,
            showCancelTitle: false,
            flagActionMessages: null,
            showDetails: false,
			
			showAcpRecertification: false,
			canRecertifyByPhone: false,
			
			downloadRecertForm: downloadRecertForm,
			recertOnline: recertOnline,
            getStatusLabel: getStatusLabel
        });

        $scope.$on('enrollment-changed', function () {
            init();
        });

        function init() {

            vm.enrollment = StorageService.get(Constants.SK.Application);
            
            if (isAbleToProcessNatVerApiResult(vm.enrollment)) {
				vm.apiValues = Utils.getNatVerApiValues();
            }

			MyAccountService.GetRetryElegibilityAvailability(vm.enrollment.ApplicationToken)
			.then(function (response) {
				vm.natVerCanValidateEligibility = response;
			});

            if ($stateParams && $stateParams.messageCode) {
                var keyToTranslate = $stateParams.messageCode;

                if ($stateParams.messageCodeFrom) {
                    keyToTranslate += "_" + $stateParams.messageCodeFrom;
                }

                switch ($stateParams.messageCode) {
                    case Constants.PublicSite.MyAccountMessageCode.ServiceCancelled:
                        vm.showUpdateTitle = false;
                        vm.showCancelTitle = true;
                        break;
                    default:
                        vm.showUpdateTitle = true;
                        vm.showCancelTitle = false;
                        break;
                }

                vm.actionMessage = GlobalizationService.get(keyToTranslate);
            }

            vm.showDetails = false;

            onGetApplicationSuccess();
			
            vm.options = {
                makePurchase: false,
                activateMyService: false
            };
            var config = StorageService.get(Constants.SK.Configuration);
            vm.isAcpWindDownEnabled = config.isAcpWindDownEnabled;
           
        }

        function onGetApplicationSuccess() {
            initView();

            // Refresh parent to show proper tabs.
            $scope.$parent.vm.showTabs();
        }

        function isAbleToProcessNatVerApiResult(application) {
            return application.Status.Code != Constants.Status.DEENROLLED
                && application.Status.Code != Constants.Status.UNQUALIFIED
                && application.Status.Code != Constants.Status.REJECTED;
        }

        function natVerCheckEligibility() {
            RestService.checkNatVerEligibility().finally(onCheckNatVerEligibilitySuccess);
        }

        function onCheckNatVerEligibilitySuccess() {
            vm.natVerEligibilityChecked = true;
        }

        function natVerEligibilityHideThanks() {
            vm.natVerEligibilityThanksHidden = true;
        }

        // Local Utils
        function initView() {
            var config = StorageService.get(Constants.SK.Configuration);
            vm.enrollment = StorageService.get(Constants.SK.Application);
            Utils.setFocus(angular.element($document[0].querySelector('#focus-set')));

            vm.isQualified = isStatus(Constants.Status.QUALIFIED);
            vm.isPending = isStatus(Constants.Status.PENDING);
            vm.isEnrolled = isStatus(Constants.Status.ENROLLED);
            vm.isDeEnrolled = isStatus(Constants.Status.DEENROLLED);
            vm.isUnqualified = isStatus(Constants.Status.UNQUALIFIED);
            vm.isRejected = isStatus(Constants.Status.REJECTED);
			
			var recertification = vm.enrollment.Recertification;
            vm.isNewCustomer = vm.enrollment.CustomerTypeCode == "N";
            vm.isVerizonForwardFlow = vm.enrollment.BenefitTypeCode == Constants.BenefitType.VZF;
			
            var verizonForwardNewCustomerAllowedBusinessLines = config.verizonForwardNewCustomerAllowedBusinessLines.split(',');


		    vm.isVerizon3Flow = vm.isVerizonForwardFlow && verizonForwardNewCustomerAllowedBusinessLines.includes(vm.enrollment.BusinessLineCode) && !vm.enrollment.AccessTokenDto.IsVerizonManualFlow;
            
            var allowRedirectToVerizon = !vm.isVerizon3Flow || config.allowRedirectOnVZF3;	
            
            var verizonFwd3ShowTrackStatus = vm.isVerizon3Flow && (vm.isQualified  ||  vm.isUnqualified ||  vm.isRejected)  && allowRedirectToVerizon
            
            vm.showTrackStatus = !vm.isVerizonForwardFlow || verizonFwd3ShowTrackStatus;
           
            vm.hasPendingProof = vm.enrollment.hasPendingProof;


			vm.showAcpRecertification = isStatus(Constants.Status.ENROLLED)
				&& recertification !== null 
				&& recertification.Status.Code.toUpperCase() !== Constants.Status.RECERTIFIED
				&& recertification.Status.Code.toUpperCase() !== Constants.Status.DEENROLLED;
				
			vm.canRecertifyByPhone = recertification !== null && recertification.type.toUpperCase() == 'S';

            vm.statusStyle = getStatusStyle(vm.enrollment.Status.Code);
            vm.notificationStyle = getNotificationStyle();

			setFlagActionMessages(vm.enrollment.flags);

            $timeout(function(){
                vm.showDetails = true;
            },200); 

            vm.natVerApplicationId = vm.enrollment.NladApplication.NatVerApplicationId;

            if(vm.isQualified && allowRedirectToVerizon) {
				
				$rootScope.$broadcast('show-air-message', {
                    message: GlobalizationService.get('AIR_MESSAGE_COUNTDOWN_TEXT'),
                    showButton: false,
                    showCounter: true,
                    counterSeconds: config && config.redirectTimeoutInSeconds,
                    callback: function() {
						goToVerizon();
                    }
                });
			}
        }

        function goToMyAccountSubmit() {
            FlowService.goToMyAccountSubmitDocumentation();
        }

        function isStatus(status) {
            return vm.enrollment.Status.Code === status;
        }
		
		function downloadRecertForm() {
			var a = $window.document.createElement('a');
			a.href = Constants.Url.DownloadRecertForm[GlobalizationService.getLang()];
			a.target = '_blank';
			a.click();
		}

		function recertOnline() {

			var url = Constants.Url.RecertOnline;
			var natverInfo = vm.enrollment.natVerApiTransactionInfo;
			
			if (!vm.canRecertifyByPhone && natverInfo && natverInfo.url) {
				url = natverInfo.url;
			}
			
			var a = $window.document.createElement('a');
			a.href = url;
			a.target = '_blank';
			a.click();
		}
        
        function getStatusLabel() {
            var statusCode = vm.enrollment.Status.Code;
            if(statusCode == Constants.Status.UNQUALIFIED || statusCode == Constants.Status.REJECTED) {
               return GlobalizationService.get('NOT_ELIGIBLE').toUpperCase();
            }
            else {
                return vm.enrollment.Status.Name.toUpperCase();
            }
        }

        function setFlagActionMessages(flags) {
            vm.flagActionMessages = [];

            if (!flags) return;

            for (var key in flags) {
                if (flags.hasOwnProperty(key)) {
                    var flag = flags[key];
                    var obj = {};
                    switch (key.toLowerCase()) {
                        case Constants.Quest.ApplicationFlags.BILLING_ADDRESS_UPDATE_REQUIRED:
                        case Constants.Quest.ApplicationFlags.ADDRESS_REVERTED_BY_CALAD:
                            obj.key = key;
                            obj.style = 'error';
                            obj.title = GlobalizationService.get(key.toUpperCase() + '_TITLE');
                            obj.message = GlobalizationService.get(key.toUpperCase() + '_MESSAGE');
                            break;
                    }
                    if (flag && obj.message) {
                        vm.flagActionMessages.push(obj);
                    }
                }
            }
        }

        function getStatusStyle(statusCode) {
            
			switch (statusCode) {
                case Constants.Status.QUALIFIED:
                case Constants.Status.ENROLLED:
                    return 'qualified';

                case Constants.Status.UNQUALIFIED:
                case Constants.Status.REJECTED:
                case Constants.Status.DEENROLLED:
                    return 'error';

                case Constants.Status.PENDING:
                    return 'warning';

                default:
                    //the default behaviour must be BLACK
                    return '';
            }
        }        
		
		function getNotificationStyle() {
            
			if (vm.isPending && !vm.isVerizonForwardFlow && (vm.enrollment.AppliedUsingNatVerAppId || vm.apiValues.complete)) {
				return 'qualified';
			}
			
			return 'warning';
        }

		function goToNvApiPortal() {
			RestService.trackApplicationEvent(Constants.Event.NatVerApi.NATVER_REDIRECT_APP_NOT_FOUND_OR_NOT_COMPLETE);

            if (vm.apiValues && vm.apiValues.url) {
				$window.location.href = vm.apiValues.url;
				return;
            } 

			FlowService.goToCheckEbb();
		}
        
        function goToVerizon() {
            var event;
			var redirectUrl = vm.enrollment ? vm.enrollment.RedirectUrl : null;
            switch(vm.enrollment.Status.Code) {
                case Constants.Status.QUALIFIED:
                    event = "QUALIFIED_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.PENDING:
                    event= "PENDING_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.REJECTED:
                case Constants.Status.UNQUALIFIED:
                    event = "UNQUALIFIED_REDIRECT_TO_VERIZON";
                    break;
            }

            if(event) {
                RestService.trackApplicationEvent(
                    event,
					redirectUrl
                );
            }

			$window.location.href = redirectUrl;
		}

    }
})();
