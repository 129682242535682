(function () {
    'use strict';

    angular.module('l4b', ['ui.router', 'ui.router.state.events', 'ngMessages', 'ngAnimate', 'ui.validate', 'ngCookies', 'ngSanitize', 'ngAria', 'slickCarousel', 'ui.mask', 'angular-uuid', 'vcRecaptcha'])
        .run(runFunction);

    function runFunction(Utils, $window, AuthProvidersService, BrandService, Constants, GlobalizationService, StorageService, RestService, FlowExecutionService, DTOFactory, GTMService, GeolocationService,$rootScope) {
      
        init();

        function init() {
            
			// Misc
            initPrototypes();

			ReadUrlParams();

			InitConfiguration();
			
            // Register Services
            BrandService.register();
            GlobalizationService.register();
            AuthProvidersService.register();

            $rootScope.brandCode = BrandService.getCurrent().Code;
			
			// Before initializing flow execution, must determine geolocation
			var geolocationInit = GeolocationService.register();
    		FlowExecutionService.register(geolocationInit);

            storeTrackingParameters();
        }

        function storeTrackingParameters() {

            StorageService.set(Constants.SK.QueryParams, Utils.toBase64(Utils.getAllQueryString())); 

        }

		function ReadUrlParams() {

			StorageService.remove(Constants.SK.LandingEnrollmentNumber);

			var loginType = Utils.getQueryString('LoginType', true);
			if (loginType) {
				StorageService.set(Constants.SK.LandingLogInType, loginType.toLowerCase());
			}

			var eid = Utils.getQueryString('EID', true);
			if (eid) {
				StorageService.set(Constants.SK.LandingEnrollmentNumber, eid);
			}
		}

		function InitConfiguration() {

			if (!StorageService.get(Constants.SK.DefaultLogInType)) {
				RestService
					.getDefaultLogInType()
					.then(function(response) {
						StorageService.set(Constants.SK.DefaultLogInType, response.data);
					});
			}

            RestService
            .getConfiguration()
            .then(function(response){
                StorageService.set(Constants.SK.Configuration, response.data);
            })
		}		
		
        // Misc
        function initPrototypes() {
            initStringPrototypes();
            initRegExPrototypes();
        }

        function initStringPrototypes() {
            String.prototype.format = function () {
                var args = arguments;
                return this.replace(/{(\d+)}/g, function (match, number) {
                    return typeof args[number] != 'undefined' ? args[number] : match;
                });
            };

            String.prototype.equals = function (value, ignoreCase) {
                if (value) {
                    if (!ignoreCase) return this.toString() === value;
                    return this.toLowerCase() === value.toLowerCase();
                }
            };

            if (!String.prototype.startsWith) {
                String.prototype.startsWith = function (searchString, position) {
                    return this.substr(position || 0, searchString.length) === searchString;
                };
            }
        }

        function initRegExPrototypes() {
            Object.defineProperty(RegExp.prototype, "toJSON", {
                value: RegExp.prototype.toString
            });
        }
    }
})();
