(function() {
    'use strict';

	angular.module('l4b')
	.factory('MyAccountService', MyAccountService);

	MyAccountService.$inject=['Constants', 'StorageService', 'DTOFactory', 'FlowService', 'RestService', '$q', '$state', 'Utils', 'GlobalizationService', '$window', '$rootScope', 'ResponseAdapter'];

	function MyAccountService(Constants, StorageService, DTOFactory, FlowService, RestService, $q, $state, Utils, GlobalizationService, $window, $rootScope, ResponseAdapter) {
		
		var service = {
			GetApplication: GetApplication,
			LogOut: LogOut,
			GoTo: GoTo,
			GoToMyAccount: FlowService.goToMyAccount,
			UpdateInfo: UpdateInfo,
			SetActionsVisibility: SetActionsVisibility,
			ResetActionsVisibility: ResetActionsVisibility,
			GetRetryElegibilityAvailability: GetRetryElegibilityAvailability
		};

        return service;

        function LogOut(options) {
			ResetActionsVisibility();
			Utils.removeLoginFromStorage();

			if (!options || !options.stayInCurrentPage) {
				FlowService.goHome();
			}
		}

		function SetActionsVisibility(showUpdate, showCancel) {
				notifyActionsVisibility({
					showUpdate: showUpdate,
					showCancel: showCancel
				});
		}

		function ResetActionsVisibility() {
			notifyActionsVisibility({
				showUpdate: true,
				showCancel: true
			});
		}

        function GoTo(page, options, reload) {
        	if (!page) {
				return FlowService.GoToMyAccount();
			}
        	
        	if (options) {
        		$state.go(page, options, {
					reload: reload
				});
    		}
			else {
        		$state.go(page);
			}
        }
		
        function GetApplication(callback){
        	getApplication().then(callback);
        }

        function getApplication() {
        	var defered = $q.defer();
            var promise = defered.promise;

            var dto = StorageService.get(Constants.SK.AccountLogin);
            if (!dto){
            	FlowService.goToLogin();
				defered.resolve(false);
				return promise;
            }
			
			RestService.getApplication(onSuccess, onError);
			
        	function onSuccess(response) {
				var error = StorageService.get(Constants.SK.Error);
				if (error) {
					switch (error) {
						case Constants.Error.TOKEN:
						case Constants.Error.NOT_FOUND:
							StorageService.remove(Constants.SK.Error);
							FlowService.goToLogin();
							break;
						default:
							break;
					}

					defered.resolve(false);
	                return;
				}

				if (!response) {
					FlowService.goToLogin();
					return defered.resolve(false);
				}

				StorageService.set(Constants.SK.Application, response.data);

				defered.resolve(true);
			}

			function onError(){
				FlowService.goToLogin();
				return defered.resolve(false);
			}

			return promise;
		}

        function ContactInfoHasChanged(contactModel, contactFromSession) {
        	return JSON.stringify(contactModel) != JSON.stringify(contactFromSession);
        }

        function AddressInfoHasChanged(addressModel, shippingModel, sessionApplication) {
        	return JSON.stringify(addressModel) != JSON.stringify(sessionApplication.Address)
				|| JSON.stringify(shippingModel || {}) != JSON.stringify(sessionApplication.ShippingAddress || {});
		}

		function updateContactIntoEmerios(contactSession) {

			var payload = {
				contactInfo: contactSession
			};
				
			return RestService
				.updateContactByApplicant(payload);
		}
		
		function UpdateInfo() {
			var defered = $q.defer(),
				promise = defered.promise;
				
			StorageService.remove(Constants.SK.Error);
		
			var contactSession = StorageService.get(Constants.SK.UpdateContact),
				addressSession = StorageService.get(Constants.SK.UpdateAddress),
				application = DTOFactory.Application();

			var contactInfoHasChanged = ContactInfoHasChanged(contactSession, application.Applicant),
				addressInfoHasChanged = AddressInfoHasChanged(addressSession.Address, addressSession.ShippingAddress, application);
				
			if (!addressInfoHasChanged && !contactInfoHasChanged) {
				defered.resolve({ status: Constants.UpdateStatus.None });
			} 
			else if (addressInfoHasChanged) {
				RestService
					.updateAddressByApplicant()
					.then(function (response) {
						
						var error = StorageService.get(Constants.SK.Error);
						if (!error && contactInfoHasChanged) {
							updateContactIntoEmerios(contactSession)
								.then(function () {
									updateApplication(defered, response, Constants.UpdateStatus.AddressContactInfo);
								});

								
							return;
						}

						updateApplication(defered, response, Constants.UpdateStatus.AddressOnly);
					});
			}
			else {
				updateContactIntoEmerios(contactSession)
					.then(function () {
						updateApplication(defered, {}, Constants.UpdateStatus.ContactInfoOnly);
					});
			}

			return promise;
		}
		
		function updateApplication(defered, response, action) {
            var error = StorageService.get(Constants.SK.Error);
            if (error) {
				defered.resolve();

				return;
            }
			
			if (response.data.suggestedAddress) {
				response.SuggestedAddress = ResponseAdapter.getAddress(response.data.suggestedAddress);
				
				defered.resolve(response);
				
				return;
			}
			
			var application = DTOFactory.Application();
			
			if (action == Constants.UpdateStatus.AddressOnly || action == Constants.UpdateStatus.AddressContactInfo) {
				application.Address = ResponseAdapter.getAddress(response.data.address);
				application.ShippingAddress = ResponseAdapter.getAddress(response.data.shippingAddress);
			}

			if (action == Constants.UpdateStatus.ContactInfoOnly || action == Constants.UpdateStatus.AddressContactInfo) {
				var updateContact = StorageService.get(Constants.SK.UpdateContact);

				application.Applicant.Email = updateContact.Email;
			}
			
			StorageService.set(Constants.SK.Application, application);
			
			angular.extend(response, { action: action });
			
			defered.resolve(response);
		}

		function GetRetryElegibilityAvailability(appToken){
			var defered = $q.defer(),
				promise = defered.promise;

			RestService.isNatVerRetryElegibilityAvailable(appToken,onGetSuccess);
			
			function onGetSuccess(response) {

	            if (!response || !response.data) {
	                return defered.reject();
	            }

	            return defered.resolve(response.data);
	        }

			return promise;
		}

		function notifyActionsVisibility(options) {
			$rootScope.$broadcast('set-menu-options', options);
		}

    }
})();
