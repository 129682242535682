(function () {
    'use strict';

    angular.module('l4b')
        .component('transferException', {
            templateUrl: 'html/modules/_components/transfer-exception/transfer-exception.component.html',
            bindings: {
                fullNameParam: '<',
                hasLifelinePlan: '<',
                isApply: '<',
                transferDate: '<',
                continueNextStep: '&'
            },
            controller: TransferExceptionController,
            controllerAs: 'vm'
        });

    function TransferExceptionController(Constants, RestService, Utils) {
        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            Constants: Constants,
            transferExceptions: [],
            selectedException: '',
            nextStep: nextStep,
            isInvalidExceptionSelected: isInvalidExceptionSelected,
            todaysdate: new Date(),
            fullnameChanged:fullnameChanged
        });

        function init() {
            getTransferExceptionForm();
        }

        function getTransferExceptionForm() {
            RestService.getTransferExceptionForm(getTransferExceptionFormSuccess);
        }

        function getTransferExceptionFormSuccess(response) {
            vm.transferExceptions = response.fields;
        }

        function nextStep(form) {
            if (!Utils.validateForm(form)) return;

            if (vm.isApply) {
                RestService.transferExceptionEsign(vm.selectedException, vm.todaysdate, onSuccessTransferExceptionEsign);
            }
            else {
                RestService.transferExceptionOptinEsign(vm.selectedException, vm.todaysdate, onSuccessTransferExceptionEsign);
            }
        }

        function onSuccessTransferExceptionEsign() {
            var nextOptions = {
                aboveSelected: vm.selectedException == 'TE_NONE'
            };
            vm.continueNextStep({ response: nextOptions });
        }

        function isInvalidExceptionSelected() {
            return vm.selectedException == 'TE_NONE';
        }

        function fullnameChanged(form){
            form.fullName.$setValidity('validationFullnameError', form.fullName.$modelValue == vm.fullNameParam);
        }
    }
})();