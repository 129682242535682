(function() {
	'use strict';
	
	angular.module('l4b')
	.component('submitProof', {
		templateUrl: 'html/shared/submitProof/submitProof.html',
		bindings: {
			refreshParent: '&',
			showAdditionalOptions: '<'
		},
		controller: 'SubmitProofController',
		controllerAs: 'vm'
	});
})();
