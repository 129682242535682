(function () {
	'use strict';

	angular.module('l4b').config(ExceptionHandler);

	ExceptionHandler.$inject = ['$provide'];

	function ExceptionHandler($provide) {
		$provide.decorator('$exceptionHandler', ['$delegate', '$injector',
			function ($delegate, $injector) {
				return function (exception, cause) {
					var isError = exception instanceof Error;
					var isString = typeof exception == "string";

					var ex = isError ? exception : null;
					var detail = isString ? exception : cause ? 'Caused by: ' + cause : null;

					$injector.get('ClientLogService').error(ex, detail);
					$delegate(exception, cause);
				};
			}
		]);
	}
})();
