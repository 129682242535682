(function () {
	'use strict';

	angular.module('l4b')
		.factory('ClientLogService', ClientLogService);

	ClientLogService.$inject = ['$document', '$location', 'RestService', 'StorageService'];

	function ClientLogService($document, $location, RestService, StorageService) {

		var LOGGING_LIMIT = {
			ERROR: 10
		};

		var enabled = true;
		var service = {
			debug: debug,
			error: error,
			info: info,
			log: log,
			trace: trace,
			warn: warn
		};

		var loggingLimit = {};

		return service;

		function clientLog(args) {
			if (!enabled) {
				return;
			}

			var tag = getTag(args.type);
			loggingLimit[tag] = loggingLimit[tag] >= 0 ? loggingLimit[tag] + 1 : 0;

			if (loggingLimit[tag] >= LOGGING_LIMIT[args.type]) {
				return;
			}

			RestService.clientLog(args);
		}

		function getTag(type) {
			var d = new Date();

			var year = d.getFullYear();
			var month = d.getMonth() + 1;
			month = month > 9 ? month : '0' + month;
			var date = d.getDate();
			date = date > 9 ? date : '0' + date;
			var hour = d.getHours();

			return type + '-' + [year, month, date, hour].join('');
		}

		function debug(message) {
			clientLog({
				type: 'DEBUG',
				message: message
			});
		}

		function error(ex, message) {
			var stack = null;
			if (ex) {
				stack = '';
				stack += ex.stack ? ex.stack : '';
				stack += '\n\nLocation: ' + $location.absUrl();
				stack += $document[0].referrer ? '\nReferrer: ' + $document[0].referrer : '';
				stack += ex.fileName ? '\nFileName: ' + ex.fileName : '';
				stack += ex.lineNumber ? '\nLineNumber: ' + ex.lineNumber : '';
				stack += ex.columnNumber ? '\nColumnNumber: ' + ex.columnNumber : '';
			}

			var storage = null;
			try {
				storage = JSON.stringify(StorageService.getRawAll());
			} catch (e) { }

			clientLog({
				type: 'ERROR',
				exception: ex ? ex.name : null,
				message: ex ? ex.message : message,
				detail: ex ? message : null,
				stackTrace: stack,
				storage: storage
			});
		}

		function info(message) {
			clientLog({
				type: 'DEBUG',
				message: message
			});
		}

		function log(message) {
			clientLog({
				type: 'LOG',
				message: message
			});
		}

		function trace(message) {
			clientLog({
				type: 'TRACE',
				message: message
			});
		}

		function warn(message) {
			clientLog({
				type: 'WARN',
				message: message
			});
		}
	}
})();