(function () {
    'use strict';

    angular.module('l4b')
        .controller('ShippingAddressController', ShippingAddressController);

    ShippingAddressController.$inject = ['StorageService', 'Constants', 'RestService', 'FlowService', 'Utils', 'DTOFactory', 'FeaturesService'];

    function ShippingAddressController(StorageService, Constants, RestService, FlowService, Utils, DTOFactory, FeaturesService) {
        var vm = this;

        angular.extend(vm, {
            $onInit: init,
			application: DTOFactory.Application(),
            nextStep: nextStep,
            previousStep: previousStep,
            suggestionActiveShipping: true,
            showErrorMessageGeneric: false,
            patterns: {
                address: Constants.Pattern.ADDRESS_OR_POBOX
            },
            isVerizonForwardFlow: false
        });

        function init() {
            vm.isVerizonForwardFlow = vm.application.BenefitTypeCode == Constants.BenefitType.VZF;
            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_SHIPPING-ADDRESS-STARTED'
            );
			
            if(vm.application.ShippingAddress == null){
                vm.application.ShippingAddress = DTOFactory.ShippingAddress(true);
			
                var address = vm.application.Address;
                
                vm.application.ShippingAddress.City = address.City;
                vm.application.ShippingAddress.State = address.State;
                vm.application.ShippingAddress.StateName = address.StateName;
                vm.application.ShippingAddress.ZipCode = address.ZipCode;
            }

        }

        function nextStep(form) {
			
			if (!Utils.validateForm(form)) {
				return;
			}

            StorageService.set(Constants.SK.Application, vm.application);
			
            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_SHIPPING-ADDRESS-COMPLETED'
            );    

            RestService.applyEnrollment(applyEnrollmentSuccess);
        }

        function applyEnrollmentSuccess(data) {

            var error = StorageService.get(Constants.SK.Error);
            if (error) {
                if (error != Constants.Error.INVALID_SHIPPING_ADDRESS)
                {
					FlowService.nextStep();					
				}
				else {
					vm.showErrorMessageGeneric = true;
				}
				return;
            }
			
			vm.showErrorMessageGeneric = false;

            if (data && data.addressValidationStatus === Constants.Status.SUGGEST) {
                vm.suggestedAddress = {
                    line1: data.suggestedAddressLine1,
                    line2: data.suggestedAddressLine2,
                    city: data.suggestedCity
                };
                return;
            }

			vm.application.Plan = data.plan;
			vm.application.DeviceOption = data.deviceOption;
			vm.application.NladTransactionInfo = data.nladTransactionInfo;
			vm.application.NladApplication.UseTransferException = data.useTransferException;
			vm.application.NladApplication.EligibleForTransferDate = data.eligibleForTransferDate;
			vm.application.AppliedUsingNatVerAppId = data.appliedUsingNatVerAppId;
			
			StorageService.set(Constants.SK.Application, vm.application);

            //Set featuresInfo in Session.
            FeaturesService.update(data);

            if (data.token) {
                StorageService.set(Constants.SK.Token, data.token);
            }

            FlowService.nextStep();
        }

        function previousStep() {
			StorageService.remove(Constants.SK.ShippingAddress);
            FlowService.previousStep();
        }
    }
})();
