(function () {
    'use strict';

    angular.module('l4b')
        .service('GlobalizationService', GlobalizationService);

    function GlobalizationService($window, Constants, StorageService) {
        var self = this;
        var LANGUAGE_SESSION_KEY = 'GlobalizationService.Language';

        var texts = {
            CHANGE_PLAN: {
                en: 'Change My Plan',
                es: 'Cambiar Mi Plan'
            },
            DISCOUNT: {
                en: 'You can apply for a {0}.',
                es: 'Puede aplicar por un {0}.'
            },
            RTA: {
                en: 'I Need Help',
                es: 'Necesito Ayuda'
            },
            AIRTIME_TITLE: {
                en: 'Add Airtime Online.',
                es: 'Añada Minutos Por Internet'
            },
            FULL_SSN_PLACEHOLDER: {
                en: 'Full SSN is required',
                es: 'Número de Seguro Social Completo'
            },
            EDIT_INFO_SUSPENDED: {
                en: 'We are sorry',
                es: 'Lo sentimos'
            },
            WELCOME: {
                en: 'Welcome',
                es: 'Bienvenido'
            },
            ALL_STATES: {
                en: 'All States',
                es: 'Todos Los Estados'
            },
            CAN_WE_HELP_YOU: {
                en: 'What can we help you with today?',
                es: 'Cómo Podemos Ayudarlo?'
            },
            STATUS_CHANGE: {
                en: '{0} the status change to {1}',
                es: '{0} el estado cambio a {1}'
            },
            PERSON_BLACKLISTED: {
                en: 'Personal Information Banned',
                es: 'Información Personal Inhabilitada'
            },
            AIRTIME: {
                en: 'Add/Buy Minutes',
                es: 'Agregar/Comprar Minutos'
            },
            CHECKENROLLMENTSTATUS: {
                en: 'Check Enrollment Status',
                es: 'Estado de Inscripción'
            },
            SUBMIT_DOCUMENTATION: {
                en: 'Submit Documentation',
                es: 'Enviar Documentación'
            },
            PROOFS_NOT_REQUIRED: {
                en: 'There are no proofs pending submission at this time.',
                es: 'En este momento no existen pruebas pendientes de envío.'
            },
            RECERTIFY: {
                en: 'Re-Certify',
                es: 'Re-Certificar'
            },
            RECERTIFY_TITLE: {
                en: 'Verify Continued Lifeline Eligibility',
                es: 'Verificar la continuidad de la Elegibilidad Lifeline'
            },
            RECERTIFY_VERBIAGE: {
                en: {
                    Program: 'Recertify with income',
                    Income: 'Recretify with program'
                },
                es: {
                    Program: 'Recertificar por ingresos',
                    Income: 'Recertificar por programa'
                }
            },
            RECERTIFY_SUCCESS: {
                en: 'Congratulations, your enrollment is already recertified!<br>You can check the status of your enrollment below.',
                es: '¡Felicitaciones, su aplicación ya ha sido recertificada!<br>Usted puede consultar el estado de su inscripción a continuación.'
            },
            CANCEL_SERVICE: {
                en: 'Your Verizon service has been cancelled, due to not receiving assistance from any of the qualifying methods.',
                es: 'Su servicio Verizon ha sido cancelado, debido a que no recibe asistencia de ninguno de los métodos de calificación válidos.'
            },
            RECERTIFY_UPLOAD_SUCCESS: {
                en: 'Files uploaded successfully',
                es: 'Archivos subidos exitosamente'
            },
            RECERTIFY_OUT_OF_WINDOW: {
                en: 'We are sorry<br>Our records indicate that you are not yet required to complete your Annual Verification at this time because you either already completed your Annual Verification and it has been accepted, or you have been enrolled in the benefit for less than 8 months. You will be notified 4 months before your benefit\'s expiration date, to complete your annual verification. Once you\'ve been notified, please come back to complete your Annual Verification.',
                es: 'Lo sentimos<br>En este momento no es necesario completar su Verificación Anual ya sea porque usted ya completó la Verificación Anual y ésta fue aceptada, o usted ha estado inscrito en el beneficio por menos de 8 meses. Usted recibirá una notificación 4 meses antes de la fecha de vencimiento del beneficio para que pueda completar la Verificación Anual. En cuanto reciba la notificación por favor vuelva a ingresar para completar su Verificación Anual.'
            },
            CANNOT_UPDATE_INFO: {
                en: 'Your Enrollment is in Qualification process, you cannot edit information at this time. Please try again in 48 hours.',
                es: 'Su Enrolamiento está en proceso de calificación, usted no podrá editar su información. Por favor, vuelva a intentarlo en 48 horas.'
            },
            UPDATE_INFO: {
                en: 'Update My Info',
                es: 'Editar Información'
            },
            SERVICE_CANCELLED: {
                en: 'Your Verizon Mobile® Service has been successfully cancelled, but remember you may continue using your phone with Verizon Mobile® Plans, which you can purchase at <a href="https://www.verizon.com/" target="_blank">www.attmobile.com</a>.',
                es: 'Su servicio de Verizon Mobile® ha sido cancelado exitosamente, pero recuerde que puede continuar usando su teléfono con los Planes de Verizon Mobile®, que puede comprar <a href="https://www.verizon.com/" target="_blank">www.verizons.com</a>.'
            },
            REPRINT: {
                en: 'Reprint My Application',
                es: 'Imprimir Aplicación'
            },
            PERSONAL_INFO: {
                en: 'Personal Info',
                es: 'Personal'
            },
            ADDRESS: {
                en: 'Address',
                es: 'Dirección'
            },
            ELIGIBILITY: {
                en: 'Eligibility',
                es: 'Elegibilidad'
            },
            REVIEW: {
                en: 'Review',
                es: 'Revisar'
            },
            SUBMIT_PROOF: {
                en: 'Submit Proof ',
                es: 'Subir Prueba'
            },
            ACCESS_DENIED: {
                en: 'Access Denied',
                es: 'Acceso Denegado'
            },
            DAY: {
                en: 'Day',
                es: 'Día'
            },
            MONTH: {
                en: 'Month',
                es: 'Mes'
            },
            YEAR: {
                en: 'Year',
                es: 'Año'
            },
            SSN_LABEL: {
                en: 'Last 4 digits of SSN',
                es: 'Últimos 4 digitos del SSN'
            },
            SSN_LABEL_SHORT: {
                en: 'Last 4 of SSN',
                es: 'Últimos 4 del SSN'
            },
            SSN_ERROR_SHORT: {
                en: 'Last 4 digits of SSN are required',
                es: 'Los últimos 4 dígitos del Número de Seguridad Social (SSN) son requeridos'
            },
            SSN_FULL_ERROR: {
                en: 'The 9 digits of SSN are required',
                es: 'Los 9 dígitos del Número de Seguridad Social (SSN) son requeridos'
            },
            SSN: {
                en: 'Social Security Number',
                es: 'Número de Seguridad Social'
            },
            MINUTES_PLAN: {
                en: ' minutes plan',
                es: ' minutos'
            },
            UNLIMITED_PLAN: {
                en: 'unlimited minutes broadband plan',
                es: 'minutos broadband ilimitado'
            },
            SERVICE_CANCELATION: {
                en: 'Service Cancelation',
                es: 'Cancelación del Servicio'
            },
            RTA_DISCONECT: {
                en: 'Sorry, but there is a problem with connection. Please try again later.',
                es: 'Disculpe, hay un problema de conexión. Intente más tarde.'
            },
            JANUARY: {
                en: 'January',
                es: 'Enero'
            },
            FEBRUARY: {
                en: 'February',
                es: 'Febrero'
            },
            MARCH: {
                en: 'March',
                es: 'Marzo'
            },
            APRIL: {
                en: 'April',
                es: 'Abril'
            },
            MAY: {
                en: 'May',
                es: 'Mayo'
            },
            JUNE: {
                en: 'June',
                es: 'Junio'
            },
            JULY: {
                en: 'July',
                es: 'Julio'
            },
            AUGUST: {
                en: 'August',
                es: 'Agosto'
            },
            SEPTEMBER: {
                en: 'September',
                es: 'Septiembre'
            },
            OCTOBER: {
                en: 'October',
                es: 'Octubre'
            },
            NOVEMBER: {
                en: 'November',
                es: 'Noviembre'
            },
            DECEMBER: {
                en: 'December',
                es: 'Diciembre'
            },
            RTA_SUCCESS: {
                en: 'Talk with a Live Agent',
                es: 'Hable ahora con un agente'
            },
            RTA_WELCOME_MESSAGE: {
                en: 'Please type your question below and an agent will be with you shortly',
                es: 'Por favor ingrese su pregunta debajo y un agente estará con usted en breve'
            },
            FIELD_REQUIRED: {
                en: 'This field is required',
                es: 'Este Campo Es Obligatorio'
            },
            INFORMATION_UPDATED: {
                en: 'Your information was successfully updated.',
                es: 'Su información fue actualizada exitosamente.'
            },
            ERROR_TITLE: {
                en: 'We are sorry',
                es: 'Lo sentimos'
            },
            INCOME_OPTION: {
                en: 'No, I do not receive any of these programs and I want to apply via INCOME',
                es: 'No recibo ninguno de estos programas, quiero aplicar mediante INGRESOS'
            },
            NOPROGRAM_OPTION: {
                en: 'I do not receive any of the programs on the list',
                es: 'No recibo asistencia de ningún programa de la lista'
            },
            ER_ERROR: {
                en: 'The combination of enrollment ID and SSN entered is invalid, please try again. You can use the link Forgot your Enrollment Number to login with your personal info.',
                es: 'La combinación de Número de Inscripción y SSN ingresada no es válida, por favor inténtelo de nuevo. Puede utilizar el enlace ¿Olvidó su Número de Inscripción? para acceder a la cuenta usando su información personal.'
            },
            FIELD_INVALID: {
                en: 'This Field Is Invalid',
                es: 'Este Campo Es Inválido'
            },
            PHONE_TYPE_SELECTION: {
                en: 'Device Info',
                es: 'Dispositivo'
            },
            ERROR_DD: {
                en: 'The file is duplicated',
                es: 'El archivo esta duplicado'
            },
            ERROR_WTYPE: {
                en: 'The file type is invalid',
                es: 'El tipo de archivo es inválido'
            },
            ERROR_MAXFILES: {
                en: 'The maximum number of files to upload has been overcome',
                es: 'El número máximo de archivos para subir se ha superado'
            },
            ERROR_MAXSIZE: {
                en: 'The maximum file size has been exceeded',
                es: 'Ha superado el límite de tamaño permitido'
            },
            MY_SERVICE: {
                en: {
                    Att: 'My Enrollment Status'
                },
                es: {
                    Att: 'Mi Estado de Aplicación'
                }
            },
            UPLOAD_RESPONSE_SUCCESS_MESSAGE: {
                en: 'Files uploaded successfully',
                es: 'Archivos subidos exitosamente'
            },
			
			UPLOAD_RESPONSE_ERROR_MESSAGE: {
                en: 'An error has ocurred',
                es: 'Ha ocurrido un error inesperado'
			},
			
			IMAGE_SIZE_EXCEEDED: {
                en: 'Image size exceeded',
                es: 'El tamaño del archivo es demasiado grande'
			},
			
			INVALID_FILE_PARAMETERS: {
                en: 'Empty file content',
                es: 'El contenido del archivo está vacío'
			},
			
            COMPATIBLE_PHONE: {
                en: 'Compatible Phone',
                es: 'Teléfono compatible con'
            },
            STEPS_DESCRIPTIONS: {
                en: [{
                    stepId: Constants.Step.PersonalInformation,
                    name: 'Personal Info'
                }, {
                    stepId: Constants.Step.Address,
                    name: 'Address'
                }, {
                    stepId: Constants.Step.Eligibility,
                    name: 'Qualify'
                }, {
                    stepId: Constants.Step.TransferException,
                    name: 'Transfer Form'
                }, {
                    stepId: Constants.Step.Review,
                    name: 'Review'
                }, {
                    stepId: Constants.Step.ApplicationCompleted,
                    name: 'Application Completed'
                },
                {
                    stepId: Constants.Step.BenefitSelection,
                    name: 'Benefit Selection'
                },
                {
                    stepId: Constants.Step.Landing,
                    name: 'Home'
                }],

                es: [{
                    stepId: Constants.Step.PersonalInformation,
                    name: 'Personal'
                }, {
                    stepId: Constants.Step.Address,
                    name: 'Dirección'
                }, {
                    stepId: Constants.Step.Eligibility,
                    name: 'Calificación'
                }, {
                    stepId: Constants.Step.TransferException,
                    name: 'Formulario de Transferencia'
                }, {
                    stepId: Constants.Step.Review,
                    name: 'Revisar'
                }, {
                    stepId: Constants.Step.ApplicationCompleted,
                    name: 'Aplicación Completa'
                },
                {
                    stepId: Constants.Step.BenefitSelection,
                    name: 'Selección de Beneficio'
                },
                {
                    stepId: Constants.Step.Landing,
                    name: 'Inicio'
                }]
            },

            Home: {
                en: 'Home',
                es: 'Inicio'
            },
            personalInformation: {
                en: 'Personal Information',
                es: 'Informacion Personal'
            },
            programEligibility: {
                en: 'Program Elegibility',
                es: 'Elegibilidad'
            },
            incomeEligibility: {
                en: 'Income Elegibility',
                es: 'Elegibilidad por Ingreso'
            },
            review: {
                en: 'Review',
                es: 'Revisar'
            },
            applicationCompleted: {
                en: 'Aplication Completed',
                es: 'Aplicación Completa'
            },
            UpgradePlan: {
                en: 'Upgrade Plan',
                es: 'Actualice Su Plan'
            },
            UpgradePhone: {
                en: 'Upgrade Phone',
                es: 'Actualice Su Telefono'
            },
            TechnicalSupport: {
                en: 'TechnicalSupport',
                es: 'Soporte Técnico'
            },
            AboutUs: {
                en: 'About Us',
                es: 'Sobre Nosotros'
            },
            WelcomeCenter: {
                en: 'Welcome Center',
                es: 'Bienvenido'
            },
            PlanFeatures: {
                en: 'Plan Features',
                es: 'Características del Plan'
            },

            UNLIMITED_TEXT: {
                en: 'Unlimited',
                es: 'Ilimitados'
            },

            UNLIMITED_VOICE: {
                en: 'Unlimited Voice',
                es: 'Minutos de Voz Ilimitados'
            },

            AIR_MESSAGE_TEXT_MY_DEVICE: {
                en: "When you click Continue you will be redirected to find your device. This will open in a new tab. Locate and click on your device to open the instructions manual.",
                es: "Cuando oprima Continuar será redireccionado para buscar su teléfono. Esto abrirá una nueva página. Localice su teléfono y haga clic en él para abrir el manual de instrucciones."
            },

            AIR_MESSAGE_BUTTON_TEXT_CONTINUE: {
                en: "Continue",
                es: "Continuar"
            },

            AIR_MESSAGE_TEXT_DEFAULT: {
                en: "When you hit Continue, your destination page will open in a new tab.",
                es: "Cuando oprima Continuar, su página de destino se abrirá en una nueva pestaña."
            },

            AIR_MESSAGE_TEXT_ACCESIBILITY: {
                en: "You will be redirected to a third-party website. Do you want to continue?",
                es: "Será redirigido a una página web de un tercero. ¿Desea continuar?"
            },

			AIR_MESSAGE_LINK_NOT_AUTHENTICATED: {
                en: 'User not authenticated. You will be redirected to Verizon site.',
                es: 'Usuario no autenticado. Será redirigido al sitio de Verizon.'
			},

            AIR_MESSAGE_BUTTON_TEXT_YES: {
                en: "Yes",
                es: "Si"
            },

            AIR_MESSAGE_TEXT_SHOP_GLOBAL: {
                en: "When you click continue below you will be redirected to one of our other brands website. Follow the instructions on that page to purchase your airtime cards.",
                es: "Si usted hace clic en Continuar será direccionado al sitio web de una de nuestras marcas. Siga las instrucciones en la página para comprar tarjeta de minutos."
            },

            AIR_MESSAGE_TEXT_UPGRADE_PHONE_BYOP: {
                en: "You will be redirected to our partner brand’s website, where you can buy your SIM KIT and transfer your Verizon benefits.",
                es: "Será redirigido al sitio web de nuestra marca asociada, donde podrá comprar su SIM KIT y transferir sus beneficios de Verizon."
            },

            ORDER_STATUS: {
                en: {
                    "Shipped": "Shipped",
                    "Cancelled": "Cancelled",
                    "Pending Approval": "In Progress",
                    "Order Processing": "In Progress"
                },
                es: {
                    "Shipped": "Enviada",
                    "Cancelled": "Cancelada",
                    "Pending Approval": "En Progreso",
                    "Order Processing": "En Progreso"
                }
            },

            AIR_MESSAGE_SESSION_EXPIRED: {
                en: "For security reasons, your session was closed after a prolonged period of inactivity. To continue, please, reenter your login information.",
                es: "Por razones de seguridad, su sesión fue cerrada luego de un tiempo prolongado de inactividad. Para continuar, por favor, reingrese su información de acceso."
            },

            AIR_MESSAGE_ZIPCODE_CHANGED: {
                en: "The navigation zip code has been changed to match the zip code found on your application. You can close this message.",
                es: "El código postal de navegación ha sido modificado para coincidir con el código postal ingresado en su aplicación. Puede cerrar este mensaje."
            },

            AIR_MESSAGE_LINK_NOT_REFERRED: {
                en: "In order to apply for the Affordable Connectivity Program (ACP), you will be redirected to find a store near you....",
                es: "Para aplicar para el Programa de Descuentos para Internet (ACP), usted será redirigido para encontrar una tienda cercana..."
            },

            AIR_MESSAGE_BUTTON_TEXT_DISMISS: {
                en: "Dismiss",
                es: "Descartar"
            },

            AIR_MESSAGE_COUNTDOWN_TEXT : {
                en: "You will be redirected to complete your order in ",
                es: "Serás redirigido para completar tu orden en "
            },

            AIR_MESSAGE_COUNTDOWN_SECONDS : {
                en: "seconds",
                es: "segundos"
            },

            SOURCE: {
                en: "Source",
                es: "Origen"
            },

            BILLING_ADDRESS_UPDATE_REQUIRED_TITLE: {
                en: "Undeliverable Address",
                es: "Dirección no válida para envío"
            },
            BILLING_ADDRESS_UPDATE_REQUIRED_MESSAGE: {
                en: "The California Administrator attempted to mail the appropriate letters/forms but was notified by the USPS the address provided was undeliverable. Please update the address to continue the process. The California Administrator could take several days to process the update.",
                es: "El Administrador de California intentó enviar una carta/aplicación, pero fue notificado por la USPS que la dirección provista no es válida para envíos. Por favor actualice su dirección para continuar con el proceso. El Administrador de California podría requerir varios días para procesar la actualización."
            },

            ADDRESS_REVERTED_BY_CALAD_TITLE: {
                en: "Address Update Rejected",
                es: "Actualización de dirección rechazada"
            },
            ADDRESS_REVERTED_BY_CALAD_MESSAGE: {
                en: "The California Administrator has rejected the last address update. Reverted to the last valid address.",
                es: "El Administrador de California ha rechazado la última actualización de dirección. La dirección ha sido revertida a la última dirección válida."
            },

            STATUS: {
                en: {
                    QUALIFIED: "Qualified, you must now order your device (See Awaiting Device Order)"
                },
                es: {
                    QUALIFIED: "Calificado, ahora debe realizar el pedido del teléfono (Vea Pedido de teléfono pendiente)"
                }
            },

            MORETHAN:{
                en: 'More than ',
                es: 'Más de '
            },
            NOT_ELIGIBLE: {
                en: "Not Eligible",
                es: "No Elegible"
            }
        };

        self.register = function () {
            StorageService.set(LANGUAGE_SESSION_KEY, getLang());
        };

        self.getLang = function () {
            var lang = StorageService.get(LANGUAGE_SESSION_KEY);
            if (!lang) {
                self.register();

                return self.getLang();
            }

            return lang;
        };

        self.get = function (key) {
            return texts[key][self.getLang()];
        };

        function getLang() {

            if (isLang(Constants.Url.Spanish)) {
                return Constants.Language.Spanish;
            }

            if (isLang(Constants.Url.English)) {
                return Constants.Language.English;
            }

            var lang = StorageService.get(LANGUAGE_SESSION_KEY);
            var languages = [Constants.Language.English, Constants.Language.Spanish];
            if (languages.indexOf(lang) !== -1) {
                return lang;
            }

            return Constants.Language.English;
        }

        function isLang(lang) {
            return $window.location.pathname.toLowerCase().indexOf(lang) !== -1;
        }
    }
})();
