(function () {
    'use strict';

    angular.module('l4b')
        .directive('addressSuggestions', AddressSuggestions);

    AddressSuggestions.$inject = ['$timeout', 'RestService', 'Constants', '$compile'];

    function AddressSuggestions($timeout, RestService, Constants, $compile) {
        return {
            restrict: 'A',
            scope: {
                address: '=ngModel',
                zipCode: '<zipcode',
                readonly: '&ngReadonly',
                suggestionActive: '<'
            },
            require: 'ngModel',
            link: function (scope, element) {
                
				if (scope.readonly && scope.readonly()) return;
				
				var DELAY = 1000;

                // create HTML element to hold the suggested address
                var el = angular.element(
                    '<div class="list address-suggestions" ng-show="addresses.length > 0">' +
                    '<li ng-repeat="address in addresses" class="item" ng-mousedown="setAddress(address)">' +
                    '{{address}}' +
                    '</li>' +
                    '</div>');

                // compile the above template and append to the element parent (the list will be sibling to the element this directive is applied to)
                $compile(el)(scope);
                element.parent().append(el);

                scope.addresses = [];
                scope.setAddress = setAddress;

                scope.$watch('address', onAddressChange);

                var suggestTO = 0;
                var lastQuery = null;

                function onAddressChange() {
                    if(scope.suggestionActive && scope.address) {
                        $timeout.cancel(suggestTO);
    
                        var address = scope.address || '';
                        var zipCode = scope.zipCode || '';
    
                        if (!address.match(Constants.Pattern.LINE1) || !zipCode.match(Constants.Pattern.ZIPCODE)) {
                            clearAddresses();
                            lastQuery = null;
                            return;
                        }
    
                        suggestTO = $timeout(function () {
                            if (address.equals(lastQuery, true)) {
                                return;
                            }
    
                            lastQuery = address;
    
                            RestService.getAddressSuggestions(zipCode, address, onGetAddressSuggestionsSuccess);
                        }, DELAY);
                    } else
                        scope.suggestionActive = !scope.suggestionActive && scope.address ? !scope.suggestionActive : scope.suggestionActive;
                }

                function onGetAddressSuggestionsSuccess(data) {
                    if (data.length === 0) {
                        clearAddresses();
                        return;
                    }

                    scope.addresses = data;
                }

                function setAddress(address) {
                    scope.address = address;
                    clearAddresses();

                    lastQuery = address;
                }

                function clearAddresses() {
                    scope.addresses = [];
                }

                element.on('blur', function () {
                    $timeout(clearAddresses, 100);
                });
            }
        };
    }
})();
