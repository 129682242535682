(function () {
    'use strict';

    angular.module('l4b')
    .controller('TransferExceptionsController', TransferExceptionsController);        

    function TransferExceptionsController(DTOFactory, Constants, FlowService) {
        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            fullName: '',
            nextStep: nextStep,
            eligibleForTransferDate: null
        });

        function init() {      
			var applicant = DTOFactory.Applicant();
			var application = DTOFactory.Application();
		
            vm.fullName = applicant.FirstName + ' ' + applicant.LastName;
            vm.eligibleForTransferDate = application.NladApplication.EligibleForTransferDate;
        }

        function nextStep(response) {
            FlowService.nextStep(response);
        }
    }
})();