(function () {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentationSubmit', {
            templateUrl: 'html/shared/requiredDocumentationSubmit/requiredDocumentationSubmit.html',
            bindings: {
				nextStep: '&'
            },
            controller: RequiredDocumentationSubmitController,
            controllerAs: 'vm'
        });

    RequiredDocumentationSubmitController.$inject = ['GuidedProofService', 'Constants', 'StorageService'];

    function RequiredDocumentationSubmitController(GuidedProofService, Constants, StorageService) {
        var vm = this;

        angular.extend(vm, {
			$onInit: init,
            actualNumber: 1,
            totalNumber: 0,
            showError: false,
            showInvalidFileError: false,
            retries: 2,
            proofsFailed: [],
            allowedFileFormats: ".png, .jpeg, .jpg, .pdf",
            continueAnyways: continueAnyways,
            resubmit: resubmit
        });

		function init() {
			
            if (!GuidedProofService.ValidateProofData()) {
				return;
			}
         
            vm.totalNumber = GuidedProofService.GetProofsToSubmitCount();

            var config = StorageService.get(Constants.SK.Configuration);

            vm.allowedFileFormats = config && config.allowedFileFormats 
                                    ?  config.allowedFileFormats
                                    :  vm.allowedFileFormats;

            GuidedProofService.SubmitAllProofs()
                .then(onSuccess, onError, onNotify);				
		}		

        function onSuccess(response) {
            if (vm.proofsFailed.length == 0) {
                
				var hasNextProof = GuidedProofService.GetMissingProofs().length > 0;
				
				vm.nextStep({ options: { nextProof: hasNextProof }});
            }
            else {
                
                if(response.data.ExceptionMessage == Constants.Error.INVALID_FORMAT_ERROR)
                {
                    vm.showInvalidFileError = true;
                    return;
                }
                
                vm.showError = true;
            }
        }

        function continueAnyways() {
            vm.nextStep({ options: { error: true } });
        }

        function resubmit() {
            vm.retries--;
            vm.showError = false;
            vm.totalNumber = GuidedProofService.GetProofsToSubmitCount();

            GuidedProofService.SubmitAllProofs()
                .then(onSuccess, onError, onNotify);
        }

        function onError() {
            vm.showError = true;
        }

        function onNotify(update) {
            if (!update) {
                return;
            }

            if (update.proofToSubmit) {
                vm.proofSubmitting = update.proofToSubmit;
            }

            if (update.actualCount) {
                vm.actualNumber = update.actualCount;
            }

            if (update.maxFiles) {
                vm.totalNumber = update.maxFiles;
            }

            if (update.proofFailed) {
                vm.proofsFailed.push(update.proofFailed);
            }
        }
    }

})();