(function () {

	'use strict';

	angular.module('l4b')
		.constant('Constants', {
			AuthProviders: {
				APPLY: 'ApplyAuthProvider',
				MY_ACCOUNT: 'MyAccountAuthProvider',
				DEFAULT: 'DefaultAuthProvider',
				ZIP_CODE: 'ZipCodeAuthProvider'
			},
			
			Event: {
				Flow: {
					CUSTOMER_MY_ACCOUNT_LOGGED_IN: 'CUSTOMER_MY-ACCOUNT_LOGGED-IN'
				},
				
				NatVerApi: {
					NATVER_REDIRECT_APP_NOT_FOUND_OR_NOT_COMPLETE: 'NATVER_REDIRECT_APP_NOT_FOUND_OR_NOT_COMPLETE',
					NATVER_REDIRECT_APP_PENDING_RESOLUTION: 'NATVER_REDIRECT_APP_PENDING_RESOLUTION',
					NATVER_REDIRECT_APP_PENDING_CERTIFICATION: 'NATVER_REDIRECT_APP_PENDING_CERTIFICATION',
					NATVER_REDIRECT_APP_PENDING_PROOF: 'NATVER_REDIRECT_APP_PENDING_PROOF',
					SYSTEM_APPLICATION_THANK_YOU_PAGE_NATVER_REDIRECT_STARTED: 'SYSTEM_APPLICATION_THANK_YOU_PAGE_NATVER_REDIRECT_STARTED',
					NATVER_RETURN_APP_QUALIFIED: 'NATVER_RETURN_APP_QUALIFIED',
					NATVER_RETURN_APP_PENDING_REVIEW: 'NATVER_RETURN_APP_PENDING_REVIEW',
					NATVER_RETURN_APP_PENDING_RESOLUTION: 'NATVER_RETURN_APP_PENDING_RESOLUTION',
					NATVER_REDIRECT_API_ERROR: 'NATVER_REDIRECT_API_ERROR',
					NATVER_RETURN_LANDING_PAGE_PRESENTED: 'NATVER_RETURN_LANDING_PAGE_PRESENTED'
				},
				
				Tablet: {
					CUSTOMER_REDIRECT_TABLET_STORE_LOCATOR_CLICKED: 'CUSTOMER-REDIRECT-TABLET_STORE-LOCATOR-CLICKED'
				},
				
				Telesales: {
					SYSTEM_CONTACTLESS_ESIGN_WELCOME_PAGE_MY_EBB_REDIRECT_COMPLETED: 'SYSTEM_CONTACTLESS-ESIGN-WELCOME-PAGE_MY-EBB-REDIRECT_COMPLETED',
					SYSTEM_CONTACTLESS_ESIGN_MY_ACCOUNT_PAGE_PRESENTATION_COMPLETED: 'SYSTEM_CONTACTLESS-ESIGN-MY-ACCOUNT-PAGE-PRESENTATION_COMPLETED',
					SYSTEM_MY_EBB_ACCOUNT_PRESENTATION_COMPLETED: 'SYSTEM_MY-EBB-ACCOUNT-PRESENTATION_COMPLETED',
					
					SYSTEM_REQUIERED_DOCUMENTATION_PRESENTATION_STARTED: 'SYSTEM_REQUIERED-DOCUMENTATION-PRESENTATION_STARTED',
					SYSTEM_REQUIERED_DOCUMENTATION_PRESENTATION_COMPLETED: 'SYSTEM_REQUIERED-DOCUMENTATION-PRESENTATION_COMPLETED',
					
					SYSTEM_IDENTITY_PROOF_PRESENTATION_COMPLETED: 'SYSTEM_IDENTITY-PROOF-PRESENTATION_COMPLETED',
					SYSTEM_IDENTITY_PROOF_NOT_PROVIDED: 'SYSTEM_IDENTITY-PROOF_NOT-PROVIDED',
					SYSTEM_IDENTITY_PROOF_PROVIDED: 'SYSTEM_IDENTITY-PROOF-PROVIDED',
					
					SYSTEM_REQUIERED_DOCUMENTATION_STATUS_PRESENTATION_STARTED: 'SYSTEM_REQUIERED-DOCUMENTATION-STATUS_PRESENTATION_STARTED',
					SYSTEM_REQUIERED_DOCUMENTATION_STATUS_PROOF_SUBMITTED: 'SYSTEM_REQUIERED-DOCUMENTATION-STATUS_PROOF-SUBMITTED',
					SYSTEM_IDENTITY_PROOF_SUCCESFULLY_SUBMITTED_PRESENTATION_STARTED: 'SYSTEM_IDENTITY-PROOF-SUCCESFULLY-SUBMITTED-PRESENTATION_STARTED',
					SYSTEM_IDENTITY_PROOF_SUCCESFULLY_SUBMITTED_PRESENTATION_COMPLETED: 'SYSTEM_IDENTITY-PROOF-SUCCESFULLY-SUBMITTED-PRESENTATION_COMPLETED',
					SYSTEM_CONTACTLESS_EMAIL_ESIGN_ACCESS_WELCOME_PAGE_REDIRECT_STARTED: 'SYSTEM_CONTACTLESS-EMAIL-ESIGN-ACCESS_WELCOME-PAGE-REDIRECT_STARTED',
					SYSTEM_CONTACTLESS_EMAIL_ESIGN_ACCESS_WELCOME_PAGE_REDIRECT_COMPLETED: 'SYSTEM_CONTACTLESS-EMAIL-ESIGN-ACCESS_WELCOME-PAGE-REDIRECT_COMPLETED',
					SYSTEM_CONTACTLESS_SMS_ESIGN_ACCESS_WELCOME_PAGE_REDIRECT_STARTED: 'SYSTEM_CONTACTLESS-SMS-ESIGN-ACCESS_WELCOME-PAGE-REDIRECT_STARTED',
					SYSTEM_CONTACTLESS_SMS_ESIGN_ACCESS_WELCOME_PAGE_REDIRECT_COMPLETED: 'SYSTEM_CONTACTLESS-SMS-ESIGN-ACCESS_WELCOME-PAGE-REDIRECT_COMPLETED'
				},

				SYSTEM_APPLICATION_DEVICE_STORE_REDIRECT:'SYSTEM_APPLICATION-DEVICE-STORE-REDIRECT'
			},

			Page: {
				PersonalInformation: 'mainFlow.personalInformation',
				Address: 'mainFlow.address',
				ChildInformation: 'mainFlow.childInformation',
				IncomeEligibility: 'mainFlow.incomeEligibility',
				ApplicantEligibility: 'mainFlow.applicantEligibility',
				Disclaimers: 'mainFlow.disclaimers',
				InvalidAddress: 'mainFlow.invalidAddress',
				ShippingAddress: 'mainFlow.shippingAddress',
				ProgramEligibility: 'mainFlow.programEligibility',
				Exit: 'main.exit',
				BenefitSelection:'mainFlow.benefitSelection',
				Landing:'mainFlow.landing',
				Review: 'mainFlow.review',
				Signature: 'mainFlow.signature',
				ApplicationCompleted: 'mainFlow.applicationCompleted',
				Error: 'main.error',
				MyAccount: 'myAccount.Tabs',
				MyAccountDetails: 'myAccount.Tabs.Details',
				RequiredDocumentation: 'mainFlow.requiredDocumentation',
				MyAccountSubmitDocumentation: 'myAccount.Tabs.SubmitDocumentation',
				MyAccountMessage: 'myAccount.Tabs.MyAccountMessage',
				MyAccountUpdate: 'myAccount.Tabs.MyAccountUpdate',
				MyAccountInvalidAddress: 'myAccount.Tabs.invalidAddress',
				MyAccountShippingAddress: 'myAccount.Tabs.shippingAddress',
				MyAccountExit: 'myAccount.Tabs.exit',
				ServiceCancellation: 'myAccount.ServiceCancellation',
				ServiceCancelConfirm: 'myAccount.ServiceCancelConfirm',
				ServiceCancellationMessage: 'myAccount.MyAccountMessage',
				Login: 'main.login',
				About: 'mainFlow.about',
				TechnicalSupport: 'main.TechnicalSupport',
				Zipcode: 'main.Zipcode',
				AboutUs: 'main.AboutUs',
				NoCoverage: 'noCoverage',
				AccessibilityPage: 'main.AccessibilityPage',
				HowToQualify: 'zipCode.howToQualify',
				ContactUs: 'main.ContactUs',
				NatVerLanding: 'main.NatVerLanding',
				TransferException: 'mainFlow.transferException'
			},

			Cookie: {
				Session: 'SessionCookie',
				ZipCodeToRedirect: 'ZipCodeToRedirect',
				Promotion: 'PromotionCookie',
				Agent: 'AgentCookie'
			},

			KeyCode: {
				Enter: '13'
			},

			Google:{
				Recaptcha: {
                    SiteKey: '6Ldei-ElAAAAAK2w27MfWaUR23nPhoy7fcahHvz1'
                }
			},

			Language: {
				English: 'en',
				Spanish: 'es'
			},

			State: {
				Alabama: 'AL',
				Alaska: 'AK',
				AmericanSamoa: 'AS',
				Arizona: 'AZ',
				Arkansas: 'AR',
				California: 'CA',
				Colorado: 'CO',
				Connecticut: 'CT',
				DC: 'DC',
				Delaware: 'DE',
				FederatedStatesofMicronesia: 'FM',
				Florida: 'FL',
				Georgia: 'GA',
				Guam: 'GU',
				Hawaii: 'HI',
				Idaho: 'ID',
				Illinois: 'IL',
				Indiana: 'IN',
				Iowa: 'IA',
				Kansas: 'KS',
				Kentucky: 'KY',
				Louisiana: 'LA',
				Maine: 'ME',
				MarshallIslands: 'MH',
				Maryland: 'MD',
				Massachusetts: 'MA',
				Michigan: 'MI',
				Minnesota: 'MN',
				Mississippi: 'MS',
				Missouri: 'MO',
				Montana: 'MT',
				Nebraska: 'NE',
				Nevada: 'NV',
				NewHampshire: 'NH',
				NewJersey: 'NJ',
				NewMexico: 'NM',
				NewYork: 'NY',
				NorthCarolina: 'NC',
				NorthDakota: 'ND',
				Ohio: 'OH',
				Oklahoma: 'OK',
				Oregon: 'OR',
				Palau: 'PW',
				Pennsylvania: 'PA',
				PuertoRico: 'PR',
				RhodeIsland: 'RI',
				SouthCarolina: 'SC',
				SouthDakota: 'SD',
				Tennessee: 'TN',
				Texas: 'TX',
				Utah: 'UT',
				Vermont: 'VT',
				VirginIslands: 'VI',
				Virginia: 'VA',
				Washington: 'WA',
				WestVirginia: 'WV',
				Wisconsin: 'WI',
				Wyoming: 'WY',
			},

			Channel: {
				Web: 'Web'
			},

			QualificationType: {
				Program: 'PROGRAM',
				Income: 'INCOME'
			},

			HttpErrors: {
				Unauthorized: '401'
			},

			Error: {
				ACCESS_DENIED_PLAN: 'AccessDeniedPlan',
				ACCESS_DENIED_INFO: 'AccessDeniedInfo',
				ACCESS_DENIED_SUBMIT: 'AccessDeniedSubmit',
				ACCESS_DENIED_EDIT_INFO_SUSPENDED: 'AccessDeniedInfoSuspended',
				ADDRESS_BLACKLISTED: 'ADDRESS_BLACKLISTED',
				ADDRESS_GENERIC: 'AddressGeneric',
				ADDRESS_NOEESCALATION: 'AddressNoEscalation',
				ADDRESS_NOT_ALLOWED: 'NotAllowedAddressType',
				ADDRESS_RETRY: 'AddressRetry',
				ALREADY_HAS_ACP: 'ALREADY_HAS_ACP',
				BQP_OVERAGE_SCHOOL_LUNCH: 'BQP_OVERAGE_SCHOOL_LUNCH',
				CAPTCHA_INVALID: 'CaptchaInvalid',
				CANNOT_CHANGE_STATE: 'CANNOT_CHANGE_STATE',
				DEACTIVATED_AGENT: 'DeactivatedAgent',
				DIS_ENROLLED_FAIL: 'DisEnrolledFail',
				DISCLAIMERS_FAIL: 'DisclaimersFail',
				DUPLICATED_DEPENDENT: 'DUPLICATED_DEPENDENT',
				DUPLICATED_PERSON: 'DUPLICATED_PERSON',
				DUPLICATED_PERSON_EXTERNAL: 'DUPLICATED_PERSON_EXTERNAL',
				DUPLICATED_DEPENDENT_EXTERNAL: 'DUPLICATED_DEPENDENT_EXTERNAL',
				DUPLICATED_DEPENDENT_PENDING: 'DUPLICATED_DEPENDENT_PENDING',
				DUPLICATED_PERSON_PENDING: 'DUPLICATED_PERSON_PENDING',
				DUPLICATED_EMAIL: 'DUPLICATED_EMAIL',
				DUPLICATED_DEPENDENT_QUALIFIED: 'DUPLICATED_DEPENDENT_QUALIFIED',
				DUPLICATED_PERSON_QUALIFIED: 'DUPLICATED_PERSON_QUALIFIED',
				GENERIC: 'Generic',
				FIELDS_GENERIC: 'FieldsGeneric',
				HOME_TELEPHONE: 'HomeTelephone',
				HOUSEHOLD_WORKSHEET_DRC: 'HouseholdWorksheetDRC',
				ID_NOT_FOUND: 'IDNotFound',
				EMAIL_DOMAIN_NOT_ALLOWED: 'EMAIL_DOMAIN_NOT_ALLOWED',
				EMAIL_BELONG_AGENT: 'EMAIL_BELONG_AGENT',
				INVALID_APPLICATION_ID: 'INVALID_APPLICATION_ID',
				INVALID_ZIPCODE: 'INVALID_ZIPCODE',
				INVALID_PROCESS_NAME: 'Invalid',
				INVALID_AGENT: 'InvalidAgent',
				INVALID_AGENT_CODE: 'InvalidAgentCode',
				INVALID_ADDRESS: 'INVALID_ADDRESS',
				INVALID_SHIPPING_ADDRESS: 'INVALID_SHIPPING_ADDRESS',
				INVALID_OR_EXPIRED_ACCESS_TOKEN: 'INVALID_OR_EXPIRED_ACCESS_TOKEN',
				INVALID_PROFILE: 'INVALID_PROFILE',
				INVALID_RATE_PLAN: 'INVALID_RATE_PLAN',
				INVALID_WIRELESS_NUMBER: 'INVALID_WIRELESS_NUMBER',
				MULTIPLE_ID_FOUND: 'MultipleIDFound',
				MAIN_AND_SHIPPING_ARE_EQUAL: 'MAIN_AND_SHIPPING_ARE_EQUAL',
				NATIONAL_VERIFIER_UPDATE_NOT_SUCCESS: 'NATIONAL_VERIFIER.UPDATE_NOT_SUCCESS',
				NLAD_DRC: 'NLADValidation.DRC',
				NLAD_DUPLICATE_ADDRESS: 'NLADDuplicatedAddreess',
				NLAD_ERROR: 'NLADValidation.Error',
				NLAD_TPIV: 'NLADValidation.TPIV',
				NLAD_MOVING: 'NLADValidation.MOVING',
				NLAD_DECEASED: 'NLADValidation.DECEASED',
				NLAD_TRANFER_REJECTION: 'NLADValidation.TransferRejection',
				NLAD_ENROLL_REJECTION: 'NLADValidation.EnrollRejection',
				NLAD_MAINTENANCE: 'NladMaintenance',
				NOT_AUTHENTICATED: 'NOT_AUTHENTICATED',
				NOT_FOUND: 'NotFound',
				LOGIN: 'Login',
				UNDERAGE: 'Underage',
				PERSON_BLACKLISTED: 'HHWSBlackList',
				SESSION_EXPIRED: 'SessionExpired',
				SSN: 'SSN',
				TOKEN: 'Token',
				UNIQUE_FULL_SSN: 'Error.UniqueFullSSN.12',
				USAC: 'USAC',
				VERIFICATION_SERVICE: 'VerificationServiceValidation',
				TIMEOUT: 'Timeout',
				TIMEOUT_ERROR_CODE: 'TimeoutErrorCode',
				MIN_AGE: 'MinAgeValidator.Birthday',
				DEVICE_NOT_AVAILABLE: 'deviceNotAvailable',
				SERVICE_DOWN: 'ServiceDown',
				TRANSFER_LIMIT: 'transferLimit',
				INVALID_NAME:'INVALID_NAME',				
				RETURNING_CUSTOMER: 'RETURNING_CUSTOMER',
				BQP_SUBSCRIBER_DUPLICATE: 'SubscriberDuplicate',
				PO_BOX_NOT_ALLOWED: 'PO_BOX_NOT_ALLOWED',
				VERIZON_FORWARD_REJECTED: "VERIZON_FORWARD_REJECTED",
				VERIZON_FORWARD_NEW_CUSTOMER_REJECTED: "VERIZON_FORWARD_NEW_CUSTOMER_REJECTED",			
				INVALID_FORMAT_ERROR: 'ERROR_INVALID_FORMAT_TYPE',
			},

			AirMessageType: {
				INFORMATION: 'information',
				INFO: 'info',
				WARNING: 'warning'
			},

			AddressGenericMsg: 'The street number from the address provided is invalid. Please, verify your address information and try again.',
				
			Status: {
				ACCEPT: 'Accept',
				ACCEPTED: 'Accepted',
				ACCESS_DENIED: 'AccesDenied',
				ADDRESS: 'Address',
				APPROVED: 'Approved',
				CANCELLED: 'Cancelled',
				CAPTCHA: 'Captcha',
				DASHBOARD_SEARCH: 'DashboardSearch',
				DEACTIVATED_AGENT: 'DeactivatedAgent',
				DEENROLLED: 'DEENROLLED',
				ENROLLED: 'ENROLLED',
				ENROLLMENT: 'Enrollment',
				ENROLLMENT_STATUS: 'EnrollmentStatus',
				ERROR: 'Error',
				EXPIRED: 'Expired',
				FAIL: 'Fail',
				HOME_TELEPHONE: 'HomeTelephone',
				ID_NOT_FOUND: 'IDNotFound',
				INVALID: 'Invalid',
				INVALID_AGENT: 'InvalidAgent',
				INVALID_NAME: 'InvalidName',
				LOGIN: 'Login',
				MISSING: 'Missing',
				MULTIPLE_ID_FOUND: 'MultipleIDFound',
				NLADMAINTENANCE: 'NladMaintenance',
				NOT_FOUND: 'NotFound',
				NOT_REQUIRED: 'NotRequired',
				NOT_UPDATED: 'NotUpdated',
				OK: 'OK',
				PENDING: 'PENDING',
				PO_BOX: 'PoBoxNotAllowed',
				QUALIFIED: 'QUALIFIED',
				RE_ENROLL: 'ReEnroll',
				RECERTIFIED: 'RECERTIFIED',
				REJECT: 'Reject',
				REJECTED: 'REJECTED',
				RETURNING_CUSTOMER: 'ReturningCustomer',
				REVIEW: 'Review',
				SERVICE_DOWN: 'ServiceDown',
				SHIPPING_ADDRESS: 'ShippingAddress',
				SUGGEST: 'Suggest',
				SUSPENDED: 'Suspended',
				TOKEN: 'Token',
				TOKEN_LOGIN: 'TokenLogin',
				UNKNOWN: 'Unknown',
				UNQUALIFIED: 'UNQUALIFIED',
				UPDATED_FINISHED: 'UpdatedFinished',
				UPDATED_INITIALIZED: 'UpdateInitialized',
				UPLOAD: 'Upload',
				VERIFYING: 'Verifying',
				ZIPCODE: 'ZipCode'
			},

			Flow: {
				APPLICATION_FORM: 'APPLICATION_FORM',
				AddressEscalation: 'AddressEscalation',
				Usac: 'USAC',
				AddressBlacklisted: 'BlacklistedAddress',
				PersonBlacklisted: 'PersonBlacklisted',
				CAPTCHA: 'Captcha',
				DASHBOARD_SEARCH: 'DashboardSearch',
				LOGIN: 'Login',
				SHIPPING_ADDRESS: 'ShippingAddress',
				SUGGESTION: 'Suggestion',
				INVALID_ZIPCODE: 'InvalidZipCode',
				SERVICE_DOWN: 'ServiceDown',
				ZIPCODE_INVALID_EDITING: 'ZipCodeInvalidEditing',
				PREVIOUS_ENROLLMENT_PENDING: 'PreviousEnrollmentPending',
				MinAge: 'MinAgeValidator.Birthday',
				PoBoxNotAllowed: 'PoBoxNotAllowed'
			},

			Pattern: {
				ADDRESS: /^(?!p[.\s]*o[.\s]*box)[A-Za-z0-9 \/,'-]*[A-Za-z0-9][A-Za-z0-9 \/,'-]*$/i,
				ADDRESS_OR_POBOX: /^[A-Za-z0-9 \/,'-]*[A-Za-z0-9][A-Za-z0-9 \/,'-]*$/,
				CITY: /^[^,]*$/,
				ALPHA: /^[A-Za-z\s]+$/,
				DATE: /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[1-2][0-9]|3[0-1])$/,
				EMAIL: /^[a-zA-Z0-9\+_-]*(\.?[a-zA-Z0-9\+_-])*@[a-zA-Z0-9-]+(\.[0-9a-zA-Z]{2,3}){1,2}$/,
				LAST_NAME: /^(?!.*[^a-zA-ZÀ-ÿ\s'-])(?![\s'-]|.*[\s'-]$).{2,50}$/,
				NAME: /^(?!.*[^a-zA-ZÀ-ÿ\s'-])(?![\s'-]|.*[\s'-]$).{1,30}$/,
				MIDDLE_NAME: /^(?!.*[^a-zA-ZÀ-ÿ\s'-])(?![\s'-]|.*[\s'-]$).{1,30}$/,
				NUMERIC: /^[0-9]+$/,
				NUMERIC_ONE: /^([1-9]{1})[0-9]*$/,
				NUMERIC_DEC: /^[1-9][0-9]?$/,
				NUMERIC_PEOPLE: /^(9|[1-9][0-9])$/,
				ONE_ALPHA: /^[A-Z|a-z]{1}$/,
				PHONE: /\b[2-9][0-9]{9}$/,
				SSN: /^\d{4}$/,
				SSNRegex: /^((?!0000)\d{4})$/,
				ZIPCODE: /\d{5}/,
				SUFFIX: /^$|^[a-zA-Z]{1,4}$/,
				INITIALS: /^$|^[a-zA-ZñÑáéíóúÁÉÍÓÚäëïöüÄËÏÖÜ.\s]{2}$/,
				NVID: /^[A-Za-z0-9]{6}(?:-[A-Za-z0-9]{5})*$/,
				FULL_NAME:/^(?!.*[^a-zA-ZÀ-ÿ\s'-])(?![\s'-]|.*[\s'-]$).{3,81}$/
			},

			SK: {
				Applicant: 'applicant',
				Application: 'application',
				Address: 'address',
				AddressValidation: 'addressValidation',
				Dependent: 'dependent',
				DefaultLogInType: 'defaultLogInType',
				CameraData: 'cameraData',
				CurrentProof: 'currentProof',
				LandingLogInType: 'landingLogInType',
				Geolocation: 'geolocation',
				GclId: 'gclId',
				Qualification: 'qualification',
				FileData: 'fileData',
				Login: 'loginData',
				LoginCaptcha: 'loginCaptcha',
				Token: 'token',
				LandingEnrollmentNumber: 'landingEnrollmentNumber',
				NladIneligibleTransferDate: 'nladIneligibleTransferDate',
				NladErrors: 'NladErrors',
				AppToken: 'appToken',
				BrowserToken: 'browserToken',
				Captcha: 'captcha',
				ShippingAddress: 'shippingAddress',
				ShippingAddressRollback: 'shippingAddressRollback',
				Lang: 'lang',
				Steps: 'steps',
				CurrentStep: 'currentStep',
				Plan: 'plan',
				Brand: 'brand',
				Disclaimers: 'disclaimers',
				Disclosures: 'disclosures',
				Enrollment: 'enrollment',
				Error: 'error',
				FlowCompleted: 'flowCompleted',
				FeaturesInformation: 'featuresInformation',
				AccountLogin: 'accountLogin',
				UpdateAddress: "updateAddress",
				UpdateContact: "updateContact",
				GetDeEnrollReasons: "GetDeEnrollReasons",
				ErrorCode: "ErrorCode",
				GuidedProofsNeeded: 'proofsNeeded',
				RequestedZipcode: 'requestedZipcode',
				PreviousStep: 'previousStep',
				Flow: 'flow',
				Quest: {
					Incomes: 'Quest_income',
					Disclaimers: 'Quest_disclaimers'
				},
				UpdatePersonalEmerios: 'updatePersonalEmerios',
				ValidateShipping: 'validateShipping',
				LoginStateParams: 'loginStateParams',
				NatVerApiToken: 'natVerApiToken',
				FlowExecutionToken: 'FlowExecutionToken',
				QueryParams: 'query_params',
				IsOnlyACP: 'isOnyACP',
				ContactPhoneNumber: 'contactPhoneNumber',
				UseRelatedApplicationToken: 'RelatedApplicationToken',
				AddressPrePopulated: 'addressPrePopulated',
				Configuration: 'configuration',
				UploadProofStep: 'uploadProofStep',
				Token: 'token',
				AccessTokenData: 'accessTokenData',
				AirMessageSection: 'airMessageSection'
			},

			BenefitType: {
				ACP: 'ACP',
				LIFELINE: 'LIFELINE',
				VZF: 'VZF'
			},
			BusinessLine: {
				FIOS: 'FIOS',
				FWA: 'FWA',
				MOBILITY: 'VZF'
			},
			NladError: {
				DECEASED: 'Error.NLADValidation.DECEASED'
			},

			Url: {
				English: '/en/',
				Spanish: '/es/',

				CheckAcp: 'https://acpbenefit.org',
				CheckLifeline: ',',

				DefaultMainSite: {
					en: 'https://www.att.com/',
					es: 'https://www.att.com/es-us/'
				},
				
				Accessibility: {
					en: 'https://www.att.com/',
					es: 'https://www.att.com/es-us/'
				},
				
				SuccessSite: {
					en: 'https://www.att.com/',
					es: 'https://www.att.com/es-us/'
				},
				
				FAQ: {
					en: 'https://www.att.com/support/article/wireless/KM1475234',
					es: 'https://www.att.com/es-us/support/article/wireless/KM1475234'
				},
				
				AboutUs: {
					en: 'http://www.att.com/prepaid',
					es: 'https://www.att.com/es-us/prepaid/'
				},

				DownloadRecertForm: {
					en: 'https://affordableconnectivity.gov/wp-content/uploads/ACP-Recertification-Form-English.pdf',
					es: 'https://affordableconnectivity.gov/wp-content/uploads/ACP-Recertification-Form-Spanish.pdf'
				},	

				VzSupportOverview: {
					en: 'https://www.verizon.com/support/',
					es: 'https://espanol.verizon.com/support/'
				},
				VzInternetHome: {
					en: 'https://www.verizon.com/home/internet/',
					es: 'https://espanol.verizon.com/home/internet/'			
				},
				VzInHome: {
					en: 'https://www.verizon.com/inhome/buildproducts',
					es: 'https://espanol.verizon.com/inhome/buildproducts'			
				},
				VzForwardDiscount: {
					en: 'https://www.verizon.com/discounts/verizon-forward',
					es: 'https://espanol.verizon.com/discounts/verizon-forward'

				},
				VzReturnPolicy: {
					en: 'https://www.verizon.com/support/return-policy/',
					es: 'https://espanol.verizon.com/support/return-policy/'
				},

				VzContactUs: {
					en: 'https://www.verizon.com/support/contact-us/',
					es: 'https://espanol.verizon.com/support/contact-us/'
				},

				VzCommunityForums: {
					en: 'https://community.verizon.com/',
					es: 'https://community.verizon.com/'
				},

				VzSignIn: {
					en: 'https://secure.verizon.com/signin',
					es: 'https://espanol.verizon.com/sdsecure/signin'
				},

				VzDownloadApp: {
					en: 'https://www.verizon.com/solutions-and-services/my-verizon-mobile/',
					es: 'https://espanol.verizon.com/solutions-and-services/my-verizon-mobile/'
				},

				VzLifeline: {
					en: 'https://www.verizon.com/support/lifeline/',
					es: 'https://espanol.verizon.com/support/lifeline/'
				},

				VzAccessibility: {
					en: 'https://www.verizon.com/accessibility/',
					es: 'https://espanol.verizon.com/accessibility/'
				},

				VzCheckNetworkStatus: {
					en: 'https://www.verizon.com/support/check-network-status/',
					es: 'https://espanol.verizon.com/support/check-network-status/'
				},

				VzAboutus: {
					en: 'https://www.verizon.com/about',
					es: 'https://www.verizon.com/about'
				},
				
				VzCareers: {
					en: 'https://mycareer.verizon.com/',
					es: 'https://mycareer.verizon.com/'
				},
				
				VzNews: {
					en: 'https://www.verizon.com/about/news-center',
					es: 'https://www.verizon.com/about/news-center'
				},
				
				VzResponsibility: {
					en: 'https://www.verizon.com/about/responsibility',
					es: 'https://www.verizon.com/about/responsibility'
				},
				
				VzInnovativeLearning: {
					en: 'https://www.verizon.com/about/responsibility/digital-inclusion/verizon-innovative-learning',
					es: 'https://www.verizon.com/about/responsibility/digital-inclusion/verizon-innovative-learning'
				},
				
				VzConsumerInfo: {
					en: 'https://www.verizon.com/info/sitemap/',
					es: 'https://espanol.verizon.com/info/sitemap/'
				},
				
				VzArticles: {
					en: 'https://www.verizon.com/articles/',
					es: 'https://espanol.verizon.com/articles/'
				},
				
				VzBrochures: {
					en: 'https://www.verizon.com/support/collateraldownload/',
					es: 'https://espanol.verizon.com/support/collateraldownload/'
				},
				
				VzTermsCons: {
					en: 'https://www.verizon.com/about/terms-conditions/overview',
					es: 'https://www.verizon.com/about/terms-conditions/overview'
				},
				
				VzPaymentTermsCons: {
					en: 'https://ss7.vzw.com/is/content/VerizonWireless/Device-Payment-Agreement-Template',
					es: 'https://ss7.vzw.com/is/content/VerizonWireless/Device-Payment-Agreement-Template'
				},
				
				VzReportVulnerability: {
					en: 'https://www.verizon.com/solutions-and-services/report-security-vulnerability/',
					es: 'https://espanol.verizon.com/solutions-and-services/report-security-vulnerability/'
				},
				
				VzCustomerAgreement: {
					en: 'https://www.verizon.com/support/customer-agreement/',
					es: 'https://espanol.verizon.com/support/customer-agreement/'
				},
				
				VzAnnouncements: {
					en: 'https://www.verizon.com/support/residential/announcements/',
					es: 'https://espanol.verizon.com/support/residential/announcements/'
				},
				
				VzFreqEmissions: {
					en: 'https://www.verizon.com/support/radio-emissions/',
					es: 'https://espanol.verizon.com/support/radio-emissions/'
				},
				
				VzTaxesSurcharges: {
					en: 'https://www.verizon.com/support/surcharges/',
					es: 'https://espanol.verizon.com/support/surcharges/'
				},
				
				VzRecall: {
					en: 'https://www.verizon.com/featured/jetpack-recall',
					es: 'https://espanol.verizon.com/featured/jetpack-recall'
				},
				
				VzLegalNotices: {
					en: 'https://www.verizon.com/support/legal-notices/',
					es: 'https://espanol.verizon.com/support/legal-notices/'
				},
				
				VzFacebook: {
					en: 'https://www.facebook.com/verizon',
					es: 'https://www.facebook.com/verizon'
				},
				
				VzTwitter: {
					en: 'https://twitter.com/verizon',
					es: 'https://twitter.com/somosfios'
				},
				
				VzYoutube: {
					en: 'https://www.youtube.com/user/verizon',
					es: 'https://www.youtube.com/user/verizon'
				},
				
				VzInstagram: {
					en: 'https://www.instagram.com/verizon/',
					es: 'https://www.instagram.com/verizon/'
				},
				
				VzFiosFacebook: {
					en: 'https://www.facebook.com/Fios/',
					es: 'https://www.facebook.com/Fios/'
				},
				
				VzFiosTwitter: {
					en: 'https://twitter.com/verizonfios',
					es: 'https://twitter.com/somosfios'
				},

				VzSiteMap: {
					en: 'https://www.verizon.com/info/sitemap/',
					es: 'https://espanol.verizon.com/info/sitemap/'
				},
				
				VzPrivacyPolicy: {
					en: 'https://www.verizon.com/about/privacy/',
					es: 'https://esus.verizon.com/about/privacy/'
				},
				
				VzCaPrivacyNotice: {
					en: 'https://www.verizon.com/about/privacy/full-privacy-policy?scrollto=index-1#acc-item-55',
					es: 'https://esus.verizon.com/about/privacy/full-privacy-policy'
				},
				
				VzYourPrivacy: {
					en: 'https://www.verizon.com/privacy/your-privacy-choices',
					es: 'https://espanol.verizon.com/privacy/your-privacy-choices'
				},
				
				VzA11y: {
					en: 'https://www.verizon.com/accessibility/',
					es: 'https://espanol.verizon.com/accessibility/'
				},
				
				VzOpenInternet: {
					en: 'https://www.verizon.com/about/our-company/open-internet',
					es: 'https://www.verizon.com/about/our-company/open-internet'
				},
				
				VzGeneralTermsCons: {
					en: 'https://www.verizon.com/about/terms-conditions/overview',
					es: 'https://www.verizon.com/about/terms-conditions/overview'
				},
				
				VzAboutAds: {
					en: 'https://www.verizon.com/about/privacy/about-our-ads',
					es: 'https://esus.verizon.com/about/privacy/about-our-ads'
				},
				
				RecertOnline: 'http://www.affordableconnectivity.gov/'
				
			},

			Month: {
				en: {
					JAN: 1,
					FEB: 2,
					MAR: 3,
					APR: 4,
					MAY: 5,
					JUN: 6,
					JUL: 7,
					AUG: 8,
					SEP: 9,
					OCT: 10,
					NOV: 11,
					DEC: 12
				},
				es: {
					ENE: 1,
					FEB: 2,
					MAR: 3,
					ABR: 4,
					MAY: 5,
					JUN: 6,
					JUL: 7,
					AGO: 8,
					SEP: 9,
					OCT: 10,
					NOV: 11,
					DIC: 12
				}
			},

			Step: {
				PersonalInformation: 'personalInformation',
				Address: 'address',
				Eligibility: 'eligibility',
				TransferException: 'transferException',
				Review: 'review',
				ApplicationCompleted: 'applicationCompleted',
				BenefitSelection: 'benefitSelection',
				Landing: 'Landing'
			},
			
			StepStatus: {
				NotStarted: '',
				Done: 'done',
				Current: 'current'
			},

			Brand: {
				VZ: {
					Code: 'VZ',
					Name: 'Verizon',
					Description: 'Verizon',
					Domain: ',',
					UrlWebPage: 'SuccessSite',
					PhoneNumber: '1-888-266-7848',
					EmailDomain: 'verizonmobile.com'
				}
			},

			TechSupportPhone: {
				VZ: {
					en: 'TBD',
					es: 'TBD'
				}
			},

			PublicSite: {			
				MyAccountMessageCode: {
					CancelService: 'CANCEL_SERVICE',
					ProofsNotRequired: 'PROOFS_NOT_REQUIRED',
					CannotUpdateInfo: 'CANNOT_UPDATE_INFO',
					ServiceCancelled: 'SERVICE_CANCELLED'
				}
			},

			Tenant: {
				VZ: 'VZ'
			},

			Document: {
				Status: {
					Missing: 'Missing',
					NotRequired: 'NotRequired',
					Approved: 'Approved'
				},

				Type: {
					AppFormStatus: "AppFormStatus",
					AnswerFormStatus: "AnswerFormStatus",
					AddressIsInvalidStatus: "AddressIsInvalidStatus",
					AddressIsDuplicatedStatus: "AddressIsDuplicatedStatus",
					ForcedAddressEscalation: "ForcedAddressEscalation",
					IdentityProof: "IdentityProof"
				}
			},

			TokenAction: {
				LoginToken: 'LoginToken'
			},

			//Emerios related constants
			Quest: {
				KeyCodes: {
					Data: 'DATA',
					EquipmentCosts: 'EQUIPMENT_COSTS',
					Caption: 'CAPTION',
					Category: 'CATEGORY',
					ServiceType: 'SERVICE_TYPE',
					PriceText: 'PRICE_TEXT',
					FinalPriceText: 'FINAL_PRICE_TEXT',
					CanCarryOver: 'CAN_CARRY_OVER',
					Features: 'FEATURES'
				},

				BussinessException: 'bussinessException',

				Error: {
					DeactivatedAgent: 'DEACTIVATED_AGENT',
					InvalidAddress: 'INVALID_ADDRESS',
					InvalidShippingAddress: 'INVALID_SHIPPING_ADDRESS',
					AddressBlacklisted: 'ADDRESS_BLACKLISTED',
					ShippingAddressBlacklisted: 'SHIPPING_ADDRESS_BLACKLISTED',
					AddressBlacklistedUpdate: 'ADDRESS_BLACKLISTED_UPDATE',
					ShippingAddressBlacklistedUpdate: 'SHIPPING_ADDRESS_BLACKLISTED_UPDATE',
					InvalidAgent: 'INVALID_AGENT',
					InvalidAgentCode: 'INVALID_AGENT_CODE',
					InvalidLexisNexis: 'INVALID_LEXISNEXIS',
					InvalidLexisNexis_DI: 'INVALID_LEXISNEXIS_DI',
					InvalidLexisNexis_02: 'INVALID_LEXISNEXIS_02',
					InvalidLexisNexis_50: 'INVALID_LEXISNEXIS_50',
					InvalidLexisNexis_SD: 'INVALID_LEXISNEXIS_SD',
					DuplicatedPerson: 'DUPLICATED_PERSON',
					DuplicatedPersonExternal:'DUPLICATED_PERSON_EXTERNAL',
					DuplicatedDependentExternal:'DUPLICATED_DEPENDENT_EXTERNAL',
					DuplicatedDependent: 'DUPLICATED_DEPENDENT',
					DuplicatedPersonPending: 'DUPLICATED_PERSON_PENDING',
					DuplicatedDependentPending: 'DUPLICATED_DEPENDENT_PENDING',
					DuplicatedPersonReEnroll: 'DUPLICATED_PERSON_REENROLL',
					DuplicatedDependentReEnroll: 'DUPLICATED_DEPENDENT_REENROLL',
					InvalidMovingDisabled: 'INVALID_MOVING_DISABLED',
					ApplicationExpired: 'APPLICATION_EXPIRED',
					Blacklisted: 'BLACKLISTED',
					Underage: 'UNDERAGE',
					Captcha: 'Captcha',
					Unauthorized: 'Not Authenticated',
					CommunicationError: 'CommunicationError',
					FormatError: 'FormatError',
					TransferCustomer: 'TransferCustomer',
					MissingRequired: 'MISSING_REQUIRED',
					InvalidApplication: 'INVALID_APPLICATION',
					PoBoxNotAllowed: 'PO_BOX_NOT_ALLOWED',
					GeneralDeliveryNotAllowed: 'GENERAL_DELIVERY_NOT_ALLOWED',
					MissingShippingAddress: 'MISSING_SHIPPING_ADDRESS',
					CannotChangeState: 'CANNOT_CHANGE_STATE',
					ZipcodeServiceNotAvailable: 'ZIPCODE_SERVICE_NOT_AVAILABLE',
					UpdatePending: 'UPDATE_PENDING',
					InvalidAddressRetriesFinished: 'INVALID_ADDRESS_RETRIES_FINISHED',
					InvalidBrand: 'INVALID_BRAND',
					NladServiceError: 'NLAD_SERVICE_UNAVAILABLE_ERROR',
					NladValidationError: 'NLAD_GENERIC_ERROR',
					NotTribalAddress: 'NOT_TRIBAL_ADDRESS_NOT_ALLOWED',
					InvalidName: 'INVALID_NAME',
					PincodeUsed: 'PINCODE_ALREADY_USED',
					PinCodeExpired: 'PINCODE_HAS_EXPIRED',
					ReturningCustomer: 'RETURNING_CUSTOMER',
					BqpSubscriberDuplicate: 'BQP_SUBSCRIBER_DUPLICATE'
				},

				Escalation: {
					TransferLimitApplicant: 'TRANSFER_LIMIT_APPLICANT_PICTURE'
				},
				
				LoginError: {
					Invalid_Credentials: 'invalid_credentials',
					Invalid_Parameters: 'invalid_parameters',
					Invalid_Captcha: 'invalid_captcha',
					Invalid_Application: 'invalid_application',
					ER_MULTIPLE_FOUND: 'multiple_enrollment_found'
				},

				Channel: {
					PublicSite: 'PUBLIC_SITE'
				},

				ApplicationFlags: {
					APPLICANT_CONTACT_ALLOWED: "applicant_contact_allowed",
					CELL: "cell",
					SERVICE_TYPE_FAMILY_PLAN: 'SERVICE_TYPE_FAMILY_PLAN',
					SERVICE_TYPE_SINGLE_LINE: 'SERVICE_TYPE_SINGLE_LINE',
					DUPLICATED_SHIPPING_ADDRESS:'duplicate_shipping_address'
				}
			},

			Requirement: {

				// ESignature: { Code: 'E_SIGNATURE' },
				// PaperApplication: { Code: 'PAPER_APPLICATION' },
				ProgramProof: { Code: 'PROGRAM_PROOF', Order: 1 },
				FormerAcpProof: { Code: 'FORMER_ACP_PROOF', Order: 1 },
				IncomeProof: { Code: 'INCOME_PROOF', Order: 1 },
			},

			RequirementStatus: {
				Pending: 'PENDING',
				Rejected: 'REJECTED',
				Approved: 'APPROVED'
			},
			
			RequirementType: {
				DOCUMENT: "DOCUMENT",
				PROCESS: "PROCESS"
			},

			Program: {
				FormerAcpBeneficiary: "FormerAcpBeneficiary"
			},

			Section: {
				MyAccountLogin: 'MyAccountLogin',
				LinkNotReferred: 'LinkNotReferred',
				LinkNotAuthenticated: 'LinkNotAuthenticated'
			},

			EnvironmentSettings: {
				Url: {
					baseQuestWebAppURL: 'https://quest.vz.emerios.com/api/v1/',
					baseVaultWebAppURL: 'https://vault.vz.emerios.com/api/',
					baseVaultAuthURL: 'https://vault.vz.emerios.com/'
				}
			},

			NatVerApi: {
				Result: {
					PendingCertification: 'PENDING_CERT',
					PendingResolution: 'PENDING_RESOLUTION',
					PendingReview: 'PENDING_REVIEW',
					PendingEligibility: 'PENDING_ELIGIBILITY',
					BadRquest: 'BAD_REQUEST',
					Complete: 'COMPLETE',
					ServiceUnavailableAcpWindDown: 'SERVICE_UNAVAILABLE_ACP_WIND_DOWN'
				}
			},

			NladErrors: {
				ApplicationPending: 'APPLICATION_PENDING',
				ApplicationNotComplete: 'APPLICATION_NOT_COMPLETE',
				ApplicationNotFound: 'APPLICATION_NOT_FOUND'
			},

			Events: {
				AirMessage: {
					OnLoad: 'air-message.onload'
				}
			},

			LogInType: {
				Enrollment: 'enrollment',
				PII: 'pii'
			},
			
			ProductType: {
				Plan: 'PLAN'
			},

			UploadProofStep: {
				RequiredDocumentation: 'requiredDocumentation',
				RequiredDocumentationUpload: 'requiredDocumentationUpload',
				RequiredDocumentationReview: 'requiredDocumentationReview',
				RequiredDocumentationAdditional: 'requiredDocumentationAdditional',
				RequiredDocumentationStatus: 'requiredDocumentationStatus',
				RequiredDocumentationSubmit: 'requiredDocumentationSubmit',
				RequiredDocumentationSubmitSuccess: 'requiredDocumentationSubmitSuccess',
				CameraView: 'cameraView'
			},

			ReviewStep: {
				Review: 'review',
				Error: 'error',
				Cancell: 'cancel'
			},
			
			LengthRestrictions:{
				addressLine1: 50,
				email: 50
			},
			
			UpdateStatus: {
				None: 'none',
				AddressOnly: 'addressOnly',
				AddressContactInfo: 'addressContactInfo',
				ContactInfoOnly: 'contactInfoOnly'
			}
		});
})();
