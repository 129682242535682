(function () {
    'use strict';

    angular.module('l4b').config(HttpProvider);

    function HttpProvider($httpProvider) {
        $httpProvider.interceptors.push(httpInterceptor);
    }

    function httpInterceptor($injector, $q, $rootScope, $timeout) {
        $rootScope.showSpinner = false;
        $rootScope.http = null;

        function hideSpinner() {
            var pendingRequests = getHttpService().pendingRequests.filter(function (pr) {
                return !!pr.spinner;
            });

            if (pendingRequests.length < 1) {
                $rootScope.showSpinner = false;
            }
        }

        function getRetryDelay(response) {
            if (!response.config.retry) {
                return false;
            }

            response.config.retryLimit = response.config.retryLimit || 3;
            response.config.retryCount = response.config.retryCount || 0;

            if (response.config.retryLimit <= response.config.retryCount) {
                return false;
            }

            response.config.retryCount++;

            var delay = 250;
            var timeout = response.config.retryCount * delay;

            return timeout >= delay
                ? delay * 5
                : timeout;
        }

        function getHttpService() {
            $rootScope.http = $rootScope.http || $injector.get('$http');

            return $rootScope.http;
        }

        return {
            request: function (config) {
                if (config.spinner) {
                    $rootScope.showSpinner = true;
                }

                return config || $q.when(config);
            },
            requestError: function (rejection) {
                hideSpinner();

                return $q.reject(rejection);
            },
            response: function (response) {
                hideSpinner();

                return response || $q.when(response);
            },
            responseError: function (rejection) {
                var retryDelay = getRetryDelay(rejection);
                if (retryDelay > 0) {
                    return $timeout(function () {
                        return getHttpService()(rejection.config);
                    }, retryDelay);
                }

                hideSpinner();

                return $q.reject(rejection);
            }
        };
    }
})();