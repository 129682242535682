(function () {
    'use strict';

    angular.module('l4b')
        .directive('externalLink', ExternalLink);

    ExternalLink.$inject = ['$rootScope', '$log', 'BrandService'];
		
    function ExternalLink($rootScope, $log, BrandService) {
		
		return {
            restrict: 'A',
            link: link
        };
		
		function link(scope, element) {
            if (!BrandService.isDefault()) return;

            if (element[0].tagName !== 'A') {
                $log.warn('[external-link] directives are only allowed for <a> tags.');
                return;
            }

            element.on('click', function($event) {
                $event.preventDefault();
                if ($event.currentTarget.href) {                    
                    var args = {
                        href: $event.currentTarget.href,
                        target: '_blank' //links will be opened in a new tab windows instead of specified $event.currentTarget.target
                    };
                    $rootScope.$broadcast('show-air-message', args);
                }
            });
		}
    }
})();