(function () {
    'use strict';

    angular.module('l4b')
        .component('addressInput', {
            templateUrl: 'html/shared/addressInput/addressInput.html',
            bindings: {
                address: '<',
                addressForm: '<',
                disableInputs: '<',
                allowChangeState: '<',
				suggestedAddress: '<',
				customAddressPattern: '<'
            },
            controller: AddressInputController,
            controllerAs: 'vm'
        });

    AddressInputController.$inject = ['Constants', 'RestService'];

    function AddressInputController(Constants, RestService) {

        var 
			vm = this,
			latestValidZipCode;

        angular.extend(vm, {
			$onInit: init,
            addressPattern: Constants.Pattern.ADDRESS,
            cityPattern: Constants.Pattern.CITY,
            zipCodePattern: Constants.Pattern.ZIPCODE,
            maxLengths: Constants.LengthRestrictions,
			onZipCodeChanged: onZipCodeChanged
        });
		
		function init() {
			latestValidZipCode = vm.address.ZipCode;
		}
		
		function onZipCodeChanged() {
			
			var newValue = vm.address.ZipCode;
			
			if (!newValue || !newValue.match(vm.zipCodePattern) || newValue == latestValidZipCode) {
				
				if (newValue == latestValidZipCode) {
					setZipCodeValidity(true);
					setStateValidity(true);
				}
				
				return;
			}
			
			RestService.getCities(vm.address.ZipCode)
				.then(onSuccessCheckZipCodeAddress);
		}
		

		function onSuccessCheckZipCodeAddress(data) {
			
			var error = RestService.getError();
			
			if (error) {
				validateErrors(error);
				
				return;
			}
			
			setZipCodeValidity(true);
			
			var city = data.data[0];
			var stateCode = city.state.code;
			
			if (!vm.allowChangeState && vm.address.State != stateCode) {
				
				setStateValidity(false);
				
				return;
			}
			
			setStateValidity(true);
			
			vm.address.City = city.name;
			vm.address.State = city.state.code;
			vm.address.StateName = city.state.name;
			
			latestValidZipCode = vm.address.ZipCode;
		}
		
		function validateErrors(error) {
			if (error == Constants.Error.INVALID_ZIPCODE) {
				setZipCodeValidity(false);
			}
			
			if (error == Constants.Error.CANNOT_CHANGE_STATE) {
				setStateValidity(false);
			}
		}
		
		function setZipCodeValidity(isValid) {
			vm.addressForm.zipCode.$setValidity('invalid', isValid);	
		}

		function setStateValidity(isValid) {
			vm.addressForm.zipCode.$setValidity('state', isValid);	
		}
    }
})();