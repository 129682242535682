
(function () {
    'use strict';

    angular.module('l4b')
        .filter('groupBy', ['$parse', function ($parse) {
            return _.memoize(function (items, field) {
                var getter = $parse(field);
                return _.groupBy(items, function (item) {
                    return getter(item);
                });
            }, function (items) {
                return JSON.stringify(items);
            });
        }]);
})();