(function () {
	'use strict';

	angular.module('l4b')
		.factory('GuidedProofService', GuidedProofService);

	GuidedProofService.$inject = ['Constants', 'StorageService', 'GlobalizationService', 'RestService', '$q', 'Utils','DTOFactory'];

	function GuidedProofService(Constants, StorageService, GlobalizationService, RestService, $q, Utils, DTOFactory) {
		
		var filesData = [];

		var service = {
			GetPendingFilesToSubmit: GetPendingFilesToSubmit, // Uploaded and not submitted
			GetMissingProofs: GetMissingProofs, // Not uploaded or skipped
			GetSubmittedProofs: GetSubmittedProofs, // Submitted
			GetNextProofNeeded: GetNextProofNeeded,
			GetCurrentProofData: GetCurrentProofData,
			ShowImageReviewPage: ShowImageReviewPage,
			ShowCameraPage: ShowCameraPage,
			ConfirmReviewProof: ConfirmReviewProof,
			DiscardReviewProof: DiscardReviewProof,
			RequestAnotherFile: RequestAnotherFile,
			SkipProof: SkipProof,
			ValidateProofData: ValidateProofData,
			RestartSkippedProofs: RestartSkippedProofs,
			SubmitAllProofs: SubmitAllProofs,
			GetProofsToSubmitCount: GetProofsToSubmitCount,
			SyncRequirements: SyncRequirements,
			CreateFromAnother: CreateFromAnother,
			GetFilesSubmitted: GetFilesSubmitted,
			CanReuseFrom: CanReuseFrom
		};

		return service;

		function initialize() {
			filesData = [];
		}
		
		function setData(key, isAdditional, data) {
			var suffix = isAdditional ? '_A' : '';
			
			filesData[key + suffix] = data;
		}

		function getData(key, isAdditional) {
			var suffix = isAdditional ? '_A' : '';
			
			return filesData[key + suffix];
		}

		//Prevent client reloading and losing data files.
		//Returns true if data is valid
		function ValidateProofData() {
			var proofs = getAllFiles();
			if (!proofs) {
				return false;
			}

			var anyCompletedProof = Utils.find(proofs, function (proof) {
				return proof.uploaded;
			});

			if (anyCompletedProof && GetPendingFilesToSubmit().length == 0) {
				return false;
			}

			return true;
		}

		function GetNextProofNeeded() {
			StorageService.remove(Constants.SK.CurrentProof);
			
			initialize();

			var allProofs = getAllFiles();

			var nextProof = Utils.find(allProofs, function (proof) {
				return !proof.uploaded && !proof.skipped;
			});

			if (!nextProof) {
				return null;
			}

			StorageService.set(Constants.SK.CurrentProof, nextProof);

			return nextProof;
		}

		function GetMissingProofs() {

			return Utils.filter(getAllFiles(), function (proof) {
				return !proof.isAdditional && (!proof.uploaded || proof.skipped);
			});
		}

		function createProof(proof) {
			return {
				code: proof.code,
				example: proof.example,
				name: proof.name,
				status: proof.status,
				additionalRequested: proof.additionalRequested || false,
				isAdditional: proof.isAdditional || false,
				isPhoto: false,
				uploaded: proof.uploaded || false,
				documentTypes: proof.documentTypes  || [],
				hasDocumentTypeWithDisclaimer: proof.hasDocumentTypeWithDisclaimer  || false,
				submitted: proof.submitted || false,
				recentlySubmitted: proof.recentlySubmitted || false,
				skipped: false,
				order: proof.order || null,
				failed: false,
				canChooseUpload: proof.canChooseUpload || false,
				reuseFrom: proof.reuseFrom || null
			};
		}

		function UpdateProofSession(currentProofData, optionObj) {

			var allProofs = getAllFiles();

			var thisProof = Utils.find(allProofs, function (proofSession) {
				return proofSession.code === currentProofData.code
					&& proofSession.isAdditional === currentProofData.isAdditional;
			});

			if (thisProof) {
				angular.extend(thisProof, optionObj);

				StorageService.set(Constants.SK.CurrentProof, thisProof);
				StorageService.set(Constants.SK.GuidedProofsNeeded, allProofs);
			}
		}

		function ShowCameraPage(isAdditional) {

			var currentProofData = GetCurrentProofData();

			UpdateProofSession(currentProofData, {
				isAdditional: isAdditional,
				isPhoto: true
			});
		}

		function ShowImageReviewPage(isAdditional) {

			var currentProofData = GetCurrentProofData();

			UpdateProofSession(currentProofData, {
				isAdditional: isAdditional,
				isPhoto: false
			});
		}

		function DiscardReviewProof() {

			var currentProofData = GetCurrentProofData();

			UpdateProofSession(currentProofData, {
				isAdditional: null,
				isPhoto: null
			});

			StorageService.remove(Constants.SK.FileData);
		}

		function ConfirmReviewProof(data) {
			var currentProofData = GetCurrentProofData();

			UpdateProofSession(currentProofData, {
				uploaded: true,
				submitted: false
			});
			
			setData(currentProofData.code, currentProofData.isAdditional, data);
		}

		function SkipProof() {
			var currentProofData = GetCurrentProofData();

			UpdateProofSession(currentProofData, { skipped: true });
		}

		function CreateFromAnother() {

			var currentProofData = GetCurrentProofData();

			UpdateProofSession(currentProofData, {
				createFromAnother: true,
				uploaded: true
			});
		}

		function RequestAnotherFile() {

			//Generates new proof obj
			var currentProof = GetCurrentProofData();
			var newProof = createProof({
				code: currentProof.code,
				name: currentProof.name,
				example: currentProof.example,
				isAdditional: true
			});

			UpdateProofSession(currentProof, {
				additionalRequested: true
			});

			var allProofs = getAllFiles();

			allProofs.push(newProof);

			StorageService.set(Constants.SK.GuidedProofsNeeded, sortProofs(allProofs));

			StorageService.set(Constants.SK.CurrentProof, newProof);
		}

		function sortProofs(proofs) {
			return proofs.sort(function (a, b) {
				if (a.order < b.order) {
					return -1;
				}
				if (a.order > b.order) {
					return 1;
				}
				if (a.code < b.code) {
					return -1;
				}

				if (a.code > b.code) {
					return 1;
				}

				return 0;
			});
		}

		function GetCurrentProofData() {
			return StorageService.get(Constants.SK.CurrentProof);
		}

		function getApplicationToken() {
			var application = DTOFactory.Application();
			return application.ApplicationToken;
		}

		function SyncRequirements() {
			var lang = GlobalizationService.getLang();
			var applicationToken = getApplicationToken();

			var defered = $q.defer();
			var promise = defered.promise;

			function onError() {
				defered.reject();
			}

			function getApplicationRequirementSuccess(requirements, submitted) {

				var requiredDocuments = [];
				var lang = GlobalizationService.getLang();

				var allProofs = getAllFiles();

				angular.forEach(requirements, function (requirement) {

					if (!Utils.isRequirementNeeded(requirement)) {
						return;
					}

					var proof = requirement.code;

					var isSubmitted = Utils.any(submitted, function (appUpl) {
						return appUpl == proof;
					});

					var additionalRequested = Utils.any(allProofs, function (alreadyProof) {
						return alreadyProof.code == proof && alreadyProof.additionalRequested;
					});

					var reqConfig = Utils.getRequirementConfiguration(requirement);

					requiredDocuments.push(createProof({
						code: proof,
						name: requirement.name,
						documentTypes: requirement.documentTypes,
						hasDocumentTypeWithDisclaimer: requirement.hasDocumentTypeWithDisclaimer,
						status: requirement.status.code,
						uploaded: isSubmitted,
						completed: isSubmitted,
						submitted: isSubmitted,
						additionalRequested: additionalRequested,
						order: reqConfig.Order,
						canChooseUpload: reqConfig.CanChooseUpload,
						reuseFrom: reqConfig.ReuseFrom
					}));
				});

				StorageService.set(Constants.SK.GuidedProofsNeeded, sortProofs(requiredDocuments));

				defered.resolve();
			}

			//Get submitted and pending proofs from server
			RestService.getUploadedDocumentRequirements(applicationToken, function (submitted) {
				RestService.getApplicationRequirements(applicationToken, lang, function (result) {
					getApplicationRequirementSuccess(result.data, submitted.data);
				}, onError);
			}, onError);

			return promise;
		}

		function GetSubmittedProofs(recentlySubmitted) {
			return Utils.filter(getAllSubmittedProofs(), function (proof) {
				return !proof.isAdditional && proof.submitted && proof.recentlySubmitted == recentlySubmitted;
			});
		}
		
		function getAllSubmittedProofs() {
			return Utils.filter(getAllFiles(), function (proof) {
				return !proof.isAdditional && proof.submitted;
			});
		}
		

		function RestartSkippedProofs() {
			angular.forEach(getAllFiles(), function (proof) {
				UpdateProofSession(proof, {
					skipped: false
				});
			});
		}

		function createReuseToSubmit(sourceCode, targetCode) {
			return {
				code: targetCode,
				createFromAnother: true,
				payload: {
					requirementCode: sourceCode,
					newRequirements: [targetCode]
				}
			};
		}

		function createFileToSubmit(code, content, contentType, secondaryContent, secondaryContentType) {
			return {
				code: code,
				payload: {
					MainImage: content,
					SecondaryImage: secondaryContent,
					PreclassifiedRequirementCodes: [code],
					ContentType: contentType,
					ContentType_SecondaryImage: secondaryContentType
				}
			};
		}

		function GetProofsToSubmitCount() {

			return GetPendingFilesToSubmit().length;
		}

		function SubmitAllProofs() {
			var defered = $q.defer();
			var promise = defered.promise;

			var files = GetPendingFilesToSubmit();
			var maxFilesCounter = files.length;

			if (maxFilesCounter == 0) {
				defered.reject("No files to submit!");
			}

			var filesReadyToSubmit = [];
			angular.forEach(files, function (file) {
				
				if (file.createFromAnother) {
					filesReadyToSubmit.push(createReuseToSubmit(
						file.reuseFrom,
						file.code
					));
				}
				else {
					var data = getData(file.code, false);

					var secondImageData = getData(file.code, true);

					filesReadyToSubmit.push(createFileToSubmit(
						file.code,
						data.Content,
						data.ContentType,
						secondImageData ? secondImageData.Content : null,
						secondImageData ? secondImageData.ContentType : null
					));
					
				}
			});

			var actualCounter = 0;
			var applicationToken = getApplicationToken();

			function submitProofSuccess() {

				defered.notify({
					proofUploaded: filesReadyToSubmit[0].code
				});

				setAsSubmitted(filesReadyToSubmit[0].code);

				//Always send and remove the first
				filesReadyToSubmit = filesReadyToSubmit.slice(1);

				if (filesReadyToSubmit.length > 0) {
					doSubmitProof(submitProofSuccess, submitProofError);
				}
				else {
					defered.resolve();
				}
			}

			function submitProofError(error) {
				defered.notify({
					proofFailed: filesReadyToSubmit[0].code
				});

				setAsIncompleted(filesReadyToSubmit[0].code);

				filesReadyToSubmit = filesReadyToSubmit.slice(1);

				if (filesReadyToSubmit.length > 0) {
					doSubmitProof(submitProofSuccess, submitProofError);
				}
				else {
					defered.resolve(error);
				}
			}

			function doSubmitProof(success, error) {

				actualCounter++;
				
				var file = filesReadyToSubmit[0];
				
				defered.notify({
					proofToSubmit: file,
					actualCount: actualCounter,
					maxFiles: maxFilesCounter
				});
				
				if (file.createFromAnother) {
					return RestService.createProofFromAnother(applicationToken, file.payload, success, error);
				}

				return RestService.submitProof(file.payload, applicationToken, success, error);
			}

			doSubmitProof(submitProofSuccess, submitProofError);

			return promise;
		}

		/*
			LOCAL UTILS
		*/

		function setAsIncompleted(proofCode) {
			var allProofs = getAllFiles();

			angular.forEach(allProofs, function (proof) {

				if (proof.code === proofCode && proof.uploaded) {
					UpdateProofSession(proof, {
						failed: true,
						recentlySubmitted: true
					});
				}
			});
		}

		function GetPendingFilesToSubmit() {

			var allProofs = getAllFilesToSubmit();

			return Utils.filter(allProofs, function (proof) {
				return !proof.isAdditional && proof.uploaded && !proof.submitted;
			});
		}

		function CanReuseFrom() {
			
			var currentProofData = GetCurrentProofData();
			
			if (!currentProofData || !currentProofData.reuseFrom) {
				return false;
			}
			
			return Utils.find(getAllSubmittedProofs(), function (proof) {
				return proof.code == currentProofData.reuseFrom;
			});
		}

		function GetFilesSubmitted() {

			var allProofs = getAllFilesToSubmit();

			return Utils.filter(allProofs, function (proof) {
				return !proof.isAdditional && proof.uploaded && proof.submitted;
			});
		}

		function getAllFilesToSubmit() {

			var allProofs = getAllFiles();

			return Utils.filter(allProofs, function (proof) {
				return proof.uploaded;
			});
		}

		function getAllFiles() {

			return StorageService.get(Constants.SK.GuidedProofsNeeded) || [];
		}

		function setAsSubmitted(proofCode) {

			var allProofs = getAllFiles();

			angular.forEach(allProofs, function (proof) {

				if (proof.code === proofCode && proof.uploaded) {
					UpdateProofSession(proof, {
						submitted: true,
						recentlySubmitted: true
					});
				}
			});
		}
	}
})();
