(function() {
    'use strict';

	angular.module('l4b')
	.factory('LoginService', LoginService);

	LoginService.$inject=['Constants', 'StorageService', 'RestService', '$q'];

	function LoginService(Constants, StorageService, RestService, $q) {
		
		var service = {
			login: login           
		};

		return service;

		function login() {
			var mainDefer = $q.defer();

			tryLoginEmerios().then(
				onLoginEmeriosSuccess(mainDefer),
				onLoginEmeriosError(mainDefer));

			return mainDefer.promise;
		}


		// Local utils

		function tryLoginEmerios(){
			var deferred = $q.defer();

			RestService.accountLogin(
				function onSuccess(response){
					if (!response || !response.data) {
						deferred.reject(response);
					}

					deferred.resolve(response);
				},
				function onError(error){
					deferred.reject(error);
				}
			);

			return deferred.promise;
		}

		//Logged into Emerios!
		function onLoginEmeriosSuccess(mainPromise) {
			return function local(response){
				StorageService.remove(Constants.SK.Captcha);

				mainPromise.resolve(response);
			};
		}

		// Failed login in Enrollment or Emerios.
		function onLoginEmeriosError(mainPromise) {
			return function local(errorResponse){
				var errors = Constants.Quest.LoginError;

				if(isError(errorResponse, errors.Invalid_Credentials) ||
					isError(errorResponse, errors.Invalid_Parameters) ||
					isError(errorResponse, errors.Invalid_Captcha) ||
					isError(errorResponse, errors.Invalid_Application) ||
					isError(errorResponse, errors.ER_MULTIPLE_FOUND)) {
						mainPromise.reject(errorResponse.data.error);
					}

                mainPromise.reject(errorResponse);
			};
		}

		function isError(response, error){
	    	return response && response.data && response.data.error === error;
	    }	
	}
})();
