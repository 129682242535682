(function() {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentationTable', {
            templateUrl: 'html/shared/requiredDocumentationTable/requiredDocumentationTable.html',
 			bindings: {
				showExamples: '<',
                metadata: '<'
			},
			controller: RequiredDocumentationTableController,
			controllerAs: 'vm'
        });

    function RequiredDocumentationTableController() {
        var vm = this;

        angular.extend(vm, {
            showDisclaimer: showDisclaimer,
        });
		
		function showDisclaimer(metadata) {
            if(metadata)
            {
              return metadata.some(m => m.hasDocumentTypeWithDisclaimer);
            }
            return false;
		}


    }
})();
