(function () {

	'use strict';

	angular.module('l4b')
		.component('errors', {
			templateUrl: 'html/shared/errors/errors.html',
			bindings: {
				messages: '<'
			},
			controller: ErrorsComponent,
			controllerAs: 'vm'
		});

	ErrorsComponent.$inject = [];

	function ErrorsComponent() {

		var vm = this;

		angular.extend(vm, {
			$onInit: init
		});

		function init() {
		}
	}
})();
