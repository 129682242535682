(function () {
	'use strict';

	angular.module('l4b')
		.controller('InvalidAddressMyAccountController', InvalidAddressMyAccountController);

	InvalidAddressMyAccountController.$inject = ['DTOFactory', 'StorageService', 'Constants', 'MyAccountService', 'Utils'];

	function InvalidAddressMyAccountController(DTOFactory, StorageService, Constants, MyAccountService, Utils) {
		var 
			vm = this,
			updateAddressDTO = DTOFactory.UpdateAddress();

		angular.extend(vm, {
			$onInit: init,
			addressDTO: null,
			nextStep: nextStep,
			cancel: cancel
		});

		function init() {
			vm.addressDTO = updateAddressDTO.Address;
		}

		function nextStep(invalidAddressForm) {
			if (!Utils.validateForm(invalidAddressForm)) {
				return;
			}			

			updateAddressDTO.Address = vm.addressDTO;

			StorageService.set(Constants.SK.UpdateAddress, updateAddressDTO);
			
			MyAccountService
				.UpdateInfo()
				.then(onSuccessUpdateInfo);
		}

		function onSuccessUpdateInfo(response) {
            var error = StorageService.get(Constants.SK.Error);
            if (error) {
				validateError(error);

				return;
            }
			
        	MyAccountService.GoTo(Constants.Page.MyAccountMessage, {
				messageKey: response,
				tab: Constants.Page.MyAccountUpdate
        	});		
		}
		
		function validateError(error) {

			if (error == Constants.Error.INVALID_ADDRESS) {
				MyAccountService.GoTo(Constants.Page.MyAccountShippingAddress, {
					tab: Constants.Page.MyAccountUpdate
				});
			}
		}		

		function cancel() {
			MyAccountService.GoTo(Constants.Page.MyAccountUpdate);
		}
	}
})();
