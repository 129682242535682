(function () {
	'use strict';

	angular.module('l4b')
		.controller('LoginController', LoginController);

	LoginController.$inject = ['$state', 'DTOFactory', 'StorageService', 'FlowService', 'Utils', 'Constants', 'RestService', 'LoginService', '$stateParams', '$rootScope', '$cookies', 'FlowExecutionService'];

	function LoginController($state, DTOFactory, StorageService, FlowService, Utils, Constants, RestService, LoginService, $stateParams, $rootScope, $cookies, FlowExecutionService) {
		var vm = this;
		angular.extend(vm, {
			$onInit: init,
			logIn: logIn,
			cancel: cancel,
			setForgotEnrollment: setForgotEnrollment,
			accountLoginDTO: DTOFactory.AccountLogin(),
			captchaDTO: DTOFactory.Captcha(),
			reloadCaptcha: null,
			patterns: setPatterns(),
			showEnrollmentNotFoundMessage: false,
			showEnrollmentNotFoundAlternativeMessage: false,
			showMultipleEnrollmentErrorMessage: false,
			showAditionalFields: false,
			selectedLogInType: null,
			yearFrom: 111,
			tabs: null,
            maskUserDataDob: true,
			maskUserDataValueDob: 'password',
            toggleMaskDataDob: toggleMaskDataDob,
			LogInType: Constants.LogInType,
		});

		function init() {
			FlowExecutionService.logFlowExecutionEvent(Constants.Event.Telesales.SYSTEM_MY_EBB_ACCOUNT_PRESENTATION_COMPLETED);

			if ($stateParams.isSessionExpired) {
				$rootScope.$broadcast('show-air-message', {
					section: Constants.Section.MyAccountLogin
				});
			}
			
			// Avoid saving params when refreshing the page
			if ($stateParams.tabSelected) {
				StorageService.set(Constants.SK.LoginStateParams, $stateParams);
			}
			
			StorageService.remove(Constants.SK.Captcha);
			StorageService.remove(Constants.SK.AccountLogin);
			
			vm.accountLoginDTO.Dob = null;
			
			validateUrlParams();

			preLoadEnrollmentNumber();
		}
		
		function preLoadEnrollmentNumber() {
			var landingEnrollmentNumber = StorageService.get(Constants.SK.LandingEnrollmentNumber);

			vm.accountLoginDTO.ExternalApplicationId = landingEnrollmentNumber || null;

			vm.selectedLogInType = StorageService.get(Constants.SK.LandingLogInType) || StorageService.get(Constants.SK.DefaultLogInType) || Constants.LogInType.Enrollment;
			
			vm.tabs = [
				Constants.LogInType.Enrollment,
				Constants.LogInType.PII
			];			
		}
		
		function logIn(loginForm) {
			var formIsValid = Utils.validateForm(loginForm);
			if (!formIsValid) {
				return;
			}

			vm.submitError = false;
			vm.accountLoginDTO.ForgotEnrollment = vm.selectedLogInType === vm.LogInType.PII;
			vm.accountLoginDTO.Session = $cookies.get(Constants.Cookie.Session);

			vm.showEnrollmentNotFoundMessage = false;
			vm.showEnrollmentNotFoundAlternativeMessage = false;
			vm.showMultipleEnrollmentErrorMessage = false;
			StorageService.set(Constants.SK.Captcha, vm.captchaDTO);
			StorageService.set(Constants.SK.AccountLogin, vm.accountLoginDTO);

			// If comes from a looged out event, the air message must be closed.
			$rootScope.$broadcast('close-air-message');

			LoginService.login().then(onLoginSuccess, onLoginError);
		}

		function onLoginSuccess(response) {
			
			StorageService.set(Constants.SK.Application, response.data);
			StorageService.set(Constants.SK.AppToken, response.data && response.data.ApplicationToken);
			
			var stateParams = StorageService.getRemove(Constants.SK.LoginStateParams);

			// If accessed directly with url
			var tab = !stateParams ? Constants.Page.MyAccountDetails : stateParams.tabSelected;

			FlowService.goToMyAccount({ 
				tabSelected: tab, 
				fromLogin: true
			});
		}

		function onLoginError(error) {
			if (error) {	
				vm.submitError = true;
				Utils.scrollTo('form-submit-error');
				
				var errorCode = typeof error === 'string' ? error : error.data.code;
			
				var loginErrors = Constants.Quest.LoginError;
				var errors = Constants.Quest.Error;

				if (errorCode === loginErrors.Invalid_Parameters || errorCode === loginErrors.Invalid_Captcha) {
					vm.reloadCaptcha();
				}

				else if (errorCode === loginErrors.Invalid_Credentials || errorCode == errors.InvalidApplication) {
					if (vm.selectedLogInType === vm.LogInType.Enrollment) {
						vm.showEnrollmentNotFoundMessage = true;
					}
					else {
						vm.showEnrollmentNotFoundAlternativeMessage = true;
					}

					vm.reloadCaptcha();
				}

				else if (errorCode === Constants.Status.MULTIPLE_ID_FOUND || errorCode === loginErrors.ER_MULTIPLE_FOUND) {
					vm.showAditionalFields = true;
					vm.showMultipleEnrollmentErrorMessage = true;
					vm.reloadCaptcha();
				}

				StorageService.remove(Constants.SK.Captcha);
				StorageService.remove(Constants.SK.AccountLogin);
			}
		}

		function setForgotEnrollment(logInType, loginForm) {
			vm.selectedLogInType = logInType;
			StorageService.set(Constants.SK.LandingLogInType, logInType)

			vm.showEnrollmentNotFoundMessage = false;
			vm.showEnrollmentNotFoundAlternativeMessage = false;
			
			if (vm.selectedLogInType === vm.LogInType.PII) {
				vm.showAditionalFields = false;
				vm.showMultipleEnrollmentErrorMessage = false;
			}
			
			Utils.resetForm(loginForm);

			validateUrlParams();
			if(vm.selectedLogInType === vm.LogInType.Enrollment) {
				preLoadEnrollmentNumber();
			}
			vm.reloadCaptcha();
		}

		function cancel() {
			StorageService.remove(Constants.SK.Captcha);
			StorageService.remove(Constants.SK.AccountLogin);

			FlowService.goHome();
		}

		function setPatterns() {
			return {
				enrollment: Constants.Pattern.NUMERIC_ONE,
				zipcode: Constants.Pattern.ZIPCODE,
				name: Constants.Pattern.NAME,
				phone: Constants.Pattern.PHONE,
				lastName: Constants.Pattern.LAST_NAME
			};
		}
        
		function toggleMaskDataDob() {

            vm.maskUserDataDob = !vm.maskUserDataDob;
			vm.maskUserDataValueDob = vm.maskUserDataDob ? 'password' : 'text';
        }

		function validateUrlParams(){
			var tokenParam = Utils.getQueryString('token');
			if(tokenParam) {
				var loginStateParams = { token: tokenParam };
				StorageService.set(Constants.SK.LoginStateParams, loginStateParams);
			}

			var enrParam = Utils.getQueryString('enrollmentNumber');
			if(enrParam) {
				StorageService.set(Constants.SK.LandingEnrollmentNumber, enrParam);
			}
		}
	}
})();
