(function () {
    'use strict';

	angular.module('l4b').directive('proofUpload', ProofUpload);
	ProofUpload.$inject = ['$document', 'DTOFactory', 'Utils', 'GlobalizationService', '$rootScope'];
	
	function ProofUpload($document, DTOFactory, Utils, GlobalizationService, $rootScope) {
		return {
			scope: {
				fileFilters: '=',
				onError: '=',
				maxSize: '=',
				onChangeCallback: '<',
				onErrorCallback: '<'
			},
			require: "ngModel",
			link: function (scope, element, attributes, ngModel) {

				var file = DTOFactory.FileToUpload();
				file.Id = Utils.GUID();

				element.bind("change", function (changeEvent) {
					scope.$apply(function () {
						$rootScope.showSpinner = true;
					});
					
					// Init
					scope.onError = '';
					
					file.FileName = changeEvent.target.files[0].name;
					
					var reader = new FileReader();
					reader.onload = onReaderLoad;
					reader.readAsDataURL(changeEvent.target.files[0]);
				});

				// local utils

				function onReaderLoad (loadEvent) {
					scope.$apply(function () {

						if (!isValidType(loadEvent.target.result)) {
							scope.onError = GlobalizationService.get('ERROR_WTYPE');
							scope.onErrorCallback && scope.onErrorCallback(scope.onError);
							$rootScope.showSpinner = false;
							return;
						}

						var content = loadEvent.target.result.replace(/[^,]+,/, '');

						if (isFileAlreadyLoaded(content)) {								
							scope.onError = GlobalizationService.get('ERROR_DD');
							scope.onErrorCallback && scope.onErrorCallback(scope.onError);
							$rootScope.showSpinner = false;
							return;
						}
						
						if (!isSizeCorrect(loadEvent.loaded)) {
							if (!isPdfType(loadEvent.target.result)) {
								resizeImage(loadEvent.target.result, loadEvent.loaded, getType(loadEvent.target.result))
								.then(function(img){
									var resizedContent = img.replace(/[^,]+,/, '');

									if (isFileAlreadyLoaded(resizedContent)) {	
										scope.$apply(function () {
											scope.onError = GlobalizationService.get('ERROR_DD');
											scope.onErrorCallback && scope.onErrorCallback(scope.onError);
											$rootScope.showSpinner = false;
										});
									} else {
										file.fullContent = img;
										file.ContentType = img.replace(/data:([^;]+);.*/, '$1');
										file.Content = resizedContent;

										scope.$apply(function () {
											ngModel.$setViewValue(file);
											$rootScope.showSpinner = false;
										});
									}
								}).catch(function(){
									scope.$apply(function () {
										scope.onError = GlobalizationService.get('ERROR_MAXSIZE');
										scope.onErrorCallback && scope.onErrorCallback(scope.onError);
										$rootScope.showSpinner = false;
									});
								});
							} else {
									scope.onError = GlobalizationService.get('ERROR_MAXSIZE');
									scope.onErrorCallback && scope.onErrorCallback(scope.onError);
									$rootScope.showSpinner = false;
							}
						} else {
							file.fullContent = loadEvent.target.result;
							file.ContentType = loadEvent.target.result.replace(/data:([^;]+);.*/, '$1');
							file.Content = content;
							ngModel.$setViewValue(file);
							$rootScope.showSpinner = false;
						}
					});
				}

				function isSizeCorrect(fileSize) {
					return fileSize <= scope.maxSize * 1024 * 1024;
				}

				function isFileAlreadyLoaded(content) {
					return file && file.Content === content;
				}
				
				function resizeImage(source) {
					
					return new Promise(function(resolve, reject){
					
						var tmp = new Image();
				        var targetSize = null;
				        var IS_PROGRESSIVE = true;

				        var calculateTargetSize = function () {
				            var scale = 1;
				            var MAX_SIZE_PX = 800;

				            if (tmp.naturalWidth > tmp.naturalHeight) {
				                if (tmp.naturalWidth > MAX_SIZE_PX) {
				                    scale = MAX_SIZE_PX / tmp.naturalWidth;
				                }
				            } else {
				                if (tmp.naturalHeight > MAX_SIZE_PX) {
				                    scale = MAX_SIZE_PX / tmp.naturalHeight;
				                }
				            }

				            return {
				                width: Math.round(tmp.naturalWidth * scale),
				                height: Math.round(tmp.naturalHeight * scale)
				            };
				        };
						
						// The quality of the image tends to degrade badly when downsampling too abruptly.
				        // Hence, we'll shrink the image in steps, halving the image size in each pass.
				        tmp.src = source;
						tmp.onload = function () {
				            if (!targetSize) {
				                targetSize = calculateTargetSize();
				            }

				            var canvas = $document[0].createElement('canvas');

				            var cW = tmp.naturalWidth / 2;
				            var cH = tmp.naturalHeight / 2;

				            if (!IS_PROGRESSIVE || cW < targetSize.width) {
				                cW = targetSize.width;
				            }
				            if (!IS_PROGRESSIVE || cH < targetSize.height) {
				                cH = targetSize.height;
				            }

				            canvas.width = cW;
				            canvas.height = cH;
				            canvas.getContext('2d').drawImage(tmp, 0, 0, cW, cH);

				            if (cW <= targetSize.width || cH <= targetSize.height) {
				                resolve(canvas.toDataURL('image/png'));
				                return;
				            }

				            tmp.src = canvas.toDataURL('image/png');
				        };
						
						tmp.onerror = function(){
							reject(source);
						};
					});
				}

				function isValidType(content) {
					
					var filters = scope.fileFilters.split(',');
					var found = false;
					
					for (var i = 0; i < filters.length && !found; i++) {
						if (content.startsWith('data:' + filters[i])) {
							found = true;
						}
					}
					
					return found;
				}
				
				function isPdfType(content) {
					return content.includes('application/pdf');
				}
				
				function getType(content) {
					var str = content.substring('data:'.length, content.indexOf(';'));
					return str;
				}
			}
		};
	}
})();