(function () {
	'use strict';

	angular.module('l4b')
		.controller('ExitPageController', ExitPageController);

	ExitPageController.$inject = ['$scope', '$rootScope','FlowService', 'Utils', 'StorageService', 'Constants', 'ClientLogService', 'DTOFactory', 'RestService', '$window', 'GlobalizationService', '$timeout'];

	function ExitPageController($scope, $rootScope, FlowService, Utils, StorageService, Constants, ClientLogService, DTOFactory, RestService, $window, GlobalizationService, $timeout) {

		var vm = this,
			error = null;

		angular.extend(vm, {
			$onInit: init,
			goHome: goHome,
			states: null,
			statesLast: null,
			errors: {
				isNladDeceased: false,
				isMinAge: false,
				isAddressBlacklisted: false,
				isPersonBlacklisted: false,
				isUniqueEligible: false,
				isPreviousEnrollmentActive: false,
				isExternalDuplicated: false,
				isAlreadyHasAcp: false,
				isInvalidNatVerAppId: false,
				isTransferLimit: false,
				isBqpOverageForShcoolLunch: false,
				isSubscriberDuplicate: false,
				isNotSupportedZipCode: false,
				isInvalidRatePlan: false,
				isInvalidWirelessNumber: false,
				isEmailDuplicated: false,
				isVerizonForwardRejected: false,
				isNewCustomerVerizonForwardRejected: false,
				isPreviousEnrollmentQualified: false
			},
			isAnotherError: false,
			isVerizon3Flow: false,
			isVerizon3Enabled: false,
			allowRedirectToVerizon: false,
			application:  DTOFactory.Application(),
			goToNatVerPortal: goToNatVerPortal,
			goToVerizon: goToVerizon,
			goToVerizonInternetHome: goToVerizonInternetHome,
			goToMyAccount: goToMyAccount,
			eligibleForTransferDate: DTOFactory.Application().NladApplication.EligibleForTransferDate,
			showHomeButton: false,
			goToUrl: Utils.goToUrl,
			genericVerizonRedirectVZF: null,
			genericVerizonRedirect: null,
			goBack: goBack,
			isVerizonForwardFlow: DTOFactory.Application().BenefitTypeCode == Constants.BenefitType.VZF,
			isVzf3NewCustomerExitPage: false,
		});
		
		function init() {
			var config = StorageService.get(Constants.SK.Configuration);

			vm.genericVerizonRedirectVZF = config.genericVerizonRedirectVZF;
			vm.genericVerizonRedirect = config.genericVerizonRedirect;
			var verizonForwardNewCustomerAllowedBusinessLines = config.verizonForwardNewCustomerAllowedBusinessLines.split(',');
			var accTokenData = StorageService.get(Constants.SK.AccessTokenData);

			vm.isVerizon3Flow = vm.isVerizonForwardFlow && verizonForwardNewCustomerAllowedBusinessLines.includes(vm.application.BusinessLineCode) && !accTokenData.isVerizonManualFlow;
			
			vm.isVerizon3Enabled = verizonForwardNewCustomerAllowedBusinessLines.length > 0 && verizonForwardNewCustomerAllowedBusinessLines[0].trim().length !== 0;
            
            vm.allowRedirectToVerizon = !vm.isVerizonForwardFlow || (vm.isVerizon3Flow && config.allowRedirectOnVZF3);	
			vm.isVzf3NewCustomerExitPage = (vm.isVerizon3Enabled && vm.application.BusinessLineCode == Constants.BusinessLine.FIOS) || config.forceVzForwardFlow;
			validationError();
			resolveError();
			if(errorsArray.isPreviousEnrollmentQualified() && vm.allowRedirectToVerizon) {
				$timeout(function () {
					$rootScope.$broadcast('show-air-message', {
						message: GlobalizationService.get('AIR_MESSAGE_COUNTDOWN_TEXT'),
						showButton: false,
						showCounter: true,
						counterSeconds: config && config.redirectTimeoutInSeconds,
						callback: function () {
							goToVerizonSpecific();
						}
					});
				}, 300);
			}

		}
		
		function validationError() {
			error = StorageService.get(Constants.SK.Error);
		}

		function goHome() {
			FlowService.goHome();
		}

		var errorsArray = {

			isNladDeceased: function () {
				return error && error === Constants.Error.NLAD_DECEASED;
			},

			isMinAge: function () {
				return error && error === Constants.Error.UNDERAGE;
			},

			isEmailDuplicated: function () {
				return error && error === Constants.Error.DUPLICATED_EMAIL;
			},

			isUniqueEligible: function () {
				return error && (error === Constants.Error.DUPLICATED_PERSON_PENDING || error === Constants.Error.DUPLICATED_DEPENDENT_PENDING);
			},

			isPreviousEnrollmentActive: function () {
				return error && (error === Constants.Error.DUPLICATED_PERSON || error === Constants.Error.DUPLICATED_DEPENDENT);
			},

			isPreviousEnrollmentQualified: function () {
				return error && (error === Constants.Error.DUPLICATED_PERSON_QUALIFIED || error === Constants.Error.DUPLICATED_DEPENDENT_QUALIFIED);
			},

			isExternalDuplicated: function () {
				return error && (error === Constants.Error.DUPLICATED_PERSON_EXTERNAL || error === Constants.Error.DUPLICATED_DEPENDENT_EXTERNAL);
			},

			isAlreadyHasAcp: function () {
				return error && error === Constants.Error.ALREADY_HAS_ACP;
			},
			
			isPersonBlacklisted: function () {
				return error && error === Constants.Error.PERSON_BLACKLISTED;
			},

			isAddressBlacklisted: function () {
				return error && error === Constants.Error.ADDRESS_BLACKLISTED;
			},

			isInvalidNatVerAppId: function(){
				return error && error === Constants.Error.INVALID_APPLICATION_ID;
			},

			isTransferLimit: function(){
				return error && error === Constants.Error.TRANSFER_LIMIT;
			},

			isBqpOverageForShcoolLunch: function () {
				return error && error === Constants.Error.BQP_OVERAGE_SCHOOL_LUNCH;
			},

			isSubscriberDuplicate: function() {
				return error && error === Constants.Error.BQP_SUBSCRIBER_DUPLICATE;
			},

			isInvalidRatePlan: function() {
				return error && error === Constants.Error.INVALID_RATE_PLAN;
			},

			isInvalidWirelessNumber: function() {
				return error && error === Constants.Error.INVALID_WIRELESS_NUMBER;
			},

			isVerizonForwardRejected: function() {
				return error && error === Constants.Error.VERIZON_FORWARD_REJECTED;
			},
			
			isNewCustomerVerizonForwardRejected: function() {
				return error && error === Constants.Error.VERIZON_FORWARD_NEW_CUSTOMER_REJECTED;
			},

			isNotSupportedZipCode: function() {
				if (error && error === Constants.Error.INVALID_ZIPCODE) {
					
					RestService.getStates(onGetStatesSuccess);

					var requestedZipCode = StorageService.get(Constants.SK.RequestedZipcode);
					
					if (requestedZipCode && requestedZipCode.NotSupportedMessage) {
						vm.notSupportedMessage = requestedZipCode.NotSupportedMessage[vm.langLogoLink];
					}
					
					return true;
				}
				
				return false;
			}
		};
		
		function goToNatVerPortal() {
			$window.open(Constants.Url.CheckAcp, '_blank');
		}		

		function goToVerizonInternetHome(isPendingDuplicate) {
			var url =  !isPendingDuplicate && vm.isVerizon3Enabled  
					? 'VzInHome' 
					: 'VzInternetHome';

			trackVerizonRedirectEvent(url);
							
			vm.goToUrl(url, '_blank');
		}	

		function goToMyAccount() {
			var app = StorageService.get(Constants.SK.Application);
			StorageService.set(Constants.SK.LandingLogInType, Constants.LogInType.PII);
            FlowService.goToMyAccount({ 
				token: app.AccessToken
			});
		}

		function goToVerizon() {

            var redirectUrl = !vm.application.BenefitTypeCode || vm.isVerizonForwardFlow 
								? vm.genericVerizonRedirectVZF
								: vm.genericVerizonRedirect; 
			
			trackVerizonRedirectEvent(redirectUrl);
			$window.location.href = redirectUrl;
		}

		function goToVerizonSpecific() {
            var event;
			var redirectUrl = vm.application ? vm.application.RedirectUrl : null;
            switch(vm.application.Status.Code) {
                case Constants.Status.QUALIFIED:
                    event = "QUALIFIED_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.PENDING:
                    event= "PENDING_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.REJECTED:
                case Constants.Status.UNQUALIFIED:
                    event = "UNQUALIFIED_REDIRECT_TO_VERIZON";
                    break;
            }

            if(event) {
                RestService.trackApplicationEvent(
                    event,
					redirectUrl
                );
            }

			$window.location.href = redirectUrl;
		}

		function trackVerizonRedirectEvent(redirectUrl) {
			var tokenData = StorageService.get(Constants.SK.AccessTokenData);

			RestService.trackApplicationEvent(
				'EXIT_PAGE_REDIRECT_TO_VERIZON',
				{ RedirectUrl: redirectUrl, CartId: tokenData ? tokenData.cartId : null}
			);
		}

        function onGetStatesSuccess(data) {
            var states = [];
			
			angular.forEach(data, function(item) {
				states.push(item.name);
            });

            vm.statesLast = states.pop();
            vm.states = states.join(', ');
        }		

		function resolveError() {
			var errorKey = null;
			for (var key in vm.errors) {
				vm.errors[key] = errorsArray[key]();
				if (vm.errors[key]) {
					errorKey = key;
					
					break;
				}
			}

			// if no previous error set to true, then set generic error
			vm.isAnotherError = !errorKey;

			vm.showHomeButton = !errorKey 
				|| !['isInvalidWirelessNumber', 'isInvalidNatVerAppId', 'isEmailDuplicated'].includes(errorKey);

			if (!errorKey) {
				ClientLogService.error(null, 'ExitPage displaying an unknown error');
			}			
		}

		function goBack() { 
			FlowService.goToPersonalInformation();
		}
	}
})();
