(function () {
    'use strict';

    angular.module('l4b')
        .filter('trustAs', ['$sce', 
        function($sce) {
            return function (input, type) {
                if (typeof input === "string") {
                    return $sce.trustAs(type || 'html', input);
                }
                console.log("trustAs filter. Error. input isn't a string");
                return "";
            };
        }
    ])
        .component('disclosures', {
            templateUrl: 'html/shared/disclosures/disclosures.html',
            bindings: {
                parentForm: '<',
                disclosures: '<'
            },
            controller: DisclosuresController,
            controllerAs: 'vm'
        });

    DisclosuresController.$inject = ['Constants', 'DTOFactory', 'StorageService'];

    function DisclosuresController(Constants, DTOFactory, StorageService) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            patterns: {}
        });

        function init() {
            var applicant = DTOFactory.Applicant();
            vm.patterns.INITIALS = applicant.InitialsPattern;

            StorageService.remove(Constants.SK.Disclosures);
        }
    }
})();