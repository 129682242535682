(function () {
    'use strict';

    angular.module('l4b')
        .controller('PersonalInformationController', PersonalInformationController);

    function PersonalInformationController($document, $rootScope, Constants, DTOFactory, Utils, StorageService, RestService, FlowService, StepsService, MyAccountService, FeaturesService, $stateParams,
        BrandService, FlowExecutionService, GlobalizationService, GeolocationService, $window) {

        var
            vm = this,
            piiFormRef = null,
            addressFormRef = null;

        angular.extend(vm, {
            $onInit: init,
            patterns: setPattern(),
            isPR: Utils.isPR(),
            application: DTOFactory.Application(),
            captchaDTO: DTOFactory.Captcha(),
            piiCompleted: false,
            nextStep: nextStep,
            toolTipSSN: false,
            toolTipAcpAppID: false,
            toolTipTribal: false,
            isUnderAge: false,
            goHome: FlowService.goHome,
            reloadCaptcha: null,
            showPoBoxMessage: false,
            language: GlobalizationService.getLang(),
            onEmailChange: onEmailChange,
            onPhoneChange: onPhoneChange,
            onAddressChange: onAddressChange,
            isDefault: BrandService.isDefault(),
            brandCode: BrandService.getCurrent().Code,
            showNatVer: false, // FeaturesService.get().AllowNatVerAppId,
            maxLengths: Constants.LengthRestrictions,
            tokenParam: null,
            identificationTypes: ['natVerApplicationId', 'lastSsn'],
            selectedIdentificationType: 'lastSsn',
            isDobDisabled: false,
            goToNatVerPortal: goToNatVerPortal,
            goToMyEnrollment: goToMyEnrollment,
            onIdentificationTypeChanged: onIdentificationTypeChanged,
            addressPrePopulated: false,
            firstNameDisabled: false,
            middleNameDisabled: false,
            lastNameDisabled: false,
            piiLoaded: false
        });

        function init() {

            var config = StorageService.getDefault(Constants.SK.Configuration, null);
            if (!config) {
                RestService
                    .getConfiguration()
                    .then(function (response) {
                        StorageService.set(Constants.SK.Configuration, response.data);
                        initCallback(response.data);
                    })
                return;
            } else {
                initCallback(config);
            }



        }

        function initCallback(config) {
            vm.isAcpWindDownEnabled = config.isAcpWindDownEnabled;
            vm.isVerizonForwardFlow = vm.application.BenefitTypeCode == Constants.BenefitType.VZF || config.forceVzForwardFlow;

            var businessLine = Utils.getQueryString('businessLine');
            if(businessLine) {
                    RestService.initFromScratch(businessLine)
                    .then(function(token){
                        vm.tokenParam = token.data;
                        StorageService.set(Constants.SK.Token, vm.tokenParam);
                        checkActionFromRedirect();
                    });
            } else {
                checkActionFromRedirect();
            }

            FlowExecutionService.logFlowExecutionEvent('SYSTEM_APPLICATION-CREATION_PERSONAL-INFORMATION-STARTED');

            StepsService.initSteps();

            // Particular case when landing from other site
            FlowService.updateCurrentStep();

            vm.showBrailleOption = GlobalizationService.getLang() === Constants.Language.English;
            vm.isExistingCustomer = vm.application && vm.application.CustomerTypeCode == "E";
            vm.addressPrePopulated = StorageService.get(Constants.SK.AddressPrePopulated);
            
            //request geolocation for init-app
            if(config.requestGeolocation) {
                GeolocationService.register(true);
            }

        }

        function nextStep(piiForm, addressForm) {

            if (!vm.piiCompleted) {
                FlowExecutionService.logFlowExecutionEvent('SYSTEM_APPLICATION-CREATION_PERSONAL-INFORMATION-COMPLETED');
                FlowExecutionService.logFlowExecutionEvent('SYSTEM_APPLICATION-CREATION_BILLING-ADDRESS-STARTED');

            }
            else {
                FlowExecutionService.logFlowExecutionEvent('SYSTEM_APPLICATION-CREATION_BILLING-ADDRESS-COMPLETED');
            }

            var formsValid = false;

            if (piiForm) {
                formsValid = Utils.validateForm(piiForm);
                piiFormRef = piiForm;
                if (!formsValid) {
                    return;
                }
            }

            if (!vm.addressPrePopulated && (piiForm || addressForm)) {
                formsValid = Utils.validateForm(piiForm) && Utils.validateForm(addressForm);
                addressFormRef = addressForm;
                if (!formsValid) {
                    return;
                }
            }

            StorageService.set(Constants.SK.Application, vm.application);

            StorageService.set(Constants.SK.Captcha, vm.captchaDTO);

            if (!vm.addressPrePopulated && piiForm && !addressForm) {
                vm.piiCompleted = true;
                RestService.initEnrollment();
                FlowService.updateCurrentStep(Constants.Page.Address);
                Utils.scrollTo('address');
                Utils.setFocus(angular.element($document[0].querySelector('#address')));
                return;
            }

            if (vm.addressPrePopulated) {
                if (!vm.piiCompleted) {
                    RestService.initEnrollment(function () {
                        RestService.initApplication(initEnrollmentSuccess);
                    });
                }
                else {
                    RestService.applyEnrollment(applyEnrollmentSuccess);
                }
            }
            else {
                checkZipCode(
                    function () {
                        var initCallback = function (response) {
                            initEnrollmentSuccess(response);
                            var error = StorageService.get(Constants.SK.Error);

                            if (error) {
                                return;
                            }
                            
                            RestService.applyEnrollment(applyEnrollmentSuccess);
                        }

                        RestService.initApplication(initCallback);

                    },
                    false
                );
            }
        }

        function applyEnrollmentSuccess(data) {

            var error = StorageService.get(Constants.SK.Error);

            if (error) {
                if (validateNonBlockingErrors(error)) {
                    return;
                }

                FlowService.nextStep();
                return;
            }

            if (data && data.addressValidationStatus === Constants.Status.SUGGEST) {
                vm.suggestedAddress = {
                    Line1: data.suggestedAddressLine1,
                    Line2: data.suggestedAddressLine2,
                    City: data.suggestedCity
                };
                return;
            }

            vm.application.Address.IsTribalAddress = data.isTribal;

            vm.application.Plan = data.plan;
            vm.application.DeviceOption = data.deviceOption;
            vm.application.NladTransactionInfo = data.nladTransactionInfo;
            vm.application.NladApplication.UseTransferException = data.useTransferException;
            vm.application.NladApplication.EligibleForTransferDate = data.eligibleForTransferDate;
            vm.application.AppliedUsingNatVerAppId = data.appliedUsingNatVerAppId;
            vm.application.BenefitTypeCode = data.BenefitTypeCode;

            if (!data.useAddressEscalation) {
                vm.application.ShippingAddress = null;
            }

            vm.application.Dependent = null;

            StorageService.set(Constants.SK.Application, vm.application);

            //Set featuresInfo in Session.
            FeaturesService.update(data);

            if (data.token) {
                StorageService.set(Constants.SK.Token, data.token);
            }

            FlowService.nextStep();
        }

        function onEmailChange() {
            setEmailValidity('invalidDomain', true);
            setEmailValidity('invalidEmail', true);
        }

        function onPhoneChange() {
            vm.application.Applicant.PhoneNumber = vm.phoneNumber;
            vm.application.ContactInfo.Phone = vm.phoneNumber;
        }

        function onAddressChange() {
            setAddressValidity('poBoxNotAllowed', true);
        }

        function setEmailValidity(validityError, isValid) {
            if (!isValid) {
                if (piiFormRef && piiFormRef.email) {
                    piiFormRef.email.$setDirty();
                }

                Utils.setFocus(angular.element($document[0].querySelector('#fcc-email')));
                Utils.scrollTo('fcc-email');
            }

            if (piiFormRef && piiFormRef.email) {
                piiFormRef.email.$setValidity(validityError, isValid);
            }
        }

        function setAddressValidity(validityError, isValid) {
            if (!isValid) {
                if (addressFormRef && addressFormRef.address1) {
                    addressFormRef.address1.$setDirty();
                }

                Utils.setFocus(angular.element($document[0].querySelector('#fcc-address-1')));
            }

            if (addressFormRef && addressFormRef.address1) {
                addressFormRef.address1.$setValidity(validityError, isValid);
            }
        }

        function validateNonBlockingErrors(errorCode) {

            var handled = false;

            switch (errorCode) {
                case Constants.Error.CAPTCHA_INVALID:
                    handled = true;
                    vm.reloadCaptcha();
                    break;

                case Constants.Error.EMAIL_DOMAIN_NOT_ALLOWED:
                    handled = true;
                    setEmailValidity('invalidDomain', false);
                    break;

                case Constants.Error.EMAIL_BELONG_AGENT:
                    handled = true;
                    setEmailValidity('invalidEmail', false);
                    break;

                case Constants.Error.PO_BOX_NOT_ALLOWED:
                    handled = true;
                    setAddressValidity('poBoxNotAllowed', false);
                    break;
            }

            return handled;
        }

        function initEnrollmentSuccess(data) {

            var error = StorageService.get(Constants.SK.Error);

            if (error) {
                if (validateNonBlockingErrors(error)) {
                    return;
                }

                FlowService.nextStep();
                return;
            }

            vm.application.ApplicationToken = data.applicationToken;
            vm.application.BenefitTypeCode = data.benefitTypeCode;

            StorageService.set(Constants.SK.Application, vm.application);
            StorageService.set(Constants.SK.Token, data.token || '');

            vm.piiCompleted = true;

            if (vm.addressPrePopulated) {
                vm.nextStep(piiFormRef);
            } else {
                FlowService.updateCurrentStep(Constants.Page.Address);
                Utils.scrollTo('address');
                Utils.setFocus(angular.element($document[0].querySelector('#address')));
            }
        }

        function setPattern() {
            return {
                name: Constants.Pattern.NAME,
                lastName: Constants.Pattern.LAST_NAME,
                email: Constants.Pattern.EMAIL,
                ssn: Constants.Pattern.SSNRegex,
                address: Constants.Pattern.ADDRESS,
                middleName: Constants.Pattern.MIDDLE_NAME,
                city: Constants.Pattern.CITY,
                phone: Constants.Pattern.PHONE,
                zipcode: Constants.Pattern.ZIPCODE,
                suffix: Constants.Pattern.SUFFIX,
                NVID: Constants.Pattern.NVID

            };
        }

        function goToNatVerPortal() {
            $window.open(Constants.Url.CheckAcp, '_blank');
        }

        function onIdentificationTypeChanged() {
            vm.application.Applicant.LastFourSsn = null;
            vm.application.NladApplication.NatVerApplicationId = null;
        }

        function checkActionFromRedirect() {

            var tokenParam = vm.tokenParam || Utils.getQueryString('token');
            if (!tokenParam || tokenParam == '') {
                tokenParam = $stateParams.token;
            }
            
            if (tokenParam) {
                StorageService.removeAllSession();
                //If token provided reset application after remove session
                vm.application = DTOFactory.Application();
                vm.tokenParam = tokenParam;
                RestService
                    .validateAccessToken(tokenParam)
                    .then(function (response) {
                        var error = StorageService.get(Constants.SK.Error) || '';
                        if (hasValidationErrors(error, response.additionalData)) {
                            return;
                        }

                        // Access token has been successfully validated
                        var data = response.data;
                        StorageService.set(Constants.SK.AccessTokenData, data);
                        vm.preLoadedData = true;
                        vm.application.RedirectUrl = data.redirectUrl;
                        vm.application.Applicant.DOB = data.dob;

                        vm.application.Applicant.FirstName = data.firstName;
                        vm.application.Applicant.MiddleName = data.middleName;
                        vm.application.Applicant.LastName = data.lastName;
                        vm.application.CustomerTypeCode = data.customerType;
                        vm.isExistingCustomer = data.customerType == "E";
                        vm.isVerizonForwardFlow = data.isVerizonForwardFlow;
                        vm.isVerizonManualFlow = data.isVerizonManualFlow;
                        vm.application.BenefitTypeCode = data.isVerizonForwardFlow ? Constants.BenefitType.VZF : null;
                        vm.application.BusinessLineCode = data.businessLine;

                        vm.isDobDisabled = data.dob != null && data.dob != "";

                        vm.application.AccessToken = tokenParam;

                        vm.phoneNumber = data.phoneNumber;

                        vm.firstNameDisabled = (vm.isExistingCustomer && ! vm.isVerizonManualFlow) || !isNullOrEmpty(data.firstName);
                        vm.middleNameDisabled = (vm.isExistingCustomer && ! vm.isVerizonManualFlow) || !isNullOrEmpty(data.middleName);
                        vm.lastNameDisabled = (vm.isExistingCustomer && ! vm.isVerizonManualFlow) || !isNullOrEmpty(data.lastName);

                        vm.addressPrePopulated = !isNullOrEmpty(data.address) && !isNullOrEmpty(data.city) && !isNullOrEmpty(data.state) && !isNullOrEmpty(data.zipCode);
                        StorageService.set(Constants.SK.AddressPrePopulated, vm.addressPrePopulated);

                        if (vm.addressPrePopulated) {
                            vm.application.Address.Line1 = data.address;
                            vm.application.Address.Line2 = data.address2;
                            vm.application.Address.City = data.city;
                            vm.application.Address.State = data.state;
                            vm.application.Address.ZipCode = data.zipCode;
                            vm.address = vm.application.Address;
                        }

                        StorageService.set(Constants.SK.Application, vm.application);
                        vm.piiLoaded = true;
                        if (data.zipCode) {
                            vm.application.Address.ZipCode = data.zipCode;
                            StorageService.set(Constants.SK.Captcha, vm.captchaDTO);
                            checkZipCode(null, true);
                        }
                    });
            }
            
            else {
                //If there is no tokenParam check if there is a current application in session
                var accessToken = vm.application ? vm.application.AccessToken : tokenParam;
                if (!accessToken) {
                    FlowService.goToExit();
                } else {
                    vm.address = vm.application.Address;
                }
                vm.piiLoaded = true;
            }
        }

        function isNullOrEmpty(value) {
            return Utils.isNullOrEmpty(value);
        }

        function checkZipCode(callback, forceLogout) {

            return RestService.checkZipCode(function (response) {

                var error = StorageService.get(Constants.SK.Error) || '';
                if (hasValidationErrors(error, response.additionalData)) {
                    return;
                }

                if (forceLogout) {
                    MyAccountService.LogOut({ stayInCurrentPage: true });
                }

                FeaturesService.update(response);

                // Set Address DTO
                vm.application.Address.ZipCode = response.number;
                vm.application.Address.State = response.processName;
                vm.application.Address.StateName = response.stateName;
                vm.application.Address.City = response.citiesFromState && response.citiesFromState[0] ? response.citiesFromState[0].CityName || response.citiesFromState[0].name : null;

                vm.application.Token = response.applicationToken;
                StorageService.set(Constants.SK.Application, vm.application);
                if (callback) {
                    callback();
                }
            }, true);
        }

        function hasValidationErrors(error, additionalData) {

            if (!error) {
                return false;
            }

            switch (error) {
                case Constants.Error.NOT_AUTHENTICATED:
                    $rootScope.$broadcast('show-air-message', {
                        section: Constants.Section.LinkNotAuthenticated,
                        loginUrl: additionalData
                    });

                    break;

                case Constants.Error.INVALID_OR_EXPIRED_ACCESS_TOKEN:
                default:
                    FlowService.goToExit();
            }

            return true;
        }

        function goToMyEnrollment() {
            FlowExecutionService.logFlowExecutionEvent('ALREADY_HAVE_ENROLLMENT_CLICKED');
            StorageService.set(Constants.SK.LandingLogInType, Constants.LogInType.PII);
            FlowService.goToMyAccount({
                token: vm.tokenParam
            });
        }

    }
})();
