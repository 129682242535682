(function () {
    'use strict';

    angular.module('l4b')
        .controller('MyAccountSubmitDocumentationController', MyAccountSubmitDocumentationController);

	MyAccountSubmitDocumentationController.$inject = ['Constants', 'StorageService'];

    function MyAccountSubmitDocumentationController(Constants, StorageService) {
		
		var vm = this;
		
		angular.extend(vm, {
            $onInit: init
        });

        function init() {

            StorageService.set(Constants.SK.UploadProofStep, Constants.UploadProofStep.RequiredDocumentation);
        }
    }
})();
