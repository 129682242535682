(function () {
    'use strict';

    angular.module('l4b')
        .component('suggestAddressBox', {
            templateUrl: 'html/modules/_components/suggest-address-box/suggest-address-box.component.html',
            bindings: {
                address: '<',
                suggestedAddress: '<'
            },
            controller: SuggestAddressBoxController,
            controllerAs: 'vm'
        });

    function SuggestAddressBoxController() {
        var vm = this;

        angular.extend(vm, {
            // Properties
            show: vm.addressSuggest ? true : false,

            // Methods
            accept: accept,
            close: close
        });

        function accept() {
            vm.address.Line1 = vm.suggestedAddress.Line1;
            vm.address.Line2 = vm.suggestedAddress.Line2;
            vm.address.City = vm.suggestedAddress.City;

            close();
        }

        function close() {
            vm.suggestedAddress = null;
        }
    }
})();