(function () {
    'use strict';

    angular
        .module('l4b')
        .directive('modal', Modal);

    function Modal(ModalService) {
        return {
            link: function (scope, element, attrs) {

                var $element = $(element);

                if (!attrs.id) {
                    console.error('modal must have an id');
                    return;
                }

                $element.appendTo('body');

                $element.on('click', 'button[data-dismiss]', function () {
                    scope.$evalAsync(Close);
                });

                if (attrs['out-closest']) {
                    element.on('click', function (e) {
                        var target = $(e.target);
                        if (!target.closest('.modal-dialog').length) {
                            scope.$evalAsync(Close);
                        }
                    });
                }

                var modal = {
                    id: attrs.id,
                    open: Open,
                    close: Close
                };
                ModalService.Add(modal);

                scope.$on('$destroy', function () {
                    ModalService.Remove(attrs.id);
                    $element.remove();
                });

                // open modal
                function Open() {
                    $element.show('fast');
                    $('body').addClass('modal-open');
                    var div = document.createElement('div');
                    div.id = 'overlay-modal';
                    div.classList.add('overlay');
                    document.body.append(div);
                }

                // close modal
                function Close() {
                    $element.hide('fast');
                    $('body').removeClass('modal-open');
                    document.getElementById('overlay-modal').remove();
                }
            }
        };
    }
})();