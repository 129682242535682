(function () {
    'use strict';

    angular.module('l4b')
        .directive('scrollTop', ScrollTop);

	ScrollTop.$inject = ['Utils'];
		
    function ScrollTop(Utils) {
		
		return {
            restrict: 'A',
            link: link
        };
		
		function link(scope, element) {	   

			Utils.setFocus(element);
			Utils.scrollTop();
		}
    }
})();