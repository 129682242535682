(function () {
    'use strict';

    angular.module('l4b')
        .factory('ErrorHandler', ErrorHandler);

    ErrorHandler.$inject = ['StorageService', 'Constants', 'Utils'];

    function ErrorHandler(StorageService, Constants, Utils) {
        var service = {
			setTokenError: setTokenError,
			setCaptchaError: setCaptchaError,
            processError: processError
        };

        var Status = Constants.Status;
        var Errors = Constants.Error;
        var Flows = Constants.Flow;

        return service;
		
        function setTokenError() {
            return processError({
                status: Constants.Status.TOKEN
            });
        }

        function setCaptchaError() {
            return processError({
                status: Constants.Status.CAPTCHA
            });
        }		

        function processError(data) {
            var error;

			var code = data && data.data
				? data.data.code
				: Errors.GENERIC;

            var additionalData = data && data.data && data.data.additionalData;
				
			switch (code) {
				case Status.OK:
					return;

				case Status.TOKEN:
					error = processToken();
					break;

				case Status.TOKEN_LOGIN:
					error = processTokenLogin();
					break;

				case Status.CAPTCHA:
					error = processCaptcha();
					break;

				case Status.ZIPCODE:
					error = processZipCode(data);
					break;

				case Status.SERVICE_DOWN:
					error = processServiceDown();
					break;

				case Status.ACCESS_DENIED:
					error = processAccessDenied(data);
					break;

				case Status.ENROLLMENT:
					error = processEnrollment(data);
					break;

				case Status.SHIPPING_ADDRESS:
					error = processShippingAddress(data);
					break;

				case Status.RE_ENROLL:
					error = processReEnroll(data);
					break;

				case Status.MULTIPLE_ID_FOUND:
					error = processMultipleIDFound(data);
					break;

				case Status.ID_NOT_FOUND:
					error = processIDNotFound(data);
					break;
					
				default:
					error = getError(code);
					break;
			}

			if (error && error.code) {
				StorageService.set(Constants.SK.Error, error.code);
				StorageService.set(Constants.SK.ErrorAdditionalData, additionalData);
			}
        }

        // Local Utils

        function processToken() {
            return getError(Errors.TOKEN);
        }

        function processTokenLogin() {
            return {
                flow: Flows.LOGIN
            };
        }

        function processMultipleIDFound() {
            return getError(Errors.MULTIPLE_ID_FOUND);
        }

        function processIDNotFound() {
            return getError(Errors.ID_NOT_FOUND);
        }

        function processCaptcha() {
            return {
                code: Constants.Error.CAPTCHA_INVALID
            };
        }

        function processZipCode(data) {
            if (data.processName === Status.INVALID) {
                return {
                    flow: Flows.INVALID_ZIPCODE
                };
                //return Pages.INDEX.equals(Utils.getPageName(), true) ?
                //{ flow: Flows.ZIPCODE_INVALID } :
                //{ flow: Flows.ZIPCODE_INVALID_EDITING };
            }
            return getError(Errors.GENERIC);
        }
		
        function processServiceDown() {
			return {
				flow: Flows.SERVICE_DOWN
			};
        }

        function processAccessDenied(data) {
            var login = StorageService.getItem(Constants.SK.Login);
			
			if (login && login.nladMaintenance) return getError(Errors.NLAD_MAINTENANCE);
            if (hasError(data, Errors.RECERTIFY)) return getError(Errors.RECERTIFY);
            if (hasError(data, Errors.HOUSEHOLD_WORKSHEET_DRC)) return getError(Errors.HOUSEHOLD_WORKSHEET_DRC);
            if (hasError(data, Errors.ACCESS_DENIED_SUBMIT)) return getError(Errors.ACCESS_DENIED_SUBMIT);
            if (hasError(data, Errors.ACCESS_DENIED_PLAN)) return getError(Errors.ACCESS_DENIED_PLAN);
            if (hasError(data, Errors.ACCESS_DENIED_EDIT_INFO_SUSPENDED)) {
                // self.title(GB.get('EDIT_INFO_SUSPENDED'));
                return getError(Errors.ACCESS_DENIED_EDIT_INFO_SUSPENDED);
            }
            if (hasError(data, Errors.ACCESS_DENIED_INFO)) return getError(Errors.ACCESS_DENIED_INFO);
        }

        function processEnrollment(data) {
            if (hasError(data, Errors.MIN_AGE)) {
                return getError(Errors.MIN_AGE);
            }

            if (hasError(data, Errors.UNDERAGE)) {
                return getError(Errors.UNDERAGE);
            }

            if (hasError(data, Errors.PERSON_BLACKLISTED)) return { flow: Flows.PersonBlacklisted };         

            if (hasBlockingErrors(data)) return;

            if (hasError(data, Errors.UNIQUE_FULL_SSN)) return getError(Errors.UNIQUE_FULL_SSN);
            if (hasError(data, Errors.BQP_SUBSCRIBER_DUPLICATE)) return getError(Errors.BQP_SUBSCRIBER_DUPLICATE);

            if (hasError(data, Errors.NLAD_TPIV)) return getError(Errors.NLAD_TPIV);
            if (hasError(data, Errors.NLAD_DRC)) return getError(Errors.NLAD_DRC);
            if (hasError(data, Errors.NLAD_TRANFER_REJECTION)) return getError(Errors.NLAD_TRANFER_REJECTION, data.replaceVars);
            if (hasError(data, Errors.NLAD_ENROLL_REJECTION)) return getError(Errors.NLAD_ENROLL_REJECTION, data.replaceVars);
        }

        function processShippingAddress() {
            return {
                flow: Flows.SHIPPING_ADDRESS
            };
        }

        function processReEnroll() {
            return {
                flow: Flows.RE_ENROLL
            };
        }

        function hasBlockingErrors(data) {
            return Utils.hasBlockingNladError(data);
        }

        function getError(code, replaceVars, flow, details) {
            return {
                code: code,
                replaceVars: replaceVars,
                flow: flow,
                details: details
            };
        }

        function hasError(data, error) {
            for (var e in (data.errors || {})) {

                if (e.indexOf(error) !== -1) {

					return true;
                }
            }
            return false;
        }
    }
})();
