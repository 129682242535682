(function() {
    'use strict';

    angular.module('l4b')
        .component('stepsFlowLine', {
            templateUrl: 'html/stepsFlowLine/stepsFlowLine.html',
            controller: StepsFlowLineController,
            controllerAs: 'vm'
        });

    StepsFlowLineController.$inject = ['StepsService', 'FlowService', 'Constants'];

    function StepsFlowLineController(StepsService, FlowService, Constants) {

        var vm = this;

        angular.extend(vm, {
            steps: StepsService.steps,
            show: show
		});
		
		function show() {
            var lastStep = vm.steps[vm.steps.length - 1];

			var currentState = FlowService.getCurrentState();

			return !lastStep
				|| (currentState !== Constants.Page.ApplicationCompleted && currentState !== Constants.Page.About);
		}
    }
})();