(function () {
	'use strict';

	angular.module('l4b')
		.controller('SubmitProofController', SubmitProofController);

	SubmitProofController.$inject = ['$window', 'Constants', 'Utils', 'StorageService', 'FlowService', 'RestService', 'GlobalizationService', '$timeout'];

	function SubmitProofController($window, Constants, Utils, StorageService, FlowService, RestService, GlobalizationService, $timeout) {

		var 
			vm = this,
			application = StorageService.get(Constants.SK.Enrollment);

		angular.extend(vm, {
			$onInit: init,
			enrollmentNumber: '',
			options: {},
			nextStep: nextStep,
			nextStepAfterUpload: false,
			provideLater: provideLater,
			showUploadProof: true,
			filesToUpload: [],
			removeFile: removeFile,
			uploadResponseMessage: '',
			uploadResponseClass: '',
			lang: GlobalizationService.getLang(),
			uploadProofs: uploadProofs,
			uploadResponse: false
		});

		function init() {

			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_REQUIERED_DOCUMENTATION_STATUS_PRESENTATION_STARTED);
			
			vm.enrollmentNumber = application.enrollmentNumber;
		}

		function uploadProofs() {		
			var files = [];

			files = Utils.filter(vm.filesToUpload, function (file) {
				return !file.Submitted;
			});

			if (files.length == 0) {
				return;
			}
			
			vm.uploadResponse = false;
			
 			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_REQUIERED_DOCUMENTATION_STATUS_PROOF_SUBMITTED);
			
			var parsedFileObj = {
				MainImage: files[0].Content,
				ContentType: files[0].ContentType,
				PreclassifiedRequirementCodes: [Constants.Requirement.IncomeProof.Code]
			};
			
			RestService.submitProof(parsedFileObj, application.applicationToken, onSuccess, onError);
		}

		function onSuccess() {
			
 			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_SUCCESFULLY_SUBMITTED_PRESENTATION_STARTED);
			
			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_PROVIDED);
			
			angular.forEach(vm.filesToUpload, function (file) {
				file.Submitted = true;
			});

			vm.uploadResponseClass = 'txt-qualified';
			vm.uploadResponseMessage = GlobalizationService.get('UPLOAD_RESPONSE_SUCCESS_MESSAGE');

			vm.uploadResponse = true;
			$timeout(function () {

				vm.uploadResponse = false;
			}, 10 * 1000);

			vm.nextStep();
		}

		function onError(data) {
			vm.fileError = data.status;
			
			vm.uploadResponseClass = 'txt-error';
			vm.uploadResponseMessage = data && data.data && data.data.ExceptionMessage
				? GlobalizationService.get(data.data.ExceptionMessage)
				: GlobalizationService.get('UPLOAD_RESPONSE_ERROR_MESSAGE');

			vm.uploadResponse = true;
			$timeout(function () {

				vm.uploadResponse = false;
			}, 10 * 1000);
		}

		function removeFile(id) {
			var index = -1;
			for (var i = 0; i < vm.filesToUpload.length && index == -1; i++) {
				if (vm.filesToUpload[i].Id == id) {
					index = i;
				}
			}

			if (index > -1) {
				vm.filesToUpload.splice(index, 1);
			}

			if (vm.filesToUpload.length <= 10 && vm.fileError === GlobalizationService.get('ERROR_MAXFILES'))
				vm.fileError = '';
		}

		function provideLater(val) {
			
			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_NOT_PROVIDED);
			
			vm.showUploadProof = !val;
		}

		function nextStep() {
			vm.refreshParent()({value: true});
		}
	}
})();
