(function () {
    'use strict';

    angular.module('l4b')
        .factory('GeolocationService', GeolocationService);

    GeolocationService.$inject = ['$q', 'DTOFactory', 'StorageService', 'Constants', 'Utils'];

    function GeolocationService($q, DTOFactory, StorageService, Constants, Utils) {

        var service = {
            register: resolveCurrentPosition
        };

		var options = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0
		};
		
		function isTelesales() {
			var data = Utils.getQueryString('data', true);
			return !!data;
		}

        function resolveCurrentPosition(force) {
			
            var q = $q.defer();
			
			StorageService.remove(Constants.SK.Geolocation);

			if(isTelesales() || force) {
				navigator.geolocation.getCurrentPosition(function (pos) {

					var geolocation = DTOFactory.Geolocation();
					geolocation.Latitude = pos.coords.latitude;
					geolocation.Longitude = pos.coords.longitude;

					StorageService.set(Constants.SK.Geolocation, geolocation);

					q.resolve();

				}, function (err) {
					q.reject(err);
				}, options);
			} else {
				q.resolve();
			}	


            return q.promise;
        }
		
		return service;
    }
})();