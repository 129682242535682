(function () {
    'use strict';

    angular.module('l4b')
        .factory('DTOFactory', DTOFactory);

    DTOFactory.$inject = ['Constants', 'StorageService', 'Entities', 'GlobalizationService'];

    function DTOFactory(Constants, StorageService, Entities, GlobalizationService) {

        var service = {
            Application: Application,
            Lang: Lang,
            Address: Address,
            Captcha: Captcha,
            Dependent: Dependent,
            Applicant: Applicant,
            Qualification: Qualification,
            AddressValidation: AddressValidation,
            Plan: Plan,
            Geolocation: Geolocation,
            ShippingAddress: ShippingAddress,
            AccountLogin: AccountLogin,
            UpdateAddress: UpdateAddress,
            UpdateContact: UpdateContact,
            RequestedZipcode: RequestedZipcode,
            NladErrorMessages: NladErrorMessages,
            FileToUpload: FileToUpload,
			NladTransactionInfo: NladTransactionInfo,
			NatVerApiTransactionInfo: NatVerApiTransactionInfo,
			Requirement: Requirement
        };

        return service;

        function Application() {
            var application = StorageService.get(Constants.SK.Application);
				
			if (application) {
				return application;
			}
			
			return {
				ApplicationId: null,
				EnrollmentNumber: null,
				Plan: null,
				Status: {
					Code: null,
					Name: null
				},
                DeviceOption: null,
                BenefitTypeCode: null,
				StatusChanges: null,
				PhoneNumber: null,
				ApplicationToken: null,
				AccessToken: null,
				UserAccountId: null,
				ContactInfo: {
					ExistingPhoneNumber: null,
					ContactMechanismType: null,
					ExistingImeiNumber: null
				},
				NladTransactionInfo: null,
				Requirements: null,
				NladApplication: {
					NatVerApplicationId: null,
					UseTransferException: null,
					EligibleForTransferDate: null
				},
				NatVerApiTransactionInfo: null,
				AppliedUsingNatVerAppId: null,
				Applicant: Applicant(true),
				Address: Address(true),
				ShippingAddress: null,
				Dependent: null,
				Recertification: null
            };
        }

        function FileToUpload() {
            return {
                Id: '',
                FileName: '',
                Content: '',
                ContentType: '',
                Submitted: false
            };
        }
        
        function Lang() {
            return GlobalizationService.getLang();
        }

        function NladTransactionInfo() {
            return {
				NladFailureReason: null,
				IsNladSuccessfulTransaction: null
			};
        }

        function Requirement() {
            return {
				Code: null,
				Name: null,
				Status: {
					Code: null,
					Name: null
				},
				IsApproved: null,
				IsProofRequired: null,
				IsSubmitted: null
			};
        }

        function Applicant(isInitialization) {
			
			if (isInitialization) {
				return Entities.PII();
			}

            var value = StorageService.get(Constants.SK.Application);

			if (!value) {
				return null;
			}

			return value.Applicant;
        }

        function Dependent(isInitialization) {
			
			if (isInitialization) {
				return Entities.PII();
			}
			
            var value = StorageService.get(Constants.SK.Application);

			if (!value) {
				return null;
			}
			
			return value.Dependent;
        }


        function Captcha() {
            var defaultObj = {
                Token: ''
            };
            return StorageService.getDefault(Constants.SK.Captcha, defaultObj);
        }

        function Address(isInitialization) {

			if (isInitialization) {
				return Entities.Address();
			}

            var value = StorageService.get(Constants.SK.Application);

			if (!value) {
				return null;
			}
			
			return value.Address;
        }

        function ShippingAddress(isInitialization) {

			if (isInitialization) {
				return Entities.ShippingAddress();
			}
			
            var value = StorageService.get(Constants.SK.Application);
			
			if (!value) {
				return null;
			}
			
			return value.ShippingAddress;
        }
		

        function NatVerApiTransactionInfo() {
            
            var value = StorageService.get(Constants.SK.Application);

			if (!value) {
				return null;
			}
			
			return value.NatVerApiTransactionInfo;			
        }		

        function RequestedZipcode() {
            var defaultObj = {
                ZipCode: '',
                State: '',
                HasCoverage: false,
                NotSupportedMessage: {}
            };

            return StorageService.getDefault(Constants.SK.RequestedZipcode, defaultObj);
        }

        function Qualification() {
            var defaultObj = {
                Type: null,
                Programs: [],
                Income: {
                    Option: null,
                    //Florida fields
                    Fields: {
                        AmountPeople: null,
                        GrossBelowLimitAcp: null,
                        GrossBelowLimitLl: null
                    }
                },
                UseDependent: false
            };
            return StorageService.getDefault(Constants.SK.Qualification, defaultObj);
        }

        function AddressValidation() {
            var defaultObj = {
                Errors: {},
                IsValid: false
            };
            return StorageService.getDefault(Constants.SK.AddressValidation, defaultObj);
        }

        function Plan() {
            var defaultObj = {
                Caption: '',
                CarryOver: false,
                Category: '',
                CategoryPlanId: 0,
                Data: '',
                Description: '',
                Name: '',
                PlanId: 0,
                ServiceType: '',
                ServiceTypeId: 0,
                Texts: '',
                VoiceMinutes: ''
            };

            return StorageService.getDefault(Constants.SK.Plan, defaultObj);
        }

        function AccountLogin() {
            var defaultObj = {
                ExternalApplicationId: null,
                ZipCode: null,
                Dob: null,
                Lang: GlobalizationService.getLang(),
                LastName: null,
                PhoneNumber: null,
                Session: null
            };

            return StorageService.getDefault(Constants.SK.AccountLogin, defaultObj);
        }

        function UpdateAddress() {
            var defaultObj = {
                Address: Address(),
                ShippingAddress: Address()
            };
            return StorageService.getDefault(Constants.SK.UpdateAddress, defaultObj);
        }

        function UpdateContact() {
            var defaultObj = {
                Phone: null,
                Email: null,
                IsSmatphone: null,
                IsContactAllowed: null
            };
            return StorageService.getDefault(Constants.SK.UpdateContact, defaultObj);
        }

        function NladErrorMessages() {
            var defaultObj = {
                DECEASED: false
            };

            return StorageService.getDefault(Constants.SK.NladErrors, defaultObj);
        }

        function Geolocation() {
            var defaultObj = {
                Latitude: null,
                Longitude: null
            };

            return StorageService.getDefault(Constants.SK.Geolocation, defaultObj);
        }
    }
})();
