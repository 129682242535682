(function() {
    'use strict';

    angular.module('l4b')
        .factory('Entities', Entities);

	function Entities() {
		
		var service = {
			Address: Address,
			ShippingAddress: ShippingAddress,
			PII: PII
		};

        return service;
        
		function Address() {
            return {
                Line1: null,
                Line2: null,
                ZipCode: null,
                City: null,
                State: null,
				StateName: null,
                IsTemporary: false,
				IsPoBox: false,
				EligibleTribalLands: false
            };
        }
		
		function ShippingAddress() {
            return Address();
        }		
		
		function PII() {
			return {
				FirstName: '',
				MiddleName: '',
				LastName: '',
				LastFourSsn: '',
				DOB: '',
				Suffix: null,
				Email: '',
				PhoneNumber: ''
			};
		}
	}
})();
