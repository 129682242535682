(function () {
    'use strict';

    angular.module('l4b')
    .controller('ErrorController', ErrorController);

    ErrorController.$inject = ['FlowService'];

    function ErrorController(FlowService) {

        var vm = this;

        angular.extend(vm, {
            goHome: goHome
        });

        function goHome() {
            FlowService.goHome();
        }
    }
})();