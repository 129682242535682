(function () {
    'use strict';

	angular.module('l4b').directive('fileUpload', FileUpload);
	FileUpload.$inject = ['$document', 'DTOFactory', 'Utils', 'GlobalizationService', '$rootScope'];
	
	function FileUpload($document, DTOFactory, Utils, GlobalizationService, $rootScope) {
		return {
			scope: {
				files: '=',
				fileFilters: '=',
				onError: '=',
				maxSize: '=',
				maxFiles: '='
			},
			link: function (scope, element) {
				element.bind("change", function (changeEvent) {

					if(changeEvent.target.files.length == 0) {
						return;
					}

					scope.$apply(function () {
						$rootScope.showSpinner = true;
					});

					function isSizeCorrect(fileSize) {
						return fileSize <= scope.maxSize * 1024 * 1024;
					}

					function isValidFileCount() {
						return scope.files.length < scope.maxFiles;
					}

					function isFileAlreadyLoaded(content) {
						var found = false;
						
						for (var i = 0; i < scope.files.length && !found; i++) {
							if (scope.files[i].Content == content) {
								found = true;
							}
						}
						
						return found;
					}
					
					function resizeImage(source) {
						
						return new Promise(function(resolve, reject){
						
							var img = new Image();
							
							img.onload = function(){
								var factor = 1;
								var encodedImage = null;
								var sizeInBytes = 0;

								do 
								{
									encodedImage = shrinkImage(img, factor);
									sizeInBytes = getImageSizeInBytes(encodedImage);
									factor *= 0.8;
								}
								while(sizeInBytes > scope.maxSize * 1024 * 1024);
									
								resolve(encodedImage);
							};
							
							img.onerror = function(){
								reject(source);
							};
							
							img.src = source;
						});
					}

					function getImageSizeInBytes(imageBase64) {
						return imageBase64.replace(/[^,]+,/, '').length * 0.75;
					}

					function shrinkImage(image, scaleFactor) {
						var canvas = $document[0].createElement("canvas");
						var newWidth = Math.round(image.width * scaleFactor);
						var newHeight = Math.round(image.height * scaleFactor);

						canvas.width = newWidth;
						canvas.height = newHeight;

						var ctx = canvas.getContext("2d");

						ctx.scale(scaleFactor, scaleFactor);	
						ctx.drawImage(image, 0, 0);		

						return canvas.toDataURL("image/jpeg");
					}

					function isValidType(content) {
						
						var filters = scope.fileFilters.split(',');
						var found = false;
						
						for (var i = 0; i < filters.length && !found; i++) {
							if (content.startsWith('data:' + filters[i])) {
								found = true;
							}
						}
						
						return found;
					}
					
					function isPdfType(content) {
						return content.includes('application/pdf');
					}
					
					function getType(content) {
						var str = content.substring('data:'.length, content.indexOf(';'));
						return str;
					}
					
					// Init
					scope.onError = [];
					
					if (!isValidFileCount()) {
						$rootScope.showSpinner = false;
						scope.onError.push(GlobalizationService.get('ERROR_MAXFILES'));
						return;
					}
					
					angular.forEach(changeEvent.target.files, function (currentFile) {
						var file = DTOFactory.FileToUpload();
						file.Id = Utils.GUID();

						//Opened window but cancelled operation
						if(changeEvent.target.files.length < 1){
							scope.$apply(function () {
								$rootScope.showSpinner = false;
							});	
							return;
						}

						file.FileName = currentFile.name;
						
						var reader = new FileReader();
						reader.onload = function (loadEvent) {
							scope.$apply(function () {

								if (!isValidType(loadEvent.target.result)) {
									scope.onError.push(file.FileName + ':' + GlobalizationService.get('ERROR_WTYPE'));
									return;
								}

								var content = loadEvent.target.result.replace(/[^,]+,/, '');

								if (isFileAlreadyLoaded(content)) {								
									scope.onError.push(file.FileName + ':' + GlobalizationService.get('ERROR_DD'));
									return;
								}
								
								if (!isSizeCorrect(loadEvent.loaded)) {
									if (!isPdfType(loadEvent.target.result)) {
										resizeImage(loadEvent.target.result, loadEvent.loaded, getType(loadEvent.target.result))
										.then(function(img){
											var resizedContent = img.replace(/[^,]+,/, '');

											if (isFileAlreadyLoaded(resizedContent)) {	
												scope.$apply(function () {
													scope.onError.push(file.FileName + ':' + GlobalizationService.get('ERROR_DD'));
													$rootScope.showSpinner = false;
												});
											} else {
												file.ContentType = img.replace(/data:([^;]+);.*/, '$1');
												file.Content = resizedContent;
												scope.$apply(function () {
													scope.files.push(file);
													$rootScope.showSpinner = false;
												});
											}
										}).catch(function(){
											scope.$apply(function () {
												scope.onError.push(file.FileName + ':' + GlobalizationService.get('ERROR_MAXSIZE'));
												$rootScope.showSpinner = false;
											});
										});
									} else {
										scope.onError.push(file.FileName + ':' + GlobalizationService.get('ERROR_WTYPE'));
										$rootScope.showSpinner = false;
									}
								} else {
								
									file.ContentType = loadEvent.target.result.replace(/data:([^;]+);.*/, '$1');
									file.Content = content;
									
									scope.files.push(file);
									$rootScope.showSpinner = false;
								}
							});
						};
						reader.readAsDataURL(currentFile);
					});
					scope.$apply(function () {
						$rootScope.showSpinner = false;
					});	
				});
			}
		};
	}
})();
