(function () {
    'use strict';

    angular.module('l4b')
        .config(RecaptchaConfig);

    function RecaptchaConfig(Constants, vcRecaptchaServiceProvider) {
        vcRecaptchaServiceProvider.setSiteKey(Constants.Google.Recaptcha.SiteKey);
    }
})();
