(function () {
    'use strict';

    angular.module('l4b')
        .factory('ZipCodeAuthProvider', ZipCodeAuthProvider);

    function ZipCodeAuthProvider(FlowService, StorageService, Constants) {

        return {
            authorize: authorize
        };

        function authorize(toState) {
            var currentState = toState.name;
            var requestedZipcode = StorageService.get(Constants.SK.RequestedZipcode);
            var coverage = StorageService.get(Constants.SK.NoCoverageData);
            var zipcode = requestedZipcode ? requestedZipcode.ZipCode : null;

            if (!coverage && !zipcode && currentState != Constants.Page.Home) {
                FlowService.goToZipCode({ previous: currentState });
                return false;
            }

            return true;
        }
    }
})();
