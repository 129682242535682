(function () {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentationAdditional', {
            templateUrl: 'html/shared/requiredDocumentationAdditional/requiredDocumentationAdditional.html',
 			bindings: {
				nextStep: '&'
			},			
			controller: RequiredDocumentationAdditionalController,
			controllerAs: 'vm'
        });

    RequiredDocumentationAdditionalController.$inject = ['$scope', 'Constants', 'GuidedProofService'];

    function RequiredDocumentationAdditionalController($scope, Constants, GuidedProofService) {
        var vm = this;

        angular.extend(vm, {
			$onInit: init,
            proof: null,
            anotherFile: anotherFile
        });
		
		function init() {
			vm.proofData = GuidedProofService.GetCurrentProofData();
		}			
		
		function anotherFile() {
			
			GuidedProofService.RequestAnotherFile();
			
			vm.nextStep({ options: { askAdditionalFile: true }});
		}
    }

})();