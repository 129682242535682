(function () {
    'use strict';

    angular.module('l4b').config(MyAccountRouting);

    function MyAccountRouting($stateProvider, Constants) {

        $stateProvider

            .state('myAccount', {
                url: '',
                templateUrl: 'html/main/main.html',
                abstract: true,
                authorizations: [Constants.AuthProviders.MY_ACCOUNT]
            })

            .state(Constants.Page.MyAccount, {
                url: '/myAccount',
                templateUrl: 'html/modules/myAccount/container/myAccount.html',
                controller: 'MyAccountController',
                controllerAs: 'vm',
                params: {
                    ebbTooltip: null,
                    tabSelected: null,
                    zipcodeLogin: null,
                    fromLogin: null,
                    hash: null,
                    token: null
                }
            })

            .state(Constants.Page.MyAccountDetails, {
                url: '/details',
                templateUrl: 'html/modules/myAccount/details/myAccountDetails.html',
                controller: 'MyAccountDetailsController',
                controllerAs: 'vm',
                params: {
                    messageCode: null,
                    messageCodeFrom: null
                }
            })
			
            .state(Constants.Page.MyAccountUpdate, {
                url: '/update',
                templateUrl: 'html/modules/myAccount/update/myAccountUpdate.html',
                controller: 'MyAccountUpdateController',
                controllerAs: 'vm'
            })
            
            .state(Constants.Page.MyAccountSubmitDocumentation, {
                url: '/submitDocumentation',
                templateUrl: 'html/modules/myAccount/submitDocumentation/myAccountSubmitDocumentation.html',
                controller: 'MyAccountSubmitDocumentationController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.MyAccountMessage, {
                url: '/message',
                templateUrl: 'html/modules/myAccount/message/myAccountMessage.html',
                controller: 'MyAccountMessageController',
                params: {
                    tab: null,
                    messageKey: null
                },
                controllerAs: 'vm'
            })

            .state(Constants.Page.ServiceCancellationMessage, {
                url: '/cancelMessage',
                templateUrl: 'html/modules/myAccount/message/myAccountMessage.html',
                controller: 'MyAccountMessageController',
                params: {
                    returnPage: null,
                    messageKey: null
                },
                controllerAs: 'vm'
            })

            .state(Constants.Page.MyAccountInvalidAddress, {
                url: '/invalidAddressMyAccount',
                templateUrl: 'html/modules/myAccount/invalidAddress/myAccountInvalidAddress.html',
                controller: 'InvalidAddressMyAccountController',
                params: {
                    poBox: false,
                    msg: null,
                    updateAddressError: null,
                    suggestInfo: null,
                    from: null
                },
                controllerAs: 'vm'
            })

            .state(Constants.Page.MyAccountShippingAddress, {
                url: '/shippingAddressMyAccount',
                templateUrl: 'html/modules/myAccount/shippingAddress/myAccountShippingAddress.html',
                controller: 'ShippingAddressMyAccountController',
                controllerAs: 'vm',
                params: {
                    updateAddressError: null
                }
            })

            .state(Constants.Page.MyAccountExit, {
                url: '/myAccountExit',
                templateUrl: 'html/exitPage/exitPage.html',
                controller: 'ExitPageController',
                controllerAs: 'vm'
            })

            .state(Constants.Page.ServiceCancellation, {
                url: '/cancelService',
                templateUrl: 'html/modules/myAccount/serviceCancellation/serviceCancellation.html',
                controller: 'ServiceCancellationController',
                controllerAs: 'vm'
            });
    }
})();
