(function () {
	'use strict';

	angular.module('l4b')
		.controller('MyAccountUpdateController', MyAccountUpdateController);

	MyAccountUpdateController.$inject = ['StorageService', 'DTOFactory', 'Constants', 'MyAccountService', 'Utils'];

	function MyAccountUpdateController(StorageService, DTOFactory, Constants, MyAccountService, Utils) {
		var vm = this,
			application = DTOFactory.Application();
			
		angular.extend(vm, {
			$onInit: init,
			patterns: setPatterns(),
			piiDto: DTOFactory.Applicant(),
			addressDto: DTOFactory.Address(),
			shippingAddressDto: DTOFactory.ShippingAddress(),
			saveChanges: saveChanges,
			goBack: goBack,
			showMainAddress: true,
			showShippingAddress: false,
			suggestionActiveAddress: false,
			suggestionActiveShipping: false,
			showSameMainAndShippingError: false,
			showNoChangesMessage: false,
			isTribal: false,
			onUseMainAsShippingChange: onUseMainAsShippingChange
		});

		function init() {

			// Initialize shipping
			if (!application.ShippingAddress) {
				var shippingAddressDto = angular.copy(vm.addressDto);
				shippingAddressDto.Line1 = '';
				shippingAddressDto.Line2 = '';
				
				vm.shippingAddressDto = shippingAddressDto;
			}
			
			vm.useMainAsShipping = !application.ShippingAddress;
			
			vm.isTribal = application.Address.EligibleTribalLands;
			
			onUseMainAsShippingChange();			
		}

		function saveChanges(addressForm, shippingAddressForm, contactInfoForm) {
			var isShippingAddressValid = !vm.showShippingAddress 
				|| (
					Utils.validateForm(shippingAddressForm) 
					&& !vm.showSameMainAndShippingError
				);
			
			if (!Utils.validateForm(addressForm) || !isShippingAddressValid || !Utils.validateForm(contactInfoForm)) {
				return;
			}

			var updatedAddressDto = {
				Address: vm.addressDto,
				ShippingAddress: vm.useMainAsShipping ? null : vm.shippingAddressDto
			};

			StorageService.set(Constants.SK.UpdateAddress, updatedAddressDto);
			StorageService.set(Constants.SK.UpdateContact, vm.piiDto);
			
			MyAccountService
				.UpdateInfo()
				.then(onSuccessUpdateInfo);
		}

		function onSuccessUpdateInfo(response) {
            var error = StorageService.get(Constants.SK.Error);
            if (error) {
				validateError(error);

				return;
            }
			
			if (response.action == Constants.UpdateStatus.None) {
				vm.showNoChangesMessage = true;

				return;
			}

            if (response.SuggestedAddress) {
                vm.suggestedAddress = {
                    Line1: response.SuggestedAddress.Line1,
                    Line2: response.SuggestedAddress.Line2,
                    City: response.SuggestedAddress.City
                };
                return;
            }
			
        	MyAccountService.GoTo(Constants.Page.MyAccountMessage, {
				messageKey: response.action
        	});
		}
		
		function validateError(error) {

			if (error == Constants.Error.INVALID_ADDRESS) {
				MyAccountService.GoTo(Constants.Page.MyAccountInvalidAddress);
			}
			
			if (error == Constants.Error.INVALID_SHIPPING_ADDRESS) {
				MyAccountService.GoTo(Constants.Page.MyAccountShippingAddress);
			}

			if (error == Constants.Error.MAIN_AND_SHIPPING_ARE_EQUAL) {
				vm.showSameMainAndShippingError = true;
			}
		}

		function goBack() {
			MyAccountService.GoTo(Constants.Page.MyAccountDetails);
		}

		function setPatterns() {
			return {
				email: Constants.Pattern.EMAIL
			};
		}
		
		function onUseMainAsShippingChange() {
			vm.showShippingAddress = !vm.useMainAsShipping;
		}
	}
})();
