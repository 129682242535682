(function () {
	'use strict';

	angular.module('l4b').controller('NatVerLandingController', NatVerLandingController);

	NatVerLandingController.$inject = ['$state', 'Constants', 'MyAccountService', 'StorageService', 'Utils', 'FlowService', '$window', 'RestService', '$stateParams', '$location', 'GlobalizationService', 'DTOFactory'];

	function NatVerLandingController($state, Constants, MyAccountService, StorageService, Utils, FlowService, $window, RestService, $stateParams, $location, GlobalizationService, DTOFactory) {

		var vm = this,
		application = DTOFactory.Application();

		angular.extend(vm, {
			$onInit: init,
			goHome: goHome,
			loaded: false,
			isSuccess: false,
		});

		function init() {
			var encryptedEnrollmentNumber = $stateParams.data;
			
			RestService.natVerApiStatusCheck(encryptedEnrollmentNumber, GlobalizationService.getLang(), OnSuccessElegibilityCheck);
		}

		function OnSuccessElegibilityCheck(data) {

			application.NatVerApiTransactionInfo = data;
			application.RedirectUrl = data.redirectUrl;
			application.Status = {
						Code: data.applicationStatus.code, 
						Name: data.applicationStatus.name
					};
			
			StorageService.set(Constants.SK.Application, application);
			vm.enrollmentNumber = data.enrollmentNumber;
			vm.loaded = true;
			vm.isPending = data.applicationStatus.code == Constants.Status.PENDING;
			var apiValues = Utils.getNatVerApiValues();

			var eventCode = '';
			var natVerErrors = apiValues.errorResponse 
							|| apiValues.pendingCertification 
							|| apiValues.pendingEligibility 
							|| apiValues.pendingResolution 
							|| apiValues.pendingReview;
		
			
			vm.isSuccess = !natVerErrors;

			if (apiValues) {
				if (apiValues.complete) {
					
					eventCode = Constants.Event.NatVerApi.NATVER_RETURN_APP_QUALIFIED;
					
				} 
				else if (apiValues.pendingProofApproval) {
					
					eventCode = Constants.Event.NatVerApi.NATVER_RETURN_APP_PENDING_REVIEW;
					
				} 
				else if (apiValues.pendingProof) {
					
					eventCode = Constants.Event.NatVerApi.NATVER_RETURN_APP_PENDING_RESOLUTION;
					
				}  
				else if (apiValues.errorResponse) {
					
					eventCode = Constants.Event.NatVerApi.NATVER_REDIRECT_API_ERROR;
				}
			}
			
			if (eventCode) {
				StorageService.set(Constants.SK.AppToken, data.applicationToken);
				
				RestService.trackApplicationEvent(Constants.Event.NatVerApi.NATVER_RETURN_LANDING_PAGE_PRESENTED);
				
				RestService.trackApplicationEvent(eventCode);
			}
			

		}


		function goHome() {
			FlowService.goHome();
		}
	}
})();
