(function () {
	'use strict';

	angular.module('l4b')
		.component('enrollmentFooter', {
			templateUrl: 'html/shared/footer/enrollmentFooter.html',
			controller: FooterController,
			controllerAs: 'vm'
		});

	FooterController.$inject = ['$scope', 'Constants', 'Utils', 'FlowService', '$window', '$state', '$transitions', 'StorageService'];

	function FooterController($scope, Constants, Utils, FlowService, $window, $state, $transitions, StorageService)
	{
		var vm = this;

		angular.extend(vm, {
			$onInit: init,
			isCreationFlow: false,
			isHomePage: false,
			isCA: false,
			goToPage: goToPage,
			goToUrl: goToUrl,
			year: (new Date()).getFullYear(),

			changeOption: changeOption,
			setAnchorTabIndex: setAnchorTabIndex
		});

		function init(){
			$transitions.onSuccess({}, checkPageTransition);

			vm.options = {
				footerSupport: false,
				footerAboutVz: false,
				footerCustomerAssist: false
			};

			checkPageTransition();
		}

		function changeOption(option) {
			vm.options.footerSupport = option == 'footerSupport' ? !vm.options.footerSupport : false;
			vm.options.footerAboutVz = option == 'footerAboutVz' ? !vm.options.footerAboutVz : false;
			vm.options.footerCustomerAssist = option == 'footerCustomerAssist' ? !vm.options.footerCustomerAssist : false;
		}

		function setAnchorTabIndex($event) {
			var collection;
			var firstCheck = Array.from($event.currentTarget.parentElement.getElementsByTagName('a'));
			firstCheck.map(function (z) { z.setAttribute('tabindex', -1); });
			$event.currentTarget.hasChildNodes() ? collection = Array.from($event.currentTarget.getElementsByTagName('a')) : false;
			$event.currentTarget.getAttribute("aria-expanded") == 'false'
				? collection.map(function (x) { x.setAttribute('tabindex', 0); })
				: collection.map(function (x) { x.setAttribute('tabindex', -1); });
		}

		function goToPage(page) {
			$state.go(Constants.Page[page]);
		}

		function checkPageTransition(){
			loadStateData(StorageService.get(Constants.SK.RequestedZipcode));
			checkIsHomePage();
			checkIsCreationFlow();
		}

		function loadStateData(data){
			vm.isCA = data && data.State === Constants.State.California;
		}

		function checkIsHomePage() {
			vm.isHomePage = true;
		}

		function checkIsCreationFlow(){
			vm.isCreationFlow = FlowService.getCurrentState().split('.')[0] === 'mainFlow';
		}
		
		function goToUrl(key) {
			Utils.goToUrl(key, true);
		}
	}
})();