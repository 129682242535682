(function () {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentationContainer', {
            templateUrl: 'html/shared/requiredDocumentationContainer/requiredDocumentationContainer.html',
			controller: RequiredDocumentationContainerController,
			controllerAs: 'vm'
        });	

    RequiredDocumentationContainerController.$inject = ['StorageService', 'FlowService', 'Constants'];

    function RequiredDocumentationContainerController(StorageService, FlowService, Constants) {
        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            currentStep: null,
            UploadProofStep: Constants.UploadProofStep,
			nextStep: nextStep,
			show: show
        });
		
		function init() {
			
			var stepCode = StorageService.get(Constants.SK.UploadProofStep);
					
			vm.currentStep = stepCode;
		}
		
		function nextStep(options) {
			
			FlowService.nextStep(options);
			
			init();
		}
		
		function show(stepCode) {
			return vm.currentStep == stepCode;
		}
    }
})();
