(function () {
    'use strict';

    angular.module('l4b')
        .component('trackApplicationStatus', {
            templateUrl: 'html/modules/apply/_components/trackApplicationStatus/trackApplicationStatus.component.html',
            bindings: {
                submitDocumentation: '<',
				enrollmentNumber: '<',
                showGoToEnrollment: '<',
                isThankYouPage: '<',
            },
            controller: TrackApplicatinStatusController,
            controllerAs: 'vm'
        });

    function TrackApplicatinStatusController(Constants, DTOFactory, FlowService, BrandService, Utils, $window, StorageService, RestService) {
        var vm = this;

        angular.extend(vm, {
			$onInit: init,
            setApiValues: '=',
            Constants: Constants,
            application: DTOFactory.Application(),
            accountLoginDTO: DTOFactory.AccountLogin(),
			prepaidUrl: BrandService.getCurrent().Domain,
            isNewCustomer: false,
            isVerizonForwardFlow: false,
            allowRedirectToVerizon: false,
            isVerizon3Flow: false,
            showAddDiscount: false,
			isSuccess : false,
            // Methods
            goToMyAccount: goToMyAccount,
            goHome: goHome,
            goToVerizon : goToVerizon
        });
        
		function init() {
			if (!vm.enrollmentNumber) {
				vm.enrollmentNumber = vm.application && vm.application.EnrollmentNumber;
			}
			var config = StorageService.get(Constants.SK.Configuration);
            vm.isAcpWindDownEnabled = config.isAcpWindDownEnabled;
            	
			vm.apiValues = Utils.getNatVerApiValues();

            vm.isQualified = isStatus(Constants.Status.QUALIFIED);
            vm.isPending = isStatus(Constants.Status.PENDING);
            vm.isEnrolled = isStatus(Constants.Status.ENROLLED);
            vm.isRejected = isStatus(Constants.Status.REJECTED);
            vm.isDeEnrolled = isStatus(Constants.Status.DEENROLLED);
            vm.isUnqualified = isStatus(Constants.Status.UNQUALIFIED);

			var natVerErrors = vm.apiValues.errorResponse 
				|| vm.apiValues.pendingCertification 
				|| vm.apiValues.pendingEligibility 
				|| vm.apiValues.pendingResolution 
				|| vm.apiValues.pendingReview;
			
			vm.isSuccess = !natVerErrors;
            vm.isNewCustomer = vm.application.CustomerTypeCode == "N";
            
            var isFwaNonAALFlag = vm.application.AccessTokenDto.nonAALFlag && vm.application.BusinessLineCode == Constants.BusinessLine.FWA;

            vm.showAddDiscount = !vm.isNewCustomer && (isFwaNonAALFlag || vm.application.BusinessLineCode == Constants.BusinessLine.FIOS);

            vm.isVerizonForwardFlow = vm.application.BenefitTypeCode == Constants.BenefitType.VZF;

            var verizonForwardNewCustomerAllowedBusinessLines = config.verizonForwardNewCustomerAllowedBusinessLines.split(',');
            
		    vm.isVerizon3Flow = vm.isVerizonForwardFlow && verizonForwardNewCustomerAllowedBusinessLines.includes(vm.application.BusinessLineCode) && !vm.application.AccessTokenDto.IsVerizonManualFlow;
            
            vm.allowRedirectToVerizon = !vm.isVerizon3Flow || config.allowRedirectOnVZF3;	
   
		}

        function isStatus(status) {
            return vm.application.Status.Code === status;
        }

        function goHome() {
            FlowService.goHome();
        }

        function goToVerizon() {
            var event;
            var redirectUrl = vm.application ? vm.application.RedirectUrl : null;
            switch(vm.application.Status.Code) {
                case Constants.Status.QUALIFIED:
                    event = "QUALIFIED_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.PENDING:
                    event= "PENDING_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.REJECTED:
                case Constants.Status.UNQUALIFIED:
                    event = "UNQUALIFIED_REDIRECT_TO_VERIZON";
                    break;
            }

            if(event) {
                RestService.trackApplicationEvent(
                    event,
                    redirectUrl
                );
            }

            
			$window.location.href = redirectUrl;
		}

        function goToMyAccount() {

            vm.accountLoginDTO.ZipCode = vm.application.Address.ZipCode;
            vm.accountLoginDTO.Dob = vm.application.Applicant.DOB;
            vm.accountLoginDTO.LastName = vm.application.Applicant.LastName;
            vm.accountLoginDTO.ForgotEnrollment = true;
            StorageService.set(Constants.SK.AccountLogin, vm.accountLoginDTO);
            var loginStateParams = { token: vm.application.AccessToken };
            StorageService.set(Constants.SK.LoginStateParams, loginStateParams);
            FlowService.goToMyAccount();
            
        }
    }
})();