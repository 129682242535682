(function () {
    'use strict';

    angular.module('l4b')
        .component('disclaimers', {
            templateUrl: 'html/shared/disclaimers/disclaimers.html',
            bindings: {
                parentForm: '<',
                disclaimers: '<',
                disclosures: '<',
				useCheckbox: '<'
            },
            controller: DisclaimersController,
            controllerAs: 'vm'
        });

    function DisclaimersController(Constants, DTOFactory, StorageService, BrandService, GlobalizationService) {

        var vm = this;
        var selection = [];

        angular.extend(vm, {
            $onInit: init,
            checkDisclaimer: checkDisclaimer,
            patterns: {},
            useInitials: false,
            useCheckbox: true,
            lang: GlobalizationService.getLang()
        });

        function init() {
            var applicant = DTOFactory.Applicant();
            vm.patterns.INITIALS = applicant.InitialsPattern;
            StorageService.remove(Constants.SK.Disclaimers);
        }

        function checkDisclaimer(disclaimer) {
            var pos = selection.indexOf(disclaimer.code);
            if (validateInitials(disclaimer)) {
                if (pos === -1) {
                    selection.push(disclaimer.code);
                }
                else {
                    selection.splice(pos, 1);
                }
            }

            StorageService.set(Constants.SK.Disclaimers, selection);
        }

        function validateInitials(disclaimer) {
            if (!vm.useInitials) {
                return true;
            }

            return new RegExp(vm.patterns.INITIALS).test(disclaimer.value);
        }
    }
})();
