(function () {
    'use strict';

    angular.module('l4b')
        .controller('ApplicantEligibilityController', ApplicantEligibilityController);

    function ApplicantEligibilityController($stateParams, RestService, Constants, DTOFactory, FlowService, StorageService, FeaturesService) {

        var vm = this;

        angular.extend(vm, {
			$onInit: init,
            nextStep: nextStep,
            features: FeaturesService.get()
        });
		
		function init() {
            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_PROGRAM_RECIPIENT_MAINPAGE_PRESENTED'
            );
			FlowService.updateCurrentStep();
		}

        function nextStep(applicantSelected) {
            var qualification = DTOFactory.Qualification();

			qualification.UseDependent = !applicantSelected;
            StorageService.set(Constants.SK.Qualification, qualification);
			
            if (applicantSelected) {
    
                RestService.trackApplicationEvent(
                    'SYSTEM_APPLICATION-CREATION_PROGRAM_RECIPIENT_MYSELF_SELECTED'
                );

                StorageService.remove(Constants.SK.Dependent);

				RestService.setEligibility(function() {
					
					FlowService.nextStep();
				});
				
				return;
            } 
			

            return FlowService.nextStep();
        }
    }
})();
