(function () {
    'use strict';

    angular.module('l4b')
        .directive('uppercase', UpperCase);

    UpperCase.$inject = [];

    function UpperCase() {
        return {
            restrict: 'A',
            require: 'ngModel',
            link: function (scope, element, attrs, ngModelController) {
                ngModelController.$parsers.push(function (input) {
                    return input ? input.toUpperCase() : "";
                });
            }
        };
    }
})();
