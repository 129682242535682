(function () {
    'use strict';

    angular.module('l4b')
        .factory('ApplyAuthProvider', ApplyAuthProvider);

    function ApplyAuthProvider(FlowService, StorageService, Constants) {

        return {
            authorize: authorize
        };

        function authorize(toState) {
            var currentState = toState.name;
            if (isValidState(currentState)) {
                return true;
            }

            var isFlowCompleted = StorageService.get(Constants.SK.FlowCompleted);
            var isFinalState = currentState == Constants.Page.ApplicationCompleted 
				|| currentState == Constants.Page.Exit;
			
            var app = StorageService.get(Constants.SK.Application);
            if(app) {
                var zipCodeData = app.Address;
                zipCodeData = zipCodeData && zipCodeData.ZipCode && zipCodeData.State;

                // If the flow has been completed and is not in any end point, or is not at the home page and don't have zipcode established,
            // it means that must redirect to home
                if (isFlowCompleted && !isFinalState || !zipCodeData) {

                    StorageService.removeAllSession();

                    FlowService.goHome();
                    return false;
                }
            }
            
            
            // If it is in an end point and the flow was not set, then set flow completed
            if (isFinalState && !isFlowCompleted) {
                StorageService.set(Constants.SK.FlowCompleted, true);
            }

            return true;
        }

        function isValidState(currentState) {
            return currentState && 
            (currentState === Constants.Page.PersonalInformation || currentState === Constants.Page.Landing);
        }		
    }
})();
