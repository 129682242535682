(function () {
    'use strict';

    angular.module('l4b')
        .controller('ProgramEligibilityController', ProgramEligibilityController);

    function ProgramEligibilityController(Constants, DTOFactory, StorageService, GlobalizationService, RestService, FlowService, Utils, FeaturesService) {

        var vm = this,
            subOptionSelected = null;

        angular.extend(vm, {
            $onInit: init,
            programsLimitTo: 2,
            nextStep: nextStep,
            address: DTOFactory.Address(),
            application: DTOFactory.Application(),
            programs: [],
			piiDTO: DTOFactory.Applicant(),
            isAnyProgramSelected: isAnyProgramSelected,
            selectedProgramsAndAcpFormer: selectedProgramsAndAcpFormer,
            applyByIncome: applyByIncome,
            checkOption: checkOption,
            clearSelectedOptions: clearSelectedOptions,
            features: FeaturesService.get(),
            lifelineEligible: FeaturesService.isLifelineEligible(),
			showSchoolName: showSchoolName,
			forceUseDependent: forceUseDependent,
            checkSubOption: checkSubOption,
			hasSubmitted: false,
            isVerizonForwardFlow: false,
            hasFormerAcpBeneficiaryProgram: false,
            programsToShow: [],
            formerAcpProgram: [],
        });

        function init() {
            vm.isVerizonForwardFlow = vm.application.BenefitTypeCode == Constants.BenefitType.VZF;
            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_PROGRAM-SELECTION-STARTED'
            );

            RestService.getPrograms().then(onGetProgramsSuccess);
        }

        function onGetProgramsSuccess(response) {
            var error = StorageService.get(Constants.SK.Error);
			if (error) {
                FlowService.goToExit(); 
            }
            
            vm.programMessage = '';
            vm.programs = response.data;

            vm.programsToShow = vm.programs.filter(function (program) {
                return program.code != Constants.Program.FormerAcpBeneficiary
            });

			vm.formerAcpProgram = vm.programs.filter(function (program) {
                return program.code == Constants.Program.FormerAcpBeneficiary;
            });

            vm.hasFormerAcpBeneficiaryProgram = vm.formerAcpProgram.length > 0;

            var selected = DTOFactory.Qualification().Programs;
            if (selected) {

                Utils.each(vm.programs, function (p, i) {
                    var exists = Utils.find(selected, function (e) {
                        return angular.equals(e.code, p.code);
                    });

                    p.checked = exists ? true : false;

                    if (p.checked && i >= vm.programsLimitTo) {
                        vm.seeOtherPrograms = true;
                    }
                });

            }
        }

        function nextStep(form) {
			vm.hasSubmitted = true;
			
            if (!Utils.validateForm(form)) return;           

            if(vm.selectedProgramsAndAcpFormer()) return;
            
            if (vm.applyViaIncome) {
                applyByIncome();
                return;
            }

            setProgramQualification(getSelectedPrograms(), vm.showSchoolName() ? vm.schoolName : '', subOptionSelected && subOptionSelected.code);
        }

        function setProgramQualification(programs, schoolName, subProgram) {

            var qualification = DTOFactory.Qualification();

            qualification.Type = Constants.QualificationType.Program;
            qualification.Programs = programs;
            qualification.Income = null;
            qualification.SchoolName = schoolName;
            qualification.SubProgramCode = subProgram;

            StorageService.set(Constants.SK.Qualification, qualification);

            RestService.setQualification(submitQualificationSuccess);
        }

        function submitQualificationSuccess() {
			FlowService.nextStep();
        }

        function isAnyProgramSelected() {
            var sPrograms = getSelectedPrograms(), 
                isValid = sPrograms.length > 0;

            if(isValid){
                sPrograms.forEach(function(program){
                    if( program.formFields && 
                        !program.formFields.find(function(p){ return p.checked; }) ){
                        isValid = false;
                    }
                });
            }

            return isValid || vm.applyViaIncome;
        }

        function selectedProgramsAndAcpFormer() {
            var sPrograms = getSelectedPrograms();

            var selectedAcpFormer = Utils.find(sPrograms, function(program) {
				return program.checked && program.code == Constants.Program.FormerAcpBeneficiary
			});

            var error =selectedAcpFormer && sPrograms.length > 1
            return error;
        }

        function getSelectedPrograms() {
            return vm.programs.filter(function (program) {
                return program.checked;
            });
        }

        function applyByIncome() {
            StorageService.remove(Constants.SK.Qualification);
            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_INCOME-QUALIFICATION-SELECTED'
            );

            return FlowService.goToIncomeEligibility();
        }

		function showSchoolName() {
			return Utils.find(vm.programs, function(program) {
				return program.checked && program.needSchoolName;
			});
		}
		function forceUseDependent() {
			return Utils.find(vm.programs, function(program) {
				return program.checked && program.forceUseDependent;
			});
		}

        function checkOption() {  
            vm.applyViaIncome = false;
        }

        function checkSubOption(evt, option, allOptions) {
            if (!evt.target.checked) {
                subOptionSelected = null;
                return;
            }

            allOptions.forEach(function (o) { o.checked = o.code == option.code; });
            subOptionSelected = option;
        }

        function clearSelectedOptions() {
            angular.forEach(vm.programs, function (program) {
                program.checked = false;
            });
        }
    }
})();
