(function () {
    'use strict';

    angular.module('l4b')
        .factory('RestService', RestService);

    function RestService(BrandService, $cookies, $http, $q, $httpParamSerializerJQLike, $timeout, Constants, GlobalizationService, StorageService, Utils, ErrorHandler, ResponseAdapter, RequestAdapter, $state, DTOFactory) {

        var _globalTimeout = 60 * 1000,
            baseQuestWebAppURL = Constants.EnvironmentSettings.Url.baseQuestWebAppURL,
            baseVaultWebAppURL = Constants.EnvironmentSettings.Url.baseVaultWebAppURL,
            baseVaultAuthURL = Constants.EnvironmentSettings.Url.baseVaultAuthURL;

        var service = {
            clientLog: clientLog,
            getError: getError,
            initEnrollment: initEnrollment,
            initApplication: initApplication,
            applyEnrollment: applyEnrollment,
            getAddressSuggestions: getAddressSuggestions,
            getPrograms: getPrograms,
            getIncomes: getIncomes,
            setDependent: setDependent,
            getDisclaimers: getDisclaimers,
            getDisclosures: getDisclosures,
            submitProof: submitProof,
            getUploadedDocumentRequirements: getUploadedDocumentRequirements,
            getApplicationRequirements: getApplicationRequirements,
            getLegalNotes: getLegalNotes,
            createApplication: createApplication,
            acceptDisclaimers: acceptDisclaimers,
            accountLogin: accountLogin,
            getApplication: getApplication,
            updateAddressByApplicant: updateAddressByApplicant,
            updateContactByApplicant: updateContactByApplicant,
            getDeEnrollReasons: getDeEnrollReasons,
            serviceCancellation: serviceCancellation,
            checkNatVerEligibility: checkNatVerEligibility,
            retryNatVerElegibilityWithAppId: retryNatVerElegibilityWithAppId,
            isNatVerRetryElegibilityAvailable: isNatVerRetryElegibilityAvailable,
            natVerApiStatusCheck: natVerApiStatusCheck,
            natVerApiStatusCheckByAppId: natVerApiStatusCheckByAppId,

            initFlowExecution: initFlowExecution,
            logFlowExecutionEvent: logFlowExecutionEvent,
            trackApplicationEvent: trackApplicationEvent,

            checkZipCode: checkZipCode,

            getStates: getStates,
            getCities: getCities,

            getDefaultLogInType: getDefaultLogInType,
            getConfiguration: getConfiguration,

            setQualification: setQualification,
            setEligibility: setEligibility,

            getTransferExceptionForm: getTransferExceptionForm,
            transferExceptionEsign: transferExceptionEsign,
            transferExceptionOptinEsign: transferExceptionOptinEsign,
            validateAccessToken: validateAccessToken,
            selectBenefit: selectBenefit,
            setAccessTokenFlowFlag: setAccessTokenFlowFlag,
            initFromScratch: initFromScratch
        };

        var calls = {
            clientLog: 'client-log',
            publicSiteLogin: 'captcha/public-site-login',
            initApplication: 'applications',
            setApplicantInfo: 'applications/{0}/personal-info',
            getAddressSuggestions: 'addresses/suggest',
            setDependent: 'applications/{0}/dependent',
            getPrograms: 'programs/{0}',
            getIncomes: 'incomeRanges/{0}',
            selectPrograms: 'applications/{0}/program',
            selectIncome: 'applications/{0}/income',
            selectEligibility: 'applications/{0}/eligibility',
            selectProduct: 'applications/{0}/product?phoneNumber={1}',
            setDeviceOption: 'applications/{0}/device-option',
            getDisclaimers: '{0}/disclaimers',
            getDisclosures: '{0}/disclosures',
            uploadMultipleDocuments: 'bo/document/upload-composite?source=PUBLIC_SITE&applicationToken={0}',
            getUploadedDocumentRequirements: 'bo/{0}/uploaded-proofs?hasProofsRejected=true',
            getApplicationRequirements: 'applications/{0}/requirements',
            getLegalNotes: 'legalnotes',
            acceptDisclaimers: 'applications/{0}/disclaimers',
            saveApplication: 'applications/{0}/save',
            accountLogin: 'token',
            getApplication: 'applicantapplicationmanager/login',
            updateAddressByApplicant: 'applications/{0}/update-address-by-applicant',
            updateContactByApplicant: 'applications/{0}/update-contact-by-applicant',
            getDeEnrollReasons: 'deneroll-reasons',
            serviceCancellation: 'applications/{0}/cancel-by-applicant',
            checkNatVerEligibility: 'applications/{applicationToken}/retryElegibility',
            retryNatVerElegibilityWithAppId: 'applications/{applicationToken}/retryElegibilityWithNatVerId',
            isNatVerRetryElegibilityAvailable: 'applications/{0}/retryElegibilityAvailable',
            natVerApiStatusCheck: 'applications/natVerApi/{0}/{1}/statusCheck',
            natVerApiStatusCheckByAppId: 'applications/natVerApi/applications/{0}/{1}/statusCheck',
            natVerApiEligibilityCheck: 'applications/natVerApi/{0}/eligibilityCheck',
            initFlowExecution: 'flow/init-ps-flow',
            logFlowExecutionEvent: 'flow/log-execution-event',
            trackApplicationEvent: 'tracking',
            checkZipCode: 'zipcode/{0}/check',
            validateAccessToken: 'zipcode/accessToken/{0}/validate',
            getStates: 'zipcode/states',
            getCities: 'zipcode/{0}/cities',
            getDefaultLogInType: 'getDefaultLogInType',
            getConfiguration: 'configuration/public-site',
            getTransferExceptionForm: 'applications/transfer-exception/{0}/form',
            transferExceptionEsign: 'applications/{0}/transfer-exception/esign',
            transferExceptionOptinEsign: 'applications/{0}/transfer-exception/optin',
            updateApplicationToken: 'public-site/upgrade-to-applicant-token/{0}',
            selectBenefit: 'applications/{0}/select-benefit',
            setAccessTokenFlowFlag: 'vz/accessToken/{0}',
            initFromScratch: 'vz/init-from-scratch'
        };

        return service;

        function checkNatVerEligibility() {
            var applicationToken = getApplicationToken();

            return httpRequest({
                url: baseQuestWebAppURL + calls.checkNatVerEligibility.replace('{applicationToken}', applicationToken),
                authTokenRequired: true,
                method: 'GET'
            });
        }

        function retryNatVerElegibilityWithAppId(applicationToken, natVerApplicationId, onSuccess) {
            httpRequest({
                url: baseQuestWebAppURL + calls.retryNatVerElegibilityWithAppId.replace('{applicationToken}', applicationToken),
                method: 'POST',
                params: {
                    natVerApplicationId: natVerApplicationId
                },
                authTokenRequired: true
            })
                .then(function (response) {
                    onSuccess(response);
                });
        }

        function isNatVerRetryElegibilityAvailable(appToken, onSuccess) {

            httpRequest({
                url: baseQuestWebAppURL + calls.isNatVerRetryElegibilityAvailable.format(appToken),
                authTokenRequired: true,
                method: 'GET',
                params: {
                    channel: 'PUBLIC_SITE'
                }
            })
                .then(function (response) {
                    onSuccess(response);
                });
        }

        function natVerApiStatusCheck(encryptedApplicationId, languageCode, onSuccess) {

            httpRequest({
                url: baseQuestWebAppURL + calls.natVerApiStatusCheck.format(encryptedApplicationId, languageCode),
                method: 'GET'
            })
                .then(function (response) {
                    onSuccess(response.data);
                });
        }

        function natVerApiStatusCheckByAppId(applicationId, languageCode, onSuccess) {

            httpRequest({
                url: baseQuestWebAppURL + calls.natVerApiStatusCheckByAppId.format(applicationId, languageCode),
                authTokenRequired: true,
                method: 'GET'
            })
                .then(function (response) {
                    if (hasError()) {
                        return null;
                    }

                    onSuccess(response.data);
                });
        }

        function clientLog(data) {
            httpRequest({
                spinner: false,
                jsonRequest: true,
                url: baseQuestWebAppURL + calls.clientLog,
                method: 'POST',
                data: data,
                error: function () { }
            });
        }

        function getError() {
            return StorageService.get(Constants.SK.Error);
        }
        function initApplication(onSuccess) {
            var adaptedResponse = {};
            var initApplicationCallback = function (response) {
                if (!hasError()) {
                    adaptedResponse = ResponseAdapter.initEnrollment(response);
                }

                if (onSuccess) {
                    onSuccess(adaptedResponse);
                }

            };

            httpRequest({
                url: baseQuestWebAppURL + calls.initApplication,
                method: 'POST',
                authTokenRequired: true,
                data: RequestAdapter.initApplication(),
                removePreviousError: true
            })
                .then(initApplicationCallback);
        }

        function initEnrollment(onSuccess) {
            var loginCallback = function (response) {
                StorageService.set(Constants.SK.Token, response.data.access_token);
                if (onSuccess) {
                    onSuccess();
                }
            };

            var captcha = StorageService.get(Constants.SK.Captcha);

            httpRequest({
                url: baseQuestWebAppURL + calls.publicSiteLogin.format(captcha.Token),
                method: 'POST',
                jsonRequest: true,
                data: captcha.Token
            })
                .then(loginCallback);
        }

        function getIncomes() {
            var appToken = getApplicationToken();

            return httpRequest({
                url: baseQuestWebAppURL + calls.getIncomes.format(appToken),
                authTokenRequired: true,
                method: 'GET',
                params: {
                    lang: GlobalizationService.getLang()
                }
            });
        }

        function getPrograms() {
            var appToken = getApplicationToken();

            return httpRequest({
                url: baseQuestWebAppURL + calls.getPrograms.format(appToken),
                authTokenRequired: true,
                method: 'GET'
            });
        }

        function applyEnrollment(onSuccess) {
            var token = getApplicationToken();
            var adaptedResponse = {};

            var setApplicantInfoCallback = function (response) {

                if (!hasError()) {
                    adaptedResponse = ResponseAdapter.applyEnrollment(response);
                }

                onSuccess(adaptedResponse);
            };

            httpRequest({
                url: baseQuestWebAppURL + calls.setApplicantInfo.format(token),
                method: 'PUT',
                data: RequestAdapter.applyEnrollment(),
                authTokenRequired: true,
                removePreviousError: true
            })
                .then(setApplicantInfoCallback);
        }

        function getAddressSuggestions(zipCode, address, onSuccess) {
            var params = {
                zipCode: zipCode,
                address: address.toUpperCase(),
                leadToken: $cookies.get(Constants.Cookie.Session) ? $cookies.get(Constants.Cookie.Session) : null
            };

            httpRequest({
                retry: false,
                authTokenRequired: true,
                spinner: false,
                url: baseQuestWebAppURL + calls.getAddressSuggestions,
                method: 'GET',
                params: params
            })
                .then(function (response) {
                    onSuccess(response.data);
                });
        }

        function setDependent(onSuccess) {
            var token = getApplicationToken();
            var dependent = DTOFactory.Dependent();

            httpRequest({
                url: baseQuestWebAppURL + calls.setDependent.format(token),
                method: 'PUT',
                data: {
                    Dependent: dependent
                },
                authTokenRequired: true
            })
                .then(function (response) {
                    var adaptedResponse = ResponseAdapter.setDependent(response);
                    onSuccess(adaptedResponse);
                });
        }

        function getDeEnrollReasons(onSuccess) {
            var adaptedResponse;

            var sourceCode = 'CustomerRequest';
            var lang = GlobalizationService.getLang().toUpperCase();

            var payload = {
                lang: lang,
                sourceCode: sourceCode
            };

            httpRequest({
                url: baseQuestWebAppURL + calls.getDeEnrollReasons,
                method: 'GET',
                authTokenRequired: true,
                params: payload,
            })
                .then(function (response) {
                    adaptedResponse = ResponseAdapter.getDeEnrollReasons(response.data);
                    onSuccess(adaptedResponse);
                });
        }

        function serviceCancellation(request) {
            var appToken = getApplicationToken();

            return httpRequest({
                url: baseQuestWebAppURL + calls.serviceCancellation.format(appToken),
                method: 'POST',
                authTokenRequired: true,
                data: request
            });
        }

        function updateAddressByApplicant() {
            var application = StorageService.get(Constants.SK.Application);
            var updateAddress = StorageService.get(Constants.SK.UpdateAddress);

            return httpRequest({
                url: baseQuestWebAppURL + calls.updateAddressByApplicant.format(application.ApplicationToken),
                method: 'POST',
                authTokenRequired: true,
                removePreviousError: true,
                data: RequestAdapter.updateAddressByApplicant(updateAddress)
            });
        }

        function updateContactByApplicant(payload) {
            var application = StorageService.get(Constants.SK.Application);

            return httpRequest({
                url: baseQuestWebAppURL + calls.updateContactByApplicant.format(application.ApplicationToken),
                method: 'POST',
                authTokenRequired: true,
                removePreviousError: true,
                data: payload
            });
        }

        function getDisclaimers(onSuccess) {
            var lang = GlobalizationService.getLang();
            var appToken = getApplicationToken();

            httpRequest({
                authTokenRequired: true,
                url: baseQuestWebAppURL + calls.getDisclaimers.format(appToken),
                method: 'GET',
                params: {
                    languageCode: lang
                }
            })
                .then(function (response) {
                    var adaptedResponse = ResponseAdapter.getDisclaimers(response);
                    return onSuccess(adaptedResponse);
                });
        }

        function getDisclosures(onSuccess) {
            var lang = GlobalizationService.getLang();
            var appToken = getApplicationToken();

            httpRequest({
                authTokenRequired: true,
                url: baseQuestWebAppURL + calls.getDisclosures.format(appToken),
                method: 'GET',
                params: {
                    languageCode: lang
                }
            })
                .then(function (response) {
                    return onSuccess(response.data);
                });
        }

        function getLegalNotes(onSuccess) {
            var lang = GlobalizationService.getLang();
            var brand = BrandService.getCurrent().Code;

            httpRequest({
                retry: true,
                authTokenRequired: true,
                url: baseQuestWebAppURL + calls.getLegalNotes,
                method: 'GET',
                params: {
                    languageCode: lang,
                    brandCode: brand
                }
            })
                .then(function (response) {
                    var adaptedResponse = ResponseAdapter.getLegalNotes(response);
                    return onSuccess(adaptedResponse);
                });
        }

        function acceptDisclaimers(onSuccess) {
            var appToken = getApplicationToken();

            httpRequest({
                url: baseQuestWebAppURL + calls.acceptDisclaimers.format(appToken),
                method: 'PUT',
                data: RequestAdapter.acceptDisclaimers(),
                authTokenRequired: true
            })
                .then(function () {
                    onSuccess();
                });
        }

        function createApplication(onSuccess) {
            var
                appToken = getApplicationToken(),
                application = DTOFactory.Application();

            var updateApplicationCallback = function (tokenResponse) {

                StorageService.set(Constants.SK.Token, tokenResponse.data.access_token);

                trackApplicationEvent(
                    'SYSTEM_APPLICATION-CREATION_APPLICATION-REVIEW-ESIGN-COMPLETED'
                );

                onSuccess(application);
            };

            var updateApplicationToken = function () {

                httpRequest({
                    url: baseQuestWebAppURL + calls.updateApplicationToken.format(appToken),
                    method: 'POST',
                    authTokenRequired: true
                })
                    .then(updateApplicationCallback);
            };



            var saveApplicationCallback = function (response) {

                var adaptedResponse = ResponseAdapter.createEnrollment(response);

                application.EnrollmentNumber = adaptedResponse.enrollmentNumber;
                application.ApplicationId = adaptedResponse.applicationId;
                application.RedirectUrl = adaptedResponse.RedirectUrl;
                application.Status = adaptedResponse.Status;
                application.NatVerApiTransactionInfo = adaptedResponse.natVerApiTransactionInfo;
                application.BenefitTypeCode = adaptedResponse.BenefitTypeCode;
                application.Requirements = adaptedResponse.Requirements;
                application.BusinessLineCode = adaptedResponse.BusinessLineCode;
                
				updateApplicationToken();
			};
			
            var callSaveApplication = function () {
                httpRequest({
                    url: baseQuestWebAppURL + calls.saveApplication.format(appToken),
                    method: 'POST',
                    authTokenRequired: true
                })
                    .then(saveApplicationCallback);
            };

            return callSaveApplication();
        }

        function accountLogin(onSuccess, onError) {

            var loginCallback = function (response) {
                StorageService.set(Constants.SK.Token, response.data.access_token);

                getApplication(onSuccess, onError);
            };

            httpRequest({
                url: baseVaultAuthURL + calls.accountLogin,
                method: 'POST',
                formUrlEncodedRequest: true,
                data: RequestAdapter.accountLogin(),
                error: onError
            })
                .then(loginCallback);
        }

        function getApplication(onSuccess, onError) {
            var application;

            var statusCheckCallback = function (response) {
                application.data.NatVerApiTransactionInfo = response;

                onSuccess(application);
            };

            var getApplicationCallback = function (response) {
                application = ResponseAdapter.getApplication(response);

                var lang = GlobalizationService.getLang().toUpperCase();

                if (!application.data.AppliedUsingNatVerAppId) {
                    natVerApiStatusCheckByAppId(application.data.ApplicationId, lang, statusCheckCallback);
                    return;
                }

                onSuccess(application);
            };

            httpRequest({
                url: baseQuestWebAppURL + calls.getApplication,
                method: 'POST',
                jsonRequest: true,
                authTokenRequired: true,
                removePreviousError: true,
                token: StorageService.get(Constants.SK.Token),
                data: RequestAdapter.getApplication(),
                error: onError
            })
                .then(getApplicationCallback);
        }

        /* Guided Flow proofs submission (on Application creation)*/
        function submitProof(data, appToken, onSuccess, onError) {
            return httpRequest({
                url: baseVaultWebAppURL + calls.uploadMultipleDocuments.format(appToken),
                data: data,
                authTokenRequired: true,
                avoidProcessEscalations: true,
                jsonRequest: true,
                method: 'POST',
                error: onError
            }).then(function (response) {
                onSuccess(response);
            });
        }

        function getUploadedDocumentRequirements(appToken, onSuccess, onError) {
            return httpRequest({
                url: baseVaultWebAppURL + calls.getUploadedDocumentRequirements.format(appToken),
                authTokenRequired: true,
                avoidProcessEscalations: true,
                method: 'GET',
                error: onError
            }).then(function (response) {
                onSuccess(response);
            });
        }

        function getApplicationRequirements(appToken, lang, onSuccess, onError) {
            return httpRequest({
                url: baseQuestWebAppURL + calls.getApplicationRequirements.format(appToken),
                authTokenRequired: true,
                avoidProcessEscalations: true,
                method: 'GET',
                params: {
                    lang: lang
                },
                error: onError
            }).then(function (response) {
                onSuccess(response);
            });
        }


        function initFlowExecution(onSuccess, onError) {
            var token = Utils.getQueryString('token');
            
            if(!token) {
                var tokenData = StorageService.get(Constants.SK.AccessTokenData);
                token = tokenData ? tokenData.token : null;
            }
            
            var geolocation = StorageService.get(Constants.SK.Geolocation);
            var payload = { AccessToken: token };

            if (geolocation) {
                payload.Latitude = geolocation.Latitude;
                payload.Longitude = geolocation.Longitude;
            }

            return httpRequest({
                url: baseQuestWebAppURL + calls.initFlowExecution,
                authTokenRequired: false,
                method: 'POST',
                data: payload,
                onError: onError
            }).then(function (response) {

                var flowExecutionToken = response.data;
                StorageService.set(Constants.SK.FlowExecutionToken, flowExecutionToken);

                onSuccess(response);
            });

        }

        function logFlowExecutionEvent(eventTypeCode, payload, onSuccess, onError) {

            var flowExecutionToken = StorageService.get(Constants.SK.FlowExecutionToken);

            var data = {
                FlowExecutionToken: flowExecutionToken,
                EventType: eventTypeCode,
                Payload: payload
            };

            return httpRequest({
                url: baseQuestWebAppURL + calls.logFlowExecutionEvent,
                authTokenRequired: false,
                method: 'POST',
                data: data,
                onError: onError
            }).then(function (response) {
                onSuccess(response);
            });

        }

        function trackApplicationEvent(eventTypeCode, payload) {

            var flowExecutionToken = StorageService.get(Constants.SK.FlowExecutionToken);
            var application = StorageService.get(Constants.SK.Application);
            var appToken = application && application.token || application && application.ApplicationToken || StorageService.get(Constants.SK.AppToken);

            var data = {
                AppToken: appToken,
                FlowExecutionToken: flowExecutionToken,
                EventType: eventTypeCode,
                Payload: payload
            };

            return httpRequest({
                url: baseQuestWebAppURL + calls.trackApplicationEvent,
                authTokenRequired: false,
                method: 'POST',
                data: data
            });
        }

        function checkZipCode(onSuccess) {

            var zipCode = DTOFactory.Address().ZipCode;
            var brandCode = BrandService.getCurrent().Code;
            var flowToken = StorageService.get(Constants.SK.FlowExecutionToken);
            var application = StorageService.get(Constants.SK.Application);
            var captcha = StorageService.get(Constants.SK.Captcha);

            var params = {
                zipCode: zipCode,
                brandCode: brandCode,
                phoneNumber: application.PhoneNumber,
                captchaToken: captcha.Token,
                flowToken: flowToken,
                token: application.AccessToken
            };

            httpRequest({
                url: baseQuestWebAppURL + calls.checkZipCode.format(zipCode),
                method: 'POST',
                data: params,
                removePreviousError: true
            }).then(function (response) {
                var adaptedResponse = ResponseAdapter.getZipCodeMetadata(response);
                onSuccess(adaptedResponse);
            });
        }

        function validateAccessToken(token, onError) {

            return httpRequest({
                url: baseQuestWebAppURL + calls.validateAccessToken.format(token),
                method: 'GET',
                error: onError
            });
        }

        function getStates(onSuccess) {
            httpRequest({
                url: baseQuestWebAppURL + calls.getStates,
                method: 'GET'
            }).then(function (response) {
                onSuccess(response.data);
            });
        }

        function getCities(zipCode) {
            return httpRequest({
                url: baseQuestWebAppURL + calls.getCities.format(zipCode),
                method: 'GET',
                removePreviousError: true
            });
        }

        function getDefaultLogInType() {
            return httpRequest({
                url: baseQuestWebAppURL + calls.getDefaultLogInType,
                method: 'GET'
            });
        }

        function getConfiguration() {
            return httpRequest({
                url: baseQuestWebAppURL + calls.getConfiguration,
                method: 'GET'
            });
        }

        function getTransferExceptionForm(onSuccess) {

            var lang = GlobalizationService.getLang().toUpperCase();

            httpRequest({
                url: baseQuestWebAppURL + calls.getTransferExceptionForm.format(lang),
                authTokenRequired: true,
                method: 'GET'
            }).then(function (response) {
                onSuccess(response.data);
            });
        }

        function transferExceptionEsign(exceptionCode, esignDatetime, onSuccess, onError) {
            var appToken = getApplicationToken();

            var data = {
                ExceptionCode: exceptionCode,
                EsignDatetime: esignDatetime,
            };

            return httpRequest({
                url: baseQuestWebAppURL + calls.transferExceptionEsign.format(appToken),
                authTokenRequired: true,
                method: 'POST',
                data: data,
                onError: onError
            }).then(function (response) {
                onSuccess(response);
            });
        }

        function transferExceptionOptinEsign(exceptionCode, esignDatetime, onSuccess, onError) {
            var appToken = getApplicationToken();

            var data = {
                ExceptionCode: exceptionCode,
                EsignDatetime: esignDatetime
            };

            return httpRequest({
                url: baseQuestWebAppURL + calls.transferExceptionOptinEsign.format(appToken),
                authTokenRequired: true,
                method: 'POST',
                data: data,
                onError: onError
            }).then(function (response) {
                onSuccess(response);
            });
        }

        function getApplicationToken() {
            return (StorageService.get(Constants.SK.Application) || {}).ApplicationToken;
        }


        function setQualification(onSuccess) {
            var qualification = StorageService.get(Constants.SK.Qualification),
                appToken = getApplicationToken();

            if (qualification.Type == Constants.QualificationType.Program) {
                httpRequest({
                    url: baseQuestWebAppURL + calls.selectPrograms.format(appToken),
                    method: 'PUT',
                    data: {
                        Programs: qualification.Programs,
                        SchoolName: qualification.SchoolName,
                        SubProgramTypeCode: qualification.SubProgramCode
                    },
                    authTokenRequired: true
                })
                    .then(function (response) {
                        onSuccess(response, qualification.Type);
                    });
            }
            else {
                var data = RequestAdapter.selectIncome();
                httpRequest({
                    url: baseQuestWebAppURL + calls.selectIncome.format(appToken),
                    method: 'PUT',
                    data: data,
                    authTokenRequired: true
                })
                    .then(function (response) {
                        onSuccess(response);
                    });
            }
        }

        function setEligibility(onSuccess) {
            var appToken = getApplicationToken();

            httpRequest({
                url: baseQuestWebAppURL + calls.selectEligibility.format(appToken),
                method: 'PUT',
                data: {
                    Type: 'A'
                },
                authTokenRequired: true
            })
                .then(onSuccess);
        }

        function selectBenefit(isLifelineSelected, onSuccess) {
            var appToken = getApplicationToken();
            var adaptedResponse = {};

            var selectBenefitCallback = function (response) {

                adaptedResponse = ResponseAdapter.applyEnrollment(response);

                onSuccess(adaptedResponse);
            };

            httpRequest({
                url: baseQuestWebAppURL + calls.selectBenefit.format(appToken),
                method: 'PUT',
                params: {
                    isLifelineSelected: isLifelineSelected
                },
                authTokenRequired: true
            })
                .then(selectBenefitCallback);
        }

        function initFromScratch(businessLineCode) {
            return httpRequest({
                url: baseQuestWebAppURL + calls.initFromScratch,
                method: 'POST',
                data: {
                    businessLineCode: businessLineCode
                }
            });
        }

        function setAccessTokenFlowFlag(token, isVerizonForwardFlow, onSuccess) {

            httpRequest({
                url: baseQuestWebAppURL + calls.setAccessTokenFlowFlag.format(token),
                method: 'PUT',
                params: {
                    isVerizonForwardFlow: isVerizonForwardFlow
                },
                authTokenRequired: true
            })
                .then(onSuccess);
        }

        function hasError() {
            return StorageService.get(Constants.SK.Error);
        }

        function httpRequest(config) {
            var timeoutMs = isNaN(config.timeout) ? _globalTimeout : config.timeout;
            var hasTimedOut = false;
            var timeout = $q.defer();

            config.timeout = timeout.promise;
            config.spinner = config.spinner !== false ? true : false;

            $timeout(function () {
                hasTimedOut = true;
                timeout.resolve();
            }, timeoutMs);

            if (!config.data) {
                config.data = {};
            }

            config.headers = config.headers || {};

            if (!config.formUrlEncodedRequest) {
                config.headers.channel = 'PUBLIC_SITE';
            }

            if (config.authTokenRequired) {
                var token = config.token || StorageService.get(Constants.SK.Token);
                config.headers.Authorization = 'BEARER ' + token;
            }

            if (config.jsonRequest) {
                config.headers['Content-Type'] = 'application/json; charset=utf-8';
                config.transformRequest = function (data) {
                    return JSON.stringify(data);
                };
            }
            else if (config.formUrlEncodedRequest) {
                config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
                config.data = $httpParamSerializerJQLike(config.data);
            }

            if (config.removePreviousError) {
                StorageService.remove(Constants.SK.Error);
            }

            var defered = $q.defer();
            var promise = defered.promise;

            $http(config)
                .then(function (response) {

                    defered.resolve(response);
                })
                .catch(function (response) {

                    if (hasTimedOut) {
                        config.data.__hasTimedOut = true;
                    }

                    // Manage error when session expires in My Account.
                    if (response.status.toString() === Constants.HttpErrors.Unauthorized) {
                        ErrorHandler.setTokenError();

                        Utils.removeLoginFromStorage();
                        $state.go(Constants.Page.Login, { isSessionExpired: true });
                        return;
                    }

                    if (config.error) {
                        return config.error(response);
                    }

                    // Non-solvable errors such as Already-Enrolled (Duplicated Person) comes as 409 BussinessException from Quest Backend
                    if (response.status === 409) {
                        ErrorHandler.processError(response);
                        defered.resolve(response);
                    }
                    else if (hasTimedOut && config.continueOnTimeOut) {
                        defered.resolve();
                    }
                    else {
                        throw response;
                    }
                });

            return promise;
        }
    }
})();
