(function() {
    'use strict';

	angular.module('l4b')
	.factory('Utils', Utils);
	
	Utils.$inject = ['$cookies', '$document', '$window', 'Constants', 'StorageService', '$timeout', '$location', '$anchorScroll', 'DTOFactory', 'FeaturesService', 'BrandService', '$state', 'GlobalizationService'];

	function Utils($cookies, $document, $window, Constants, StorageService, $timeout, $location, $anchorScroll, DTOFactory, FeaturesService, BrandService, $state, GlobalizationService) {
		
		var service = {
			getDate: getDate,
			isDate: isDate,
            validateForm: validateForm,
            resetForm: resetForm,
			find: find,
			any: any,
			filter: filter,
			map: map,
			each: each,
			contains: contains,
			distinct: distinct,
			getDOBFromFormatted: getDOBFromFormatted,
			GUID: GUID,
			scrollTop: scrollTop, 
			setFocus: setFocus,
			scrollTo: scrollTo,
            pushDataLayer: pushDataLayer,
            hasBlockingNladError: hasBlockingNladError,
            getQueryString: getQueryString,
			toBase64: toBase64,
            getAllQueryString: getAllQueryString,
			goToFaq: goToFaq,
			goToUrl: goToUrl,
            removeLoginFromStorage: removeLoginFromStorage,
            getNatVerApiValues: getNatVerApiValues,
            // state
            isState: isState,
			isPR: isPR,
			isTX: isTX,
			isHI: isHI,
			isAS: isAS,
            mapStateToAbreviation: mapStateToAbreviation,
			isProofNeeded: isProofNeeded,
			isRequirementNeeded: isRequirementNeeded,	
			areProofsStillNeeded: areProofsStillNeeded,
			getRequirementConfiguration: getRequirementConfiguration,
			getProofStepCode: getProofStepCode,
			setComponentFocus: setComponentFocus,
			round: round,
			isLifelineEnabled: isLifelineEnabled,
			isNullOrEmpty: isNullOrEmpty
		};

        return service;
        
		function GUID() {
			function s4() {
				return Math.floor((1 + Math.random()) * 0x10000)
				.toString(16)
				.substring(1);
			}
			return s4() + s4() + '-' + s4() + '-' + s4() +
			'-' + s4() + '-' + s4() + s4() + s4();
		}
		
		function setFocus(element) {
			$timeout(function () {
				var exists;
				(exists = element)
					&& (exists = exists.find('h1'))
					&& (exists = exists[0])
					&& exists.focus();
			}, 100);
		}
		
		function setComponentFocus(instance) {
			var tagName = instance.constructor.name.replace(/Controller|[A-Z]/g,
				function (match, offset) {
					return (offset > 0 && match != 'Controller' ? '-' : '') + (match != 'Controller' ? match.toLowerCase() : '');
				});
			var element = angular.element($document[0].querySelector( tagName + ' section'));
			setFocus(element);
		}

		function scrollTo(id) {
			$timeout(function () {
				$location.hash(id);

				$anchorScroll();
			}, 150);
		}
		
		function pushDataLayer() {
			if (BrandService.isDefault()) {
				return;
			}
			
			var application = StorageService.get(Constants.SK.Application);
			
			if (application) {	
				dataLayer.push({
					enrollment_id: '' + application.EnrollmentNumber + '',
					state: application.Address.State,
					zip_code: application.Address.ZipCode,
					customer_type: application.DeviceOption.needsPhone ? 'EXISTING' : 'NEW',
                    event: "enrollmentComplete",
					email: application.Applicant.Email,
					email_sha256: CryptoJS.SHA256(application.Applicant.Email).toString(),
					email_md5: CryptoJS.MD5(application.Applicant.Email).toString()
				});
			}
		}
		function getProofStepCode() {
			var stepCode = StorageService.get(Constants.SK.UploadProofStep);
			return stepCode;
		}
		function scrollTop() {
			try {
				$document[0].body.scrollTop = 0;
				$document[0].documentElement.scrollTop = 0;
			} catch (e) {
			}
		}
		
        function find(list, predicate, context) {
            for (var i = 0; i < list.length; i++) {
                if (predicate.call(context, list[i], i, list)) {
                    return list[i];
                }
            }
        }
		
		function any(list, predicate, context) {
            for (var i = 0; i < list.length; i++) {
                if (predicate.call(context, list[i], i, list)) {
                    return true;
                }
            }
			
			return false;
		}
        
        function filter(list,predicate,context) {
            var result = [];
            for(var i = 0; i < list.length; i++) {
                if(predicate.call(context,list[i],i,list)) {
                    result.push(list[i]);
                }
            }
            return result;
        }

		function map(list, func, context) {
			var result = [];
			for(var i = 0; i < list.length; i++) {
				result.push(func.call(context,list[i],i,list));
			}		
			return result;
		}
		
		function each(list, func, context) {
			for (var i = 0; i < list.length; i++) {
				func.call(context, list[i], i, list);
			}
		}
	
		function contains(array, value) {
			for (var i = array.length - 1; i >= 0; i--) {
				if (array[i] === value){
					return true;
				}
			}
			return false;
        }
		
		function distinct(list, predicate, context) {
			var dict = {};
			
			for (var i = 0; i < list.length; i++) {
				var val = predicate.call(context, list[i], i, list);
				
				if (!(val in dict)) {
					dict[val] = null;
				}
			}
			
			return Object.keys(dict);
		}		
        
		function getDate(day, month, year, isBrowserFormat){
			day = day <= 9 ? '0' + day : day;
			month = month <= 9 ? '0' + month : month;

			if (isBrowserFormat) return '{0}-{1}-{2}'.format(year, month, day);
			else return '{0}/{1}/{2}'.format(month, day, year);
		}

		function getDOBFromFormatted(date){		
			var dob;		
			if (isDate(date)) {
				if(date.indexOf('-')) {
					dob = date.split('-');
				}
				else if(date.indexOf('/')) {
					dob = date.split('/');
				}
				
				return {
						Year: dob[0],
						Month: dob[1],
						Day: dob[2]
					};
			}
			else{
			    return null;
			}			
		}

		function isDate(value){
			return value && Constants.Pattern.DATE.test(value);
		}

        function validateForm(formObj){
            if(!formObj || formObj == null) return true;

            if(!formObj.$valid){
                angular.forEach(formObj.$error.required, function(field) {
                    field.$setDirty();                    
                });

                var requiredElements = formObj.$error.required;
                var invalidElements = formObj.$error.pattern;

                var elementToFocus = requiredElements ? requiredElements[0].$$element[0] : invalidElements ? invalidElements[0].$$element[0] : null;

                if(elementToFocus){
                    focusElement(elementToFocus);
                    updateReaderTag(elementToFocus);
                }

                return false;
            }

            return true;
        }

        function resetForm(formObj) {
            var controlNames = filter(Object.keys(formObj), function(key) {
                return key.indexOf('$') !== 0;
            });

            for (var i = 0; i < controlNames.length; i++) {
                var control = formObj[controlNames[i]];
                control.$setViewValue(undefined);
                control.$render();
            }
		
			formObj.$setPristine();
			formObj.$setUntouched();
		}

        function focusElement(element){
            $timeout(function() {
                element.focus();
                element.select ? element.select() : element.click();
            });
        }

        function updateReaderTag(element){
            var readerId = element.id + '-error';
            element.setAttribute("aria-describedby", readerId);
        }

        // State Utils
        function isState(stateCode) {
			// First try using login information
			var application = DTOFactory.Application();
			if (application && application.Address.State) {
				return application.Address.State === stateCode;
			}

			return false;
        }

        function isPR() {
            return isState(Constants.State.PuertoRico);
        }

		function isTX() {
            return isState(Constants.State.Texas);
        }

		function isHI() {
            return isState(Constants.State.Hawaii);
        }

		function isAS() {
            return isState(Constants.State.AmericanSamoa);
        }

        function mapStateToAbreviation(stateName) {
            if (!stateName) return;

            return Constants.State[stateName.replace(/\s/g, '')];
        }
	
        function hasBlockingNladError() {
            var NladError = DTOFactory.NladErrorMessages();

            return NladError.DECEASED;
        }

		function getAllQueryString(){
			return $location.url().split('?')[1] || '';
		}

		function toBase64(strData){
			return $window.btoa(strData);
		}

        function getQueryString(variable, caseInsensitive) {
            
			var value = null;
			
			if (caseInsensitive) {
				var keys = Object.keys($location.search());
				
				for (var i = 0; i < keys.length && !value; i++) {
					var key = keys[i];
					
					if (key.toLowerCase() == variable.toLowerCase()) {
						value = $location.search()[key];
					}
				}
			}
			else {
				value = $location.search()[variable];
			}
			
			return (value || '').toString();
        }

		function goToFaq() {
			goToUrl('FAQ', true);
        }
		
		function goToUrl(url, openInNewTab) {
			var lang = GlobalizationService.getLang();
            $window.open(Constants.Url[url][lang], openInNewTab ? '_blank' : '');
        }		

		function removeLoginFromStorage() {
            StorageService.remove(Constants.SK.AccountLogin);
			StorageService.remove(Constants.SK.Login);
			StorageService.remove(Constants.SK.Token);
			StorageService.remove(Constants.SK.Application);
		}

        function getNatVerApiValues() {
			
			var natVerStatus = DTOFactory.Application().NatVerApiTransactionInfo;
			
			var apiValues = {
				complete: false,
				pendingCertification: false,
				pendingProofApproval: false,
				pendingResolution: false,
				pendingReview: false,
				pendingEligibility: false,
				pendingProof: false,
				errorResponse: false,
				url: null,
				eligibilityCheckId: null,
				initialized: false
			};
			
			if (!natVerStatus) {
				return apiValues;
			}
			
			switch (natVerStatus.status) {
                case Constants.NatVerApi.Result.Complete:
                    apiValues.complete = true;
                    break;
				case Constants.NatVerApi.Result.PendingCertification:
                    apiValues.pendingCertification = true;
					break;
 				case Constants.NatVerApi.Result.PendingResolution:
 					apiValues.pendingResolution = true;
 					break;
				case Constants.NatVerApi.Result.PendingReview:
					apiValues.pendingReview = true;
					break;
 				case Constants.NatVerApi.Result.PendingEligibility:
 					apiValues.pendingEligibility = true;
                    break;
				case Constants.NatVerApi.Result.ServiceUnavailableAcpWindDown:
					apiValues.errorResponse = false;
					break;
                default:
                    apiValues.errorResponse = true;
                    break;
            }
			
			apiValues.url = natVerStatus.url
				|| natVerStatus._links && (natVerStatus._links.certification || natVerStatus._links.resolution).href;
							
			apiValues.eligibilityCheckId = apiValues.eligibilityCheckId;
			
			apiValues.initialized = true;
			
			return apiValues;
        }

		function isProofNeeded(requirements) {

            return any(requirements, function(requirement) {
				return isRequirementNeeded(requirement) && !requirement.isSubmitted;
            });
        }

		function isRequirementNeeded(requirement) {
			return [
					Constants.Requirement.ProgramProof.Code,
					Constants.Requirement.FormerAcpProof.Code,
					Constants.Requirement.IncomeProof.Code,
				]
				.includes(requirement.code)
				&& [Constants.RequirementStatus.Pending, Constants.RequirementStatus.Rejected].includes(requirement.status.code);
		}

		function getRequirementConfiguration(requirement) {
			return Object.keys(Constants.Requirement).reduce(function (acc, req) {
				return acc || (Constants.Requirement[req].Code == requirement.code ? Constants.Requirement[req] : null);
			}, null);
		}

		       
		function areProofsStillNeeded() {
        	var proofs = StorageService.get(Constants.SK.GuidedProofsNeeded);

            if (!proofs) {
				return false;
			}

            var nextProof = find(proofs, function(proof) {
                return !proof.uploaded && !proof.skipped;
            });

            return nextProof;
        }
		

		function isLifelineEnabled() {
			var features = StorageService.get(Constants.SK.FeaturesInformation);
			var qualification = StorageService.get(Constants.SK.Qualification);
			var isLifelineAvailable = false;

			if(qualification && qualification.Type == 'PROGRAM' && qualification.Programs) {
				isLifelineAvailable = qualification.Programs.some(function(x){
					return x.benefitTypeCodes.some(function(y){
						return y == Constants.BenefitType.LIFELINE;
					});
				});				
			}

			if(qualification && qualification.Type == 'INCOME' && qualification.Income) {
				var incomeFields = qualification.Income.Fields;
				isLifelineAvailable = incomeFields.GrossBelowLimitAcp == "0" && incomeFields.GrossBelowLimitLl == "1";
			}

			return features && features.LifeLineBenefit && isLifelineAvailable;
		}

		/**
		 * 
		 * @param {number} num - number to round
		 * @param {number} floatPlaces - decimal places
		 * @returns {number} - number rounded
		 */
		function round(num, floatPlaces){
			var factor = Math.pow(10, floatPlaces);
			return Math.ceil(num * factor) / factor;
		}

		function isNullOrEmpty(value) {
            return !value;
        }
	}
})();
