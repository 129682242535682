(function () {
    'use strict';

    angular.module('l4b')
        .controller('IncomeEligibilityController', IncomeEligibilityController);

    function IncomeEligibilityController($scope, GlobalizationService, Constants, Utils, StorageService, FlowService, DTOFactory, RestService, FeaturesService, $interval) {

        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            nextStep: nextStep,
            back: back,
            incomes: [],
            isTX: Utils.isTX(),
            isHI: Utils.isHI(),
            isAS: Utils.isAS(),
            total: 0,
            addInputValues: addInputValues,
            patterns: setPattern(),
            qualificationDTO: DTOFactory.Qualification(),
            address: DTOFactory.Address(),
            lang: DTOFactory.Lang(),
            selectedOption: null,
            showIncomeTable: false,
            toggleIncomeTable: toggleIncomeTable,
            showIncomeCalc: false,
            toggleIncomeCalc: toggleIncomeCalc,
            incomeSelected: null,
            incomeCaculated: {},
            lifelineEligible: FeaturesService.isLifelineEligible(),
            goHome: goHome,
			isOptionMore: isOptionMore,
            onPAmountChange: onPAmountChange,
            totalGross: '',
            timePeriod: 'yearly',
            calcIncome: calcIncome,
            customIncomeRange: null,
            customTimePeriod: null,
            evalEligibility: evalEligibility,
            isLifelineEnabled: StorageService.get(Constants.SK.FeaturesInformation).LifeLineBenefit,
            application: DTOFactory.Application(),
            isVerizonForwardFlow: false,
            showAmountInput: false,
            allowCustomerSelection: false
        });

        var maxPersons = 0;
        
        function init() {
            vm.isVerizonForwardFlow = vm.application.BenefitTypeCode == Constants.BenefitType.VZF;
            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_INCOME-SELECTION-STARTED'
            );
			
            RestService.getIncomes()
                .then(getIncomesSuccess);
        }

        

        function getIncomesSuccess(response) {
            var error = StorageService.get(Constants.SK.Error);
			if (error) {
                FlowService.goToExit(); 
            }
            StorageService.set(Constants.SK.Quest.Incomes, response.data);
			
			var ranges = response.data.ranges;
            

            maxPersons = ranges.reduce(function (acc, item) {
                return acc < item.persons ? item.persons : acc;
            }, 0);
			
            ranges.forEach(function(income){
                vm.selectedOption = vm.selectedOption || income.code;
                vm.incomes.push(new IncomeItem(income, maxPersons));
            });  

			vm.qualificationDTO.Income.Option = Utils.find(vm.incomes, function (range) {
				return range.code.startsWith('DEFAULT_RANGE');
			});

            vm.allowCustomerSelection = response.data.enableCustomerSelection;

        }

        $scope.$watch(function () {
            return vm.qualificationDTO.Income.Option;
        }, function (newValue, oldValue) {
            if (newValue === oldValue) {
                return;
            }
            vm.showAmountInput = newValue.code.startsWith('OPTMORE');
            vm.amountPeople = newValue.code.startsWith('OPTMORE') ? '' : newValue.PersonsInHousehold;
        });
		
        function nextStep(form) {
            if (!vm.selectedOption || !Utils.validateForm(form)) return;

            var option = (vm.incomeSelected || {}).code || vm.qualificationDTO.Income.Option; 
			var fields = vm.qualificationDTO.Income.Fields;
            fields.GrossBelowLimitLl = vm.totalGross === 'below' ? '1' : '0';
            fields.GrossBelowLimitAcp = vm.totalGross === 'between' ? '1' : '0';
            fields.AmountPeople = vm.amountPeople;            

            var qualification = {
                Type: Constants.QualificationType.Income,
                Programs: null,
                Income: {
                    Option: option,
                    Disclaimer: option,
                    Fields: fields
                }
            };

            StorageService.set(Constants.SK.Qualification, qualification);

			RestService.setQualification(submitQualificationSuccess);
        }

        function submitQualificationSuccess() {
			
            FlowService.nextStep();
        }

        function addInputValues() {
            var inputValues = vm.qualificationDTO.Income.Fields;
            vm.totalValues = 0;

            if (!inputValues || inputValues !== null) {
                for (var x in inputValues) {
                    vm.totalValues += inputValues[x];
                }
            }
        }

        function back() {

            return FlowService.goToProgramEligibility();
        }

        function IncomeItem(income, maxPersons) {
            var self = this;

            this.code = income.code;
            this.id = 'chb' + income.code;

            this.PersonsInHousehold = this.code==='optMore' || this.code.startsWith('OPTMORE')? 
            GlobalizationService.get('MORETHAN') + maxPersons : income.persons;

            income.ranges.forEach(function(range){
				self.MaximumAnnual = range.maxAnnual;
				self.MaximumMonthly = range.maxMonthly;
            });            
        }

        function setPattern() {
            return {
                numeric: Constants.Pattern.NUMERIC,
                numericOne: Constants.Pattern.NUMERIC_ONE,
                numericDec: Constants.Pattern.NUMERIC_DEC,
                numericMill: Constants.Pattern.NUMERIC_MILL,
                numericPeopleAmount: Constants.Pattern.NUMERIC_PEOPLE
            };
        }

        function toggleIncomeTable() {
            vm.showIncomeTable = !vm.showIncomeTable;
        }

        function toggleIncomeCalc() {
            vm.showIncomeCalc = !vm.showIncomeCalc;
        }

        function goHome() {
			FlowService.goHome();
		}
		
		function isOptionMore(income) {
			return (income || vm.incomeSelected) && (income || vm.incomeSelected).code.toLowerCase().startsWith('optmore');
		}

        function onPAmountChange() {
            vm.totalGross = '';
            vm.incomeSelected = vm.incomes.find(function (income) { return income.PersonsInHousehold == vm.qualificationDTO.Income.Fields.AmountPeople; });
            if (vm.incomeSelected == null && vm.qualificationDTO.Income.Fields.AmountPeople > 8) {
                vm.incomeSelected = vm.incomes.find(function (income) { return income.code.toLowerCase().startsWith('optmore'); });
            }

            angular.copy(vm.incomeSelected, vm.incomeCaculated);

            if (vm.qualificationDTO.Income.Fields.AmountPeople > 8) {
                var base = vm.incomes.find(function (i) { return i.PersonsInHousehold == maxPersons; });
                var optmore = vm.incomes.find(function (i) { return i.code == vm.incomeSelected.code; });

                vm.incomeCaculated.MaximumAnnual = base.MaximumAnnual + (optmore.MaximumAnnual * (vm.qualificationDTO.Income.Fields.AmountPeople - maxPersons));
                vm.incomeCaculated.MaximumMonthly = base.MaximumMonthly + (optmore.MaximumMonthly * (vm.qualificationDTO.Income.Fields.AmountPeople - maxPersons));
                vm.incomeCaculated.MaximumAnnualLifeline = base.MaximumAnnualLifeline + (optmore.MaximumAnnualLifeline * (vm.qualificationDTO.Income.Fields.AmountPeople - maxPersons));
                vm.incomeCaculated.MaximumMonthlyLifeline = base.MaximumMonthlyLifeline + (optmore.MaximumMonthlyLifeline * (vm.qualificationDTO.Income.Fields.AmountPeople - maxPersons));
            }

            evalEligibility();
        }

        function calcIncome(yearlyAmount){
            if(vm.timePeriod === 'weekly'){
                return Utils.round(yearlyAmount / 52.1429, 2);
            }
            if(vm.timePeriod === 'monthly'){
                return Utils.round(yearlyAmount / 12, 2);
            }
            if(vm.timePeriod === 'yearly'){
                return yearlyAmount;
            }
        }

        function evalEligibility(){
            var income = calcIncomeYearly(vm.customIncomeRange, vm.customTimePeriod);
            if(!income){
                vm.totalGross = '';
                return;
            }
            if (income<= vm.incomeCaculated.MaximumAnnualLifeline){
                vm.totalGross = 'below';
            }
            else if(income > vm.incomeCaculated.MaximumAnnualLifeline && income <= vm.incomeCaculated.MaximumAnnual){
                vm.totalGross = 'between';
            }
            else
            {
                vm.totalGross = 'above';
            }
        }

        function calcIncomeYearly(amount, timePeriod){
            if(timePeriod === 'weekly'){
                return Utils.round(amount * 52.1429, 2);
            }
            if(timePeriod === 'monthly'){
                return amount * 12;
            }
            if(timePeriod === 'yearly'){
                return amount;
            }
        }
    }

})();
