(function () {
    'use strict';

    angular.module('l4b')
        .service('FeaturesService', FeaturesService);

    function FeaturesService($rootScope, Constants, StorageService) {
        var self = this;
		
        self.get = function () {
            return StorageService.getDefault(Constants.SK.FeaturesInformation, {});
        };

        self.isLifelineEligible = function () {
            return !!self.get().LifeLineBenefit;
        };
        
        self.update = function (data) {
            var features = extract(data);
            var storage = self.get();

            for (var i in features) {
                storage[i] = features[i];
            }

            self.replace(storage);
        };

        self.replace = function (data) {
            var features = extract(data);

            StorageService.set(Constants.SK.FeaturesInformation, features);

            $rootScope.$broadcast('FeaturesServiceChange');
        };

        self.reset = function () {
            self.replace({});
        };

        self.subscribe = function ($scope, callback) {
            $scope.$on('FeaturesServiceChange', callback);

            return self;
        };

        function extract(data) {
            return data.FeaturesInformation || data.featuresInformation || data || {};
        }
    }
})();