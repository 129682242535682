(function () {
	'use strict';

	angular.module('l4b')
		.factory('StepsService', StepsService);

	StepsService.$inject = ['StorageService', 'Constants', 'GlobalizationService', 'Utils', 'BrandService', 'DTOFactory'];

	function StepsService(StorageService, Constants, GlobalizationService, Utils, BrandService, DTOFactory) {

		var service = {
			initSteps: initSteps,
			setCurrentStep: setCurrentStep,
			steps: []
		};

		init();

		return service;

		function initSteps() {

			var descriptions = GlobalizationService.get('STEPS_DESCRIPTIONS');
			var currentBrand = BrandService.getCurrent().Code;
			var brandSteps = {};

			brandSteps[Constants.Brand.VZ.Code] = [
				{
					id: Constants.Step.PersonalInformation,
					pages: [Constants.Page.Landing, Constants.Page.PersonalInformation]
				},
				{
					id: Constants.Step.Address,
					pages: [Constants.Page.Address, Constants.Page.InvalidAddress, Constants.Page.ShippingAddress]
				},
				{
					id: Constants.Step.Eligibility,
					pages: [Constants.Page.IncomeEligibility, Constants.Page.ProgramEligibility, Constants.Page.ApplicantEligibility, Constants.Page.ChildInformation]
				},
				{
					id: Constants.Step.TransferException,
					pages: [Constants.Page.TransferException]
				},
				{
					id: Constants.Step.Review,
					pages: [Constants.Page.Review]
				},
				{
					id: Constants.Step.ApplicationCompleted,
					pages: [Constants.Page.ApplicationCompleted]
				}];

			service.steps.splice(0, service.steps.length);

			angular.forEach(brandSteps[currentBrand], function (step) {
				service.steps.push({
					id: step.id,
					name: Utils.find(descriptions, function (item) {
						return item.stepId == step.id;
					}).name,
					pages: step.pages,
					visible: false
				});
			});

			StorageService.set(Constants.SK.Steps, service.steps);
		}

		function setCurrentStep(stateId) {
			var steps = service.steps;
			var Status = Constants.StepStatus;
			var step;

			if (stateId === Constants.Page.ApplicationCompleted) {
				step = Utils.find(steps, function (obj) {
					return (obj.id == Constants.Page.ApplicationCompleted || obj.id == Constants.Page.EnrollmentStatus);
				});

				if (step) {
					step.status = Status.Done;
				}

			}
			else {
				var found = false;

				for (var i = 0; i < steps.length; i++) {

					step = steps[i];

					step.visible = step.id != Constants.Step.TransferException
						|| !!DTOFactory.Application().NladApplication.UseTransferException;

					// If step is previous to current, then mark as done
					if (!found) {

						var isCurrent = Utils.find(steps[i].pages, function (x) { return x === stateId; });

						if (isCurrent) {
							found = true;

							//Update the current step 
							StorageService.set(Constants.SK.CurrentStep, step.id);

							step.status = Status.Current;
						}
						else {
							step.status = Status.Done;
						}
					}
					else {
						step.status = Status.NotStarted;
					}
				}
			}

			StorageService.set(Constants.SK.Steps, service.steps);
		}

		// Service Locals
		function init() {
			var sessionSteps = StorageService.get(Constants.SK.Steps);

			if (sessionSteps && sessionSteps.length > 0) {
				service.steps = sessionSteps;
			} else {
				initSteps();
			}
		}
	}
})();
