(function () {
    'use strict';

    angular.module('l4b')
        .factory('RequestAdapter', RequestAdapter);

    RequestAdapter.$inject = ['BrandService', 'DTOFactory', 'StorageService', 'Constants', 'Utils', '$cookies', 'GlobalizationService'];

    function RequestAdapter(BrandService, DTOFactory, StorageService, Constants, Utils, $cookies, GlobalizationService) {

        var service = {
            initApplication: initApplication,
            applyEnrollment: applyEnrollment,
            selectIncome: selectIncome,
            acceptDisclaimers: acceptDisclaimers,
            accountLogin: accountLogin,
            getApplication: getApplication,
            updateInfoEnrollment: updateInfoEnrollment,
            updateAddressByApplicant: updateAddressByApplicant
        };

        return service;

        function initApplication() {
			var 
				application = DTOFactory.Application(),
                lang = GlobalizationService.getLang(),
				geolocation = DTOFactory.Geolocation();

            angular.extend(application.ContactInfo, { Email: application.Applicant.Email });
            
            return {
				brand: BrandService.getCurrent(),
                phoneNumber: application.PhoneNumber,
                channel: Constants.Quest.Channel.PublicSite,
                applicationToken: application.Token,
				userAccountId: application.UserAccountId,
                gclId: StorageService.get(Constants.SK.GclId),
                flowExecutionToken: StorageService.get(Constants.SK.FlowExecutionToken),
                location: geolocation,
				applicant: application.Applicant,
				contactInfo: application.ContactInfo,
				address: application.Address,
                lang: lang			
            };
        }

        function applyEnrollment() {
            var application = DTOFactory.Application();
			
			angular.extend(application.ContactInfo, { Email: application.Applicant.Email });
			
            return {
                applicant: application.Applicant,
                contactInfo: application.ContactInfo,
                address: application.Address,
                shippingAddress: application.ShippingAddress,
                lang: getLang(),
				natVerApplicationId: application.NladApplication.NatVerApplicationId
            };
        }

        function selectIncome() {
            var qualification = StorageService.get(Constants.SK.Qualification);
            var questIncomes = StorageService.get(Constants.SK.Quest.Incomes);
			
			var extraFields = [];
			if (questIncomes.extraFields) {
				extraFields = questIncomes.extraFields.map(function (extraField) {
					return {
						extraField: extraField,
						value: qualification.Income.Fields[extraField.code]
					};
				});
			}
				
            var incomeRange = Utils.find(questIncomes.ranges, function (range) {
                return range.code === qualification.Income.Option.code;
            });

            return {
                IncomeRange: incomeRange,
                ExtraFields: extraFields || []
            };
        }

        function acceptDisclaimers() {
            var enrDisclaimers = StorageService.get(Constants.SK.Disclaimers);
            var questDisclaimers = StorageService.get(Constants.SK.Quest.Disclaimers);

            var selectedDisclaimers = questDisclaimers.map(function (questDiscObj) {
                if (enrDisclaimers.includes(questDiscObj.code)) {
                    questDiscObj.isAccepted = true;
                }
				
                return questDiscObj;
            });

            return selectedDisclaimers;
        }

        function accountLogin() {
            var captcha = DTOFactory.Captcha();
            var loginDTO = getLoginParams();

            var leadToken = $cookies.get(Constants.Cookie.Session) || null;

            var flowExecutionToken = StorageService.get(Constants.SK.FlowExecutionToken);

            var data = {
                grant_type: 'applicant',
                captcha_token: captcha.Token,
                external_application_id: loginDTO.ExternalApplicationId,
                zipcode: loginDTO.ZipCode,
                dob: loginDTO.Dob,
                phone_number: loginDTO.PhoneNumber,
                last_name: loginDTO.LastName,
                brand_name: loginDTO.BrandCode,
                lang: loginDTO.Lang,
                tenant_code: Constants.Tenant.VZ,
                lead_token: leadToken,
                flow_execution_token: flowExecutionToken,
                access_token: loginDTO.AccessToken
            };

            return data;
        }

        function getApplication() {
            return getLoginParams();
        }

        function updateInfoEnrollment(applicationDto, addressDto, contactDto) {
            return {
                addressLine1: addressDto.Address.line1,
                addressLine2: addressDto.Address.line2,
                city: addressDto.Address.city,
                state: getStateCode(addressDto.Address.state),
                zipCode: addressDto.Address.zipcode,
                enrollmentId: applicationDto.enrollmentId,
                enrollmentNumber: applicationDto.externalApplicationId,
                firstName: applicationDto.applicant.firstName,
                lastName: applicationDto.applicant.lastName,
                process: applicationDto.process,
                token: applicationDto.token,
                email: contactDto.email,
                otherTelephone: contactDto.phone
            };
        }
        
        function updateAddressByApplicant(addressDTO) {
            addressDTO.Address.state = getStateCode(addressDTO.Address.state);
            if (addressDTO.ShippingAddress != null) {
                addressDTO.ShippingAddress.state = getStateCode(addressDTO.ShippingAddress.state);
            }
            return addressDTO;
        }

        // Local utils

        function getStateCode(stateName) {
            return Constants.State[stateName];
        }

        function getLang() {
            return GlobalizationService.getLang().toUpperCase();
        }

        function getLoginParams() {

            var leadToken = $cookies.get(Constants.Cookie.Session) || null;
            var currentLogin = StorageService.get(Constants.SK.AccountLogin);
            if (currentLogin && currentLogin.session) {
                leadToken = currentLogin.session;
            }

            var loginState = StorageService.get(Constants.SK.LoginStateParams);
            var params = {
                BrandCode: BrandService.getCurrent().Code,
                LeadToken: leadToken,
                AccessToken: loginState && loginState.token
            };
            var dto = StorageService.get(Constants.SK.AccountLogin);

			angular.extend(params, dto);
            
			params.Lang = getLang();

            return params;
        }
    }
})();
