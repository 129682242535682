(function () {
    'use strict';

    createDirectives('brand', brand);
    createDirectives('state', state);


    // Filters

    function brand($injector, value) {
        return getService($injector, 'BrandService')
            .getCurrent().Name
            .equals(value, true);
    }

    function state($injector, value) {
        return getService($injector, 'Utils')
            .isState(value);
    }


    // Main

    var services = {};

    function createDirectives(name, filter) {
        var nameIn = name + 'In';
        var nameNotIn = name + 'NotIn';

        angular.module('l4b')
            .directive(nameIn, createDirective(nameIn, filter, true))
            .directive(nameNotIn, createDirective(nameNotIn, filter, false));
    }

    function createDirective(attrName, filter, expected) {
        return ['$injector', function ($injector) {
            return {
                restrict: 'A',
                link: function (scope, element, attr) {
                    var values = attr[attrName].split(",");

                    var exists = values.some(filter.bind(this, $injector));
                    if (exists !== expected) {
                        element.remove();
                    }
                }
            };
        }];
    }

    function getService($injector, name) {
        services[name] = services[name] || $injector.get(name);

        return services[name];
    }
})();
