(function () {
    'use strict';

    angular.module('l4b')
        .controller('BenefitSelectionController', BenefitSelectionController);

    function BenefitSelectionController(FlowService,RestService) {

        var vm = this;

        angular.extend(vm, {
			$onInit: init,
            nextStep: nextStep,
            selectBenefit: selectBenefit
        });
		
		function init() {
			FlowService.updateCurrentStep();
		}

        function nextStep() {
            FlowService.nextStep();
        }

        function selectBenefit(includeLifeline) {
            RestService.selectBenefit(includeLifeline, onSelectBenefitSuccess);
        }

        function onSelectBenefitSuccess(){
            vm.nextStep();
        }
    }
})();
