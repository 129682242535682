(function() {
	'use strict';
	
	angular.module('l4b')
	.component('datepicker', {
	  templateUrl: 'html/shared/datepicker/datepicker.html',
	  bindings: {
	    underage: '=?',
	    date: '=',
	    onChange: '<',
		parentForm: '<',
		disable: '<',
		yearFrom: '=?',
		disableValidation: '<',
		maskType: '<'
	  },
	  controller: DatepickerController,
	  controllerAs: 'vm'
	});

	DatepickerController.$inject=['Constants', 'Utils', 'StorageService', 'GlobalizationService'];

	function DatepickerController(Constants, Utils, StorageService, GlobalizationService){
		
		var vm = this;
		var YEAR_FROM = 110,
		//YEAR_TO = 18,
		YEAR_TO = 0, //-- allows datepicker.year to display over 18.
		UNDERAGE_LIMIT = 18;
		angular.extend(vm, {
			$onInit: init,
			months: getMonthsArray(),
			days: getDaysArray(),
			years: getYearsArray(),
			invalidDate: true,
			onMonthChange: onMonthChange,
			onYearChange: onYearChange,
			onDayChange: onDayChange,
			$onChanges: onChanges,
			hideSensitive: hideSensitive
		});

		function init() {
			YEAR_FROM = vm.yearFrom ? vm.yearFrom : YEAR_FROM;
			vm.years = getYearsArray();		
		}
		
		/* PRIVATE METHODS */
		
		function isUnderage () {
			return calculateAge() < UNDERAGE_LIMIT;
		}

		function onMonthChange(){
			vm.days = getDaysArray(vm.selectedMonth ? vm.selectedMonth.value : 1, vm.selectedYear, isMinYear(vm.selectedYear));

			validateAndSetDate();
		}

		function onDayChange(){
			validateAndSetDate();
		}
		
		function hideSensitive() {
			return vm.maskType == 'password';
		}

		function onYearChange(){
			var month = vm.selectedMonth ? vm.selectedMonth.value : undefined;
			if(isFebruary(month))
				vm.days = getDaysArray(month, vm.selectedYear);

			vm.days = getDaysArray(month, vm.selectedYear, isMinYear(vm.selectedYear));

			validateAndSetDate();
		}

		function validateAndSetDate(){
			var date = Utils.getDate(vm.selectedDay, vm.selectedMonth ? vm.selectedMonth.value : undefined, vm.selectedYear, true);
			
			vm.invalidDate = !(Utils.isDate(date));

			if(vm.invalidDate) {
				vm.date = '';
			}				
			else {
				vm.date = date;
				vm.underage = isUnderage();
			}

			if(vm.onChange) {
				vm.onChange(vm.date);
			}
		}

		function isLeapYear(year){
			return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
		}

		function getDaysArray(month, year, isMinYear){
			var days = [],
			quantity = 31;

			var currentDay = isMinYear ? new Date().getDate() : null;
			var currentMonth = isMinYear ? new Date().getMonth() + 1 : null;

			if (month === 2) quantity =  year && isLeapYear(year) ? 29 : 28;
			else if (month === 4 || month ===  6 || month === 9 || month === 11) quantity = 30;

			for (var i = 1; i<=quantity; i++){
				if(currentMonth && currentMonth === month && currentDay && i > currentDay) break;
				days.push(i);
			}

			return days;
		}

		function getYearsArray(){
			var years = [],
			currentYear = new Date().getFullYear(),
			to = currentYear - YEAR_TO,
			from = currentYear - YEAR_FROM;

			for (;from<=to ; from ++){
				years.push(from);
			}

			return years;
		}

		function getMonthsArray(isMinYear){
			var months = [];

			var currentMonth = isMinYear ? new Date().getMonth() + 1 : null;
			var translatedMonths = Constants.Month[GlobalizationService.getLang()];

			for (var month in translatedMonths){
				if (currentMonth && translatedMonths[month] > currentMonth) break;

				months.push({
					text: month,
					value: translatedMonths[month]
				});
			}
			return months;
		}

		function isMinYear(year){
			return year === (new Date().getFullYear() - YEAR_TO);
		}

		function isFebruary(month){
			return month === 2;
		}

		function calculateAge(){

			var todayDate = new Date();
			var todayYear = todayDate.getFullYear();
			var todayMonth = todayDate.getMonth();
			var todayDay = todayDate.getDate();
			var age = todayYear - vm.selectedYear; 

			if (todayMonth < vm.selectedMonth.value - 1) {
				age--;
			}

			if (vm.selectedMonth.value - 1 == todayMonth && todayDay < vm.selectedDay) {
				age--;
			}

			return age;
		}

		function onChanges() {
			var date = Utils.getDOBFromFormatted(vm.date);
		    if (date) {
				vm.selectedYear = parseInt(date.Year);
		        vm.selectedDay = parseInt(date.Day);
		        vm.selectedMonth = { value: parseInt(date.Month) };
				
		        vm.underage = isUnderage();
			}
		}
	}
})();