(function () {
    'use strict';

    angular.module('l4b')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['Constants', 'StorageService', 'RestService', 'FlowService', 'Utils', 'DTOFactory', '$state', '$q', '$window', '$cookies',
        '$stateParams', 'MyAccountService', 'FeaturesService', '$rootScope', '$scope', 'GlobalizationService', 'FlowExecutionService', 'BrandService', 'GeolocationService'];

    function HomeController(Constants, StorageService, RestService, FlowService, Utils, DTOFactory, $state, $q, $window, $cookies, $stateParams, MyAccountService,
        FeaturesService, $rootScope, $scope, GlobalizationService, FlowExecutionService, BrandService, GeolocationService) {
        var vm = this,
            lang = GlobalizationService.getLang();

        angular.extend(vm, {
            $onInit: init,
            lang: lang,
            verify: verify,
			application: null,
            goToServicePlans: goToServicePlans,
            goToMyAccount: MyAccountService.GoToMyAccount,
			captchaDTO: DTOFactory.Captcha(),
            phoneNumber: '',
            preLoadedPhoneNumber: false,
            patterns: setPattern(),
            resetPhoneErrors: resetPhoneErrors,
            hideForm: false
        });
		
        function init() {
			
            StorageService.removeAllSession();

			vm.application = DTOFactory.Application();
			
            FlowExecutionService.logFlowExecutionEvent('CUSTOMER_PUBLIC-SITE-HOME_ENTERED-PAGE');
            
			checkActionFromRedirect();
            checkOtherTracking();
        }

        function checkOtherTracking() {
			var gclId = Utils.getQueryString('GclId', true);
			if (gclId) {
				StorageService.set(Constants.SK.GclId, gclId);
			}
		}

        function verify(form, avoidValidation) {
            if (!Utils.validateForm(form) && !avoidValidation) {
                return;
            }

            saveSessionInputs();

			FlowExecutionService
				.onFlowInitialized()
				.finally(function(){
					checkZipCode();
				});
        }

        function goToServicePlans() {
            $state.go(Constants.Page[Constants.SK.ServicePlans]);
        }

        function setPattern() {
            return {
                zipcode: Constants.Pattern.ZIPCODE,
                phone: Constants.Pattern.PHONE,
                email: Constants.Pattern.EMAIL
            };
        }

        /* LOCAL UTILS */

        function checkZipCode() {
		
            RestService.checkZipCode(function (response) {

                var error = StorageService.get(Constants.SK.Error) || '';
                if (hasValidationErrors(error, response.additionalData)) {
                    return;
                }

                MyAccountService.LogOut({ stayInCurrentPage: true });

                FeaturesService.update(response);

                validateFeatures();

				// Set Address DTO
				vm.application.Address.ZipCode = response.number;
				vm.application.Address.State = response.processName;
				vm.application.Address.StateName = response.stateName;
				vm.application.Address.City = response.citiesFromState && response.citiesFromState[0] ? response.citiesFromState[0].CityName || response.citiesFromState[0].name : null;

				vm.application.Token = response.applicationToken;
				StorageService.set(Constants.SK.Application, vm.application);
              
                FlowService.nextStep();
            }, true);
        }

        function hasValidationErrors(error, additionalData) {
            
			if (!error) {
				return false;
			}
			
			switch (error) {
				case Constants.Error.NOT_AUTHENTICATED:
					$rootScope.$broadcast('show-air-message', {
						section: Constants.Section.LinkNotAuthenticated,
						loginUrl: additionalData
					});
					
					break;
					
				case Constants.Error.INVALID_OR_EXPIRED_ACCESS_TOKEN:
					break;
				
				default:
					FlowService.goToExit();
			}
			
            return true;
        }

        function saveSessionInputs() {
			
			vm.application.PhoneNumber = vm.phoneNumber;
			
			StorageService.set(Constants.SK.Application, vm.application);
			
			StorageService.set(Constants.SK.Captcha, vm.captchaDTO);
        }
		
        function checkActionFromRedirect() {
            var tokenParam = Utils.getQueryString('token');

            if (tokenParam) {
                vm.hideForm = true;
				RestService
					.validateAccessToken(tokenParam)
					.then(function(response) {

						var error = StorageService.get(Constants.SK.Error) || '';
						if (hasValidationErrors(error, response.additionalData)) {
							return;
						}
						
						// Access token has been successfully validated
						var data = response.data;
						
						vm.preLoadedData = true;

            			vm.application.Address.Line1 = data.address;
            			vm.application.Address.Line2 = data.address2;
            			vm.application.Address.City = data.city;
            			vm.application.Address.State = data.state;
            			vm.application.Address.ZipCode = data.zipCode;

						vm.application.AccessToken = tokenParam;
						
						StorageService.set(Constants.SK.Application, vm.application);
						
						vm.phoneNumber = data.phoneNumber;
						
						if (data.zipCode) {
							vm.application.Address.ZipCode = data.zipCode;
							saveSessionInputs();
							checkZipCode();
						}
					});
            }			
        }
		
        function checkGeolocationAvailable() {

            var deferred = $q.defer();

            GeolocationService.register(true)
                .then(function (id) {
                    navigator.geolocation.clearWatch(id);
                    deferred.resolve();
                })
                .catch(function () {
                    StorageService.set(Constants.SK.Error, Constants.Error.LOCATION_NOT_ENABLED);
                    deferred.reject();
                });

            return deferred.promise;
        }

        function validateFeatures(){

            var featureRequests = [];

            if (FeaturesService.get().RequestGeolocationAuth) {                  
                featureRequests.push(checkGeolocationAvailable());
            }            

            Promise.all(featureRequests)
            .catch(function(){                    
                FlowService.goToExit();
            });

        }

        function resetPhoneErrors() {
            $scope.homeForm.attNumberValidation.$setValidity('pattern', null);
            $scope.homeForm.attNumberValidation.$setValidity('invalidPrepaidLine', null);
        }
    }
})();
