(function () {
	'use strict';

	angular.module('l4b')
		.component('timeline', {
			templateUrl: 'html/modules/_components/timeline/timeline.html',
			bindings: {
				showDetails: '<'
			},
			controller: TimelineController,
			controllerAs: 'vm'
		});

	function TimelineController(DTOFactory, Constants, GlobalizationService) {
		var vm = this;

		angular.extend(vm, {
			$onInit: init,
            isVerizonForwardFlow: false,
            getStatusLabel: getStatusLabel
		});
		
		function init() {
			var enrollment = DTOFactory.Application();
			vm.isVerizonForwardFlow = enrollment.BenefitTypeCode == Constants.BenefitType.VZF;
			vm.timeline = parseTimeline(enrollment);
		}
		
        function parseTimeline(enrollment) {
            var monthNames = [];
            var translatedMonths = Constants.Month[GlobalizationService.getLang()];
            for (var prop in translatedMonths) {
                if (translatedMonths.hasOwnProperty(prop)) {
                    monthNames[translatedMonths[prop] - 1] = prop;
                }
            }

            var events = [];
            var dict = {};

            if (enrollment.StatusChanges) {
                events = enrollment.StatusChanges;
            }

            var timeline = [];

            events = events.sort(function (a, b) {

                if (b.date > a.date) {
                    return -1;
                }
                else if (b.date < a.date) {
                    return 1;
                }

                return 0;
            });

            for (var i = 0; i < events.length; i++) {
                var element = events[i];
                if (element.Date) {
                    var date = new Date(element.Date),
                        day = date.getDate(),
                        month = date.getMonth(),
                        year = date.getFullYear(),
                        my = year.toString() + month.toString(),
                        ymLabel = monthNames[month].slice(0, 3).toUpperCase() + '-' + year.toString(),
                        dLabel = ('0' + day.toString()).slice(-2);

                    if (element.Status) {
                        if (!dict[my]) {
                            timeline.push({
                                Type: 'ym-cell',
                                Date: new Date(year, month),
                                DateLabel: ymLabel
                            });
                            dict[my] = {};
                        }

                        var stylePart = {};
                        if (!dict[my][day]) {
                            stylePart = {
                                Type: 'd-cell',
                                DateLabel: dLabel
                            };
                            dict[my][day] = {};
                        } else {
                            stylePart = {
                                Type: 'cell'
                            };
                        }

                        timeline.push(angular.extend({
                            Date: date,
                            Status: {
                                Code: element.Status.Code,
                                Name: element.Status.Name,
                                Style: getStatusStyle(element.Status.Code)
                            },
                            StatusChangeSource: element.StatusChangeSource ? GlobalizationService.get('SOURCE') + ': ' + element.StatusChangeSource : '',
                            StatusChangeReason: element.StatusChangeReason
                        }, stylePart));
                    }
                }
            }

            return timeline;
        }
		
		function getStatusStyle(statusCode) {
            switch (statusCode) {
                case Constants.Status.QUALIFIED:
                case Constants.Status.ENROLLED:
                    return 'qualified';

                case Constants.Status.UNQUALIFIED:
                case Constants.Status.REJECTED:
                case Constants.Status.DEENROLLED:
                    return 'error';

                case Constants.Status.PENDING:
                    return 'warning';

                default:
                    //the default behaviour must be BLACK
                    return '';
            }
        }

        function getStatusLabel(item) {
            switch (item.Status.Code) {
                case Constants.Status.UNQUALIFIED:
                case Constants.Status.REJECTED:
                    return GlobalizationService.get('NOT_ELIGIBLE');

               
                default:
                    return item.Status.Name;
            }
        }
	}
})();