(function () {
	'use strict';

	angular.module('l4b')
		.component('requiredDocumentationSubmitSuccess', {
			templateUrl: 'html/shared/requiredDocumentationSubmitSuccess/requiredDocumentationSubmitSuccess.html',
			bindings: {
				nextStep: '&'
			},
			controller: RequiredDocumentationSubmitSuccessController,
			controllerAs: 'vm'
		});

	RequiredDocumentationSubmitSuccessController.$inject = ['RestService', 'Constants', 'GuidedProofService', 'FlowService', 'StorageService'];

	function RequiredDocumentationSubmitSuccessController(RestService, Constants, GuidedProofService, FlowService, StorageService) {
		var vm = this;

		angular.extend(vm, {
			$onInit: init,
			submittedProofs: GuidedProofService.GetSubmittedProofs(true),
			next: next,
			showContinueBtn: false
		});

		function init() {
			vm.showContinueBtn = Constants.Page.MyAccountSubmitDocumentation !== FlowService.getCurrentState();
			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_SUCCESFULLY_SUBMITTED_PRESENTATION_STARTED);
			var app = StorageService.get(Constants.SK.Application);
			app.hasPendingProof = false;
			StorageService.set(Constants.SK.Application, app);
		}		

		function next() {

			RestService.trackApplicationEvent(Constants.Event.Telesales.SYSTEM_IDENTITY_PROOF_SUCCESFULLY_SUBMITTED_PRESENTATION_COMPLETED);

			vm.nextStep();
		}
	}
})();