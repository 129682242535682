(function () {
    'use strict';

    angular.module('l4b')
        .filter('where', function () {
            return function (items, prop, value) {
                return (Array.prototype.isPrototypeOf(value) ?
                    items.filter(function (f) { return value.includes(f[prop]); })
                    : items.find(function (f) { return f[prop] == value; }));
            };
        });
})();