(function () {
    'use strict';

    angular.module('l4b')
        .controller('MyAccountMessageController', MyAccountMessageController);

    MyAccountMessageController.$inject = ['$stateParams', 'Constants', 'MyAccountService', '$scope'];

    function MyAccountMessageController($stateParams, Constants, MyAccountService, $scope) {
        var vm = this;

        angular.extend(vm, {
            $onInit: init,
            goBack: goBack,
            messageKey: ''
        });

        function init(){
            if (!$stateParams) {
				return goBack();
			}

            vm.messageKey = $stateParams.messageKey;

            // Update parent controller to highlight the tab.
            if ($stateParams.tab) {
                $scope.$parent.vm.activeTab = $stateParams.tab;
			}
        }

        function goBack() {
            $scope.$parent.vm.activeTab = null;
            MyAccountService.GoTo(Constants.Page.MyAccountDetails);
        }
    }
})();