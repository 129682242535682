(function () {
	'use strict';

	angular.module('l4b')
		.component('enrollmentShow', {
			templateUrl: 'html/shared/enrollmentShow/enrollmentShow.html',
			bindings: {
				enrollmentNumber: '<'
			},			
			controller: EnrollmentShowController,
			controllerAs: 'vm',
			
		});

	EnrollmentShowController.$inject = ['Constants', 'StorageService', 'FlowService'];

	function EnrollmentShowController(Constants, StorageService, FlowService) {

		var vm = this;

		angular.extend(vm, {
			goToMyAccount: goToMyAccount
		});

		function goToMyAccount() {
			FlowService.goToMyAccount();
		}
	}
})();
