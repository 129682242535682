(function() {
	'use strict';
	
	angular.module('l4b')
	.component('enrollmentHeader', {
	  templateUrl: 'html/shared/header/enrollmentHeader.html',
	  controller: HeaderController,
	  controllerAs: 'vm'
	});
	
	HeaderController.$inject = ['FlowService', '$rootScope'];

	function HeaderController(FlowService, $rootScope) {
		var vm = this;

		angular.extend(vm,{
			goHome: goHome
		});

		function goHome() {
			FlowService.goHome();

			// if there is an air message displayed, it must be closed after redirecting.
			$rootScope.$broadcast('close-air-message');
		}
	}	
})();
