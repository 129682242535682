(function () {
    'use strict';

    angular.module('l4b')
        .controller('ReviewApplicationController', ReviewApplicationController);

    ReviewApplicationController.$inject = ['$stateParams', '$window', 'FlowService', 'RestService', 'StorageService', 'Constants', 'DTOFactory', 'Utils', 'FeaturesService', 'GlobalizationService', 'BrandService'];

    function ReviewApplicationController($stateParams, $window, FlowService, RestService, StorageService, Constants, DTOFactory, Utils, FeaturesService, GlobalizationService, BrandService) {

        var vm = this,
            hasPreviousShippingAddress = false,
			qualification = DTOFactory.Qualification();

        angular.extend(vm, {
            $onInit: initCreationFlow,
            features: FeaturesService.get(),
            application: DTOFactory.Application(),
            applicant: DTOFactory.Applicant(),
            accountLoginDTO: DTOFactory.AccountLogin(),
            isVerizonForwardFlow:  DTOFactory.Application().BenefitTypeCode == Constants.BenefitType.VZF,
			dependent: StorageService.get(Constants.SK.Dependent),
            address: DTOFactory.Address(),
            shippingAddressDto: {},
            submitDisclaimers: submitDisclaimers,
            selectedOptions: [],
            submitShippingAddress: submitShippingAddress,
            cancelShippingAddress: cancelShippingAddress,
            startEdition: startEdition,
            isPR: Utils.isPR(),
			todaysdate: new Date(),
			Constants: Constants,
            toolTipExamples: false,
            lang: DTOFactory.Lang(),
            brand: BrandService.getCurrent(),
            disclosures: {},
			showQualificationInformation: qualification.Type,
			fullName: '',
			fullnameChanged: fullnameChanged,
            // maskUserData: false,
            // maskUserDataValue: 'password',
            // toggleMaskData: toggleMaskData,
			checkDisclaimer: checkDisclaimer
        });

        function initCreationFlow() {

            validateApplicationOnSession();

            RestService.trackApplicationEvent(
                'SYSTEM_APPLICATION-CREATION_APPLICATION-REVIEW-ESIGN-STARTED'
            );

            angular.element($window).on('beforeunload', cancelShippingAddress);
			
			vm.fullNameParam = vm.applicant.FirstName + ' ' + vm.applicant.LastName;

            setShippingAddressData();
            getDisclaimers();
            setSelectedPrograms();
        }

        function submitDisclaimers(form) {
            if (!Utils.validateForm(form)) return;

            RestService.acceptDisclaimers(submitDisclaimersSuccess);
        }

        function submitDisclaimersSuccess() {
            if (StorageService.get(Constants.SK.Error)) {
                return FlowService.nextStep();
            }

			RestService.createApplication(function(application) {
				StorageService.set(Constants.SK.Application, application);
				
				FlowService.nextStep(); 
			});
        }

        function startEdition() {
            FlowService.goToPersonalInformation();
        }

        function getDisclaimers() {
            RestService.getDisclaimers(onSuccessGetDisclaimers);
            RestService.getDisclosures(onSuccessGetDisclosures);
            RestService.getLegalNotes(onSuccessGetLegalNotes);
        }

        function onSuccessGetDisclosures(data) {
            vm.disclosures = data;
        }

        function onSuccessGetDisclaimers(data) {
            vm.disclaimers = data;
        }

        function onSuccessGetLegalNotes(data) {
            vm.legalNotes = data;
        }

        function onSuccessSubmitShippingAddress(data) {
            if (data && data.status) {
                vm.showErrorMessageGeneric = false;

                if (data.status === Constants.Status.OK) {
                    StorageService.set(Constants.SK.ShippingAddress, vm.shippingAddressDto);
                    setShippingAddressData();
                    vm.showShippingAddress = false;
                }
                else {
                    vm.showErrorMessageGeneric = true;
                }

            } 
			else {
                vm.showErrorMessageGeneric = true;
            }
        }

        function submitShippingAddress() {
            RestService.validateShippingAddress(onSuccessSubmitShippingAddress);
        }

        function cancelShippingAddress() {
            vm.showShippingAddress = false;
            vm.showErrorMessageGeneric = false;

            // Restore previous shipping address or remove the item
            var rollback = StorageService.get(Constants.SK.ShippingAddressRollback);

            if (rollback) {
                StorageService.set(Constants.SK.ShippingAddress, rollback);
            } 
			else if (!hasPreviousShippingAddress) {
                StorageService.remove(Constants.SK.ShippingAddress);
            }

            setShippingAddressData();
        }

        function setShippingAddressData() {
            var shippingAddress = DTOFactory.ShippingAddress();

            hasPreviousShippingAddress = shippingAddress ? true : false;

            if (shippingAddress) {
                vm.shippingAddressDto = shippingAddress;

                // Save rollback data before proceeding
                StorageService.set(Constants.SK.ShippingAddressRollback, shippingAddress);
            }
            else {
                vm.shippingAddress = angular.copy(vm.address);
                // vm.shippingAddress.Line1 = '';
            }
        }

        function setSelectedPrograms() {
			if (!qualification.Type) {
				return;
			}

            if (qualification) {
                if (qualification.Type === Constants.QualificationType.Program) {
                    vm.selectedOptions = qualification.Programs;
                }
                else {
                    vm.selectedOptions = [{ text: (GlobalizationService.getLang() == Constants.Language.English) ? 'Income' : 'Ingreso' }];
                }
            }
        }
        
        function validateApplicationOnSession(){
            //If there is no application data user will be redirected to the login
            if(!vm.application.ApplicationToken) {
                FlowService.goToLogin();
            }

            //If for some reason the users goes back to the review page and already has an ER Number will be redirect to my account to avoid double save      
            if(vm.application && vm.application.EnrollmentNumber) {
                var hasAddressInfo = vm.application.Address && vm.application.Address.ZipCode;
                var hasApplicantInfo = vm.application.Applicant && vm.application.Applican.LastName;

                if(hasAddressInfo && hasApplicantInfo) {
                    vm.accountLoginDTO.ZipCode = vm.application.Address.ZipCode; 
                    vm.accountLoginDTO.Dob = vm.application.Applicant.DOB;
                    vm.accountLoginDTO.LastName = vm.application.Applicant.LastName;
                    vm.accountLoginDTO.ExternalApplicationId = vm.application.EnrollmentNumber;
                    vm.accountLoginDTO.ForgotEnrollment = false;
                    StorageService.set(Constants.SK.AccountLogin, vm.accountLoginDTO);
                    var loginStateParams = { token: vm.application.AccessToken };
                    StorageService.set(Constants.SK.LoginStateParams, loginStateParams);
        
                    FlowService.goToMyAccount({tabSelected: Constants.Page.MyAccountDetails});
                } 
                //If we dont have address and applicant Info redirect to login
                else {
                    FlowService.goToLogin();
                }


            }

        }

        function fullnameChanged(form){
            var input = form.fullName.$modelValue && form.fullName.$modelValue.toUpperCase();
            form.fullName.$setValidity('validationFullnameError', input == vm.fullNameParam.toUpperCase());
        }		
		
        function checkDisclaimer() {
			
			var selection = [];
			
			if (vm.agreeDisclaimer) {
				selection = Utils.map(vm.disclaimers, function(x) {
					return x.code;
				});
			}

            StorageService.set(Constants.SK.Disclaimers, selection);
        }		

        // function toggleMaskData() {

        //     vm.maskUserData = !vm.maskUserData;
        //     vm.maskUserDataValue = vm.maskUserData ? 'password' : 'text';
        // }
    }
})();
