(function (){
    'use strict';

    angular.module('l4b')
		.component('optionListDisclaimers', {
			templateUrl: 'html/modules/apply/_components/optionListDisclaimers/optionListDisclaimers.html',
			bindings: {
                isByopAvailable: '@'
			},
			controller: OptionListDisclaimers,
			controllerAs: 'vm'
		});
	
	function OptionListDisclaimers() {
        var vm = this;

		angular.extend(vm, {
            isByopAvailable: false
		});
	}
})();