(function () {
	'use strict';

	angular.module('l4b')
		.component('success', {
			templateUrl: 'html/shared/success/success.html',
			bindings: {
				enrollmentNumber: '<',
				setApiValues: '='
			},
			controller: SuccessController,
			controllerAs: 'vm'
		});

	function SuccessController(Constants, Utils, StorageService, FlowService, DTOFactory, RestService, FeaturesService, $window, $rootScope, GlobalizationService) {

		var vm = this;

		angular.extend(vm, {
			$onInit: init,
			
			goToMyAccount: goToMyAccount,
			goHome: FlowService.goHome,
			goToNvApiPortal: goToNvApiPortal,
			goToVerizon: goToVerizon,
			apiValues: null,
			isVerizonForwardFlow: null,
			isSuccess: false
		});

		
		
		function init() {
			var config = StorageService.get(Constants.SK.Configuration);
            vm.isAcpWindDownEnabled = config.isAcpWindDownEnabled;
            
			vm.setApiValues = setApiValues;
			vm.application = DTOFactory.Application();
			vm.isVerizonForwardFlow = vm.application.BenefitTypeCode == Constants.BenefitType.VZF;
			var proofCodes = [Constants.Requirement.ProgramProof, Constants.Requirement.IncomeProof, Constants.Requirement.FormerAcpProof.Code,];
			vm.hasPendingProof = vm.application.Requirements.some(function(x){ return proofCodes.includes(x.code) });
			setApiValues();
		}
		
		function setApiValues() {

			vm.apiValues = Utils.getNatVerApiValues();
			
			var natVerErrors = vm.apiValues.errorResponse 
				|| vm.apiValues.pendingCertification 
				|| vm.apiValues.pendingEligibility 
				|| vm.apiValues.pendingResolution 
				|| vm.apiValues.pendingReview;

			vm.isSuccess = !natVerErrors;
			var config = StorageService.get(Constants.SK.Configuration);
			var verizonForwardNewCustomerAllowedBusinessLines = config.verizonForwardNewCustomerAllowedBusinessLines.split(',');
			var accTokenData = StorageService.get(Constants.SK.AccessTokenData);
			var isManualFlow;
			if(accTokenData) {
				isManualFlow = accTokenData.IsVerizonManualFlow;
			} else {
				isManualFlow = vm.application.AccessTokenDto.IsVerizonManualFlow;
			}
		    vm.isVerizon3Flow = vm.isVerizonForwardFlow && verizonForwardNewCustomerAllowedBusinessLines.includes(vm.application.BusinessLineCode) && !isManualFlow;
			
			var allowRedirect = !vm.isVerizonForwardFlow || (vm.isVerizon3Flow && config.allowRedirectOnVZF3);

			if(vm.isSuccess && allowRedirect) {
				
				$rootScope.$broadcast('show-air-message', {
                    message: GlobalizationService.get('AIR_MESSAGE_COUNTDOWN_TEXT'),
                    showButton: false,
                    showCounter: true,
                    counterSeconds: config && config.redirectTimeoutInSeconds,
                    callback: function() {
						goToVerizon();
                    }
                });
			}
		}
	
		function goToNvApiPortal() {
			RestService.trackApplicationEvent(Constants.Event.NatVerApi.NATVER_REDIRECT_APP_NOT_FOUND_OR_NOT_COMPLETE);

            if (vm.apiValues && vm.apiValues.url) {
				$window.location.href = vm.apiValues.url;
				return;
            } 

			FlowService.goToCheckEbb();
		}

		function goToMyAccount() {
			StorageService.set(Constants.SK.LandingEnrollmentNumber, vm.enrollmentNumber);
			FlowService.goToLogin();
		}

		function goToVerizon() {
            var event;
			var redirectUrl = vm.application ? vm.application.RedirectUrl : null;
            switch(vm.application.Status.Code) {
                case Constants.Status.QUALIFIED:
                    event = "QUALIFIED_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.PENDING:
                    event= "PENDING_REDIRECT_TO_VERIZON";
                    break;
                case Constants.Status.REJECTED:
                case Constants.Status.UNQUALIFIED:
                    event = "UNQUALIFIED_REDIRECT_TO_VERIZON";
                    break;
            }

            if(event) {
                RestService.trackApplicationEvent(
                    event,
					redirectUrl
                );
            }

			$window.location.href = redirectUrl;
		}
	}
})();
