(function () {
    'use strict';

    angular.module('l4b')
        .factory('GTMService', GTMService);

    GTMService.$inject = ['Constants', 'DTOFactory', 'uuid'];

    function GTMService(Constants, DTOFactory, uuid) {

        var service = {
            pushDataLayer: pushDataLayer,
            pushApplicationCompleted: pushApplicationCompleted
        };

        init();
        
        function init() {
            
        }
        
        function pushDataLayer(payload) {
            dataLayer.push(payload);
        }

        function pushApplicationCompleted() {

            var application = DTOFactory.Application();

            var eventUuid = uuid.v4();

            var event = {
                'event' : 'applicationCompleted',
                'event_uuid' : eventUuid,
                'enrollment_id' :  '' + application.EnrollmentNumber + '',
                'lead_id' : application.ApplicationToken
            };

            pushDataLayer(event);
        }

        return service;
    }
})();