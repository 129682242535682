(function () {
    'use strict';

    angular.module('l4b')
        .factory('DefaultAuthProvider', DefaultAuthProvider);

    function DefaultAuthProvider(BrandService, FlowService) {

        return {
            authorize: authorize
        };

        function authorize() {
            if (BrandService.isDefault()) {
                return true;
            }

            FlowService.goHome();

            return false;
        }
    }
})();
