(function () {
    'use strict';

    angular.module('l4b')
        .controller('ShippingAddressMyAccountController', ShippingAddressMyAccountController);

    ShippingAddressMyAccountController.$inject = ['StorageService', 'Constants', 'DTOFactory', 'MyAccountService', 'Utils'];

    function ShippingAddressMyAccountController(StorageService, Constants, DTOFactory, MyAccountService, Utils) {
		var 
			vm = this,
			updateAddressDTO = DTOFactory.UpdateAddress();

        angular.extend(vm, {
            $onInit: init,
            nextStep: nextStep,
            cancel: cancel,
            shippingAddressDTO: null
        });

        function init() {
			var shippingAddressDTO = angular.copy(updateAddressDTO.ShippingAddress || updateAddressDTO.Address);
			shippingAddressDTO.Line1 = '';
			shippingAddressDTO.Line2 = '';
			
			vm.shippingAddressDTO = shippingAddressDTO;
        }

        function nextStep(shippingAddressForm) {
			if (!Utils.validateForm(shippingAddressForm)) {
				return;
			}		
			
			updateAddressDTO.ShippingAddress = vm.shippingAddressDTO;

			StorageService.set(Constants.SK.UpdateAddress, updateAddressDTO);			
			
			MyAccountService
				.UpdateInfo()
				.then(onSuccessUpdateInfo);
		}

		function onSuccessUpdateInfo(response) {
            var error = StorageService.get(Constants.SK.Error);
            if (error) {
				validateError(error);

				return;
            }
			
        	MyAccountService.GoTo(Constants.Page.MyAccountMessage, {
				messageKey: response,
				tab: Constants.Page.MyAccountUpdate
        	});		
		}
		
		function validateError(error) {

			if (error == Constants.Error.INVALID_SHIPPING_ADDRESS) {
				MyAccountService.GoTo(Constants.Page.MyAccountShippingAddress, {
					tab: Constants.Page.MyAccountUpdate
				});
				
				return;
			}
			
			if (error == Constants.Error.INVALID_SHIPPING_ADDRESS) {
				
				MyAccountService.GoTo(Constants.Page.MyAccountMessage, {
					messageKey: 'addressBlacklisted',
					tab: Constants.Page.MyAccountUpdate
				});
				
				return;
			}
		}	

        function cancel() {
            MyAccountService.GoTo(Constants.Page.MyAccountUpdate);
        }
    }
})();