(function () {
    'use strict';

    angular
        .module('l4b')
        .factory('ModalService', ModalService);

    function ModalService() {
        var modals = [];
        var service = {};

        service.Add = Add;
        service.Remove = Remove;
        service.Open = Open;
        service.Close = Close;

        return service;

        function Add(modal) {
            modals.push(modal);
        }

        function Remove(id) {
            modals = modals.filter(function (modal) { return modal.id != id; });
        }

        function Open(id) {
            var modal = modals.find(function (modal) { return modal.id == id; });
            modal.open();
        }

        function Close(id) {
            var modal = modals.find(function (modal) { return modal.id == id; });
            modal.close();
        }
    }

})();