(function () {
    'use strict';

    angular.module('l4b')
    .component('legalNotes', {
        templateUrl: 'html/shared/legalNotes/legalNotes.html',
        bindings: {
            parentForm: '<',
            legalNotes: '<'
        },
        controller: LegalNotesController,
        controllerAs: 'vm'
    })
    .filter('trustAs', ['$sce', 
        function($sce) {
            return function (input, type) {
                if (typeof input === "string") {
                    return $sce.trustAs(type || 'html', input);
                }
                console.log("trustAs filter. Error. input isn't a string");
                return "";
            };
        }
    ]);
    
    LegalNotesController.$inject = ['Constants', 'StorageService', 'FlowService', 'BrandService', '$transitions', 'RestService', '$scope'];

    function LegalNotesController(Constants, StorageService, FlowService, BrandService, $transitions, RestService, $scope) {
        var vm = this;
        var eventListener = null;
        angular.extend(vm, {
            $onInit: init,
            $onDestroy: destroy,
            legalNotes: []
        });

        var currentLoadSequence = 0;

        function init() {
            
            $scope.$on('$stateChangeSuccess', function() {
                loadData();
            });

            loadData();
        }

        function loadData() {
            currentLoadSequence++;
            RestService.getLegalNotes(wrapSuccessHandler(currentLoadSequence));
        }

        //  hack to workaround out of order results
        function wrapSuccessHandler(callSequence) {
            return function(data) {
                if(currentLoadSequence == callSequence) {
                    onSuccessGetLegalNotes(data);
                }
            };
        }

        function onSuccessGetLegalNotes(data){
            vm.legalNotes = data;
        }

		function destroy() {
			if (eventListener != null) {
				eventListener();
            }
		}
    }
})();
