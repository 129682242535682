(function () {
    'use strict';

    angular.module('l4b')
        .component('requiredDocumentationReview', {
            templateUrl: 'html/shared/requiredDocumentationReview/requiredDocumentationReview.html',
 			bindings: {
				nextStep: '&'
			},			
			controller: RequiredDocumentationReviewController,
			controllerAs: 'vm'
        });		

    RequiredDocumentationReviewController.$inject = ['GuidedProofService', 'StorageService', 'Constants', '$window', '$sce'];

    function RequiredDocumentationReviewController(GuidedProofService, StorageService, Constants, $window, $sce) {

        var vm = this;

        angular.extend(vm, {
			$onInit: init,
            proofData: GuidedProofService.GetCurrentProofData(),
            errorMessage: null,
            isSuccess: true,
            confirmProof: confirm,
            discardProof: discardProof
        });
		
		function init() {
						
			if (vm.proofData.isPhoto) {

				var picture = StorageService.localGet(Constants.SK.CameraData);
				
				if (!picture) {
					discardProof();
					return;
				}
				
				var picContent = picture ? picture.split(',')[1] : null;
				vm.data = {
					fullContent: picture,
					ContentType: "image/",
					Content: picContent
				};
			}
			else {

				var fileData = StorageService.get(Constants.SK.FileData);

				var error = !fileData || fileData.error;
				if (error) {
					vm.isSuccess = false;
					vm.errorMessage = fileData.error;
					return;
				}
				var isPdf = isPdfType(fileData.ContentType)
				var blobUrl = isPdf ? createBlobUrl(fileData.Content, fileData.ContentType) : null;
				
				if(blobUrl){
					//without this angular refuses to add this blob url as src on the embed tag (or any other tag)
					blobUrl = $sce.trustAsResourceUrl(blobUrl);
				}

				vm.data = {
					fullContent: fileData.fullContent,
					ContentType: fileData.ContentType,
					Content: fileData.Content,
					blobUrl: blobUrl,
					FileName: fileData.FileName,
					hasPreview: !isTifType(fileData.ContentType),
					isPdf: isPdf
				};
			}			
		}

		function createBlobUrl(b64Data, contentType){
			var byteCharacters = $window.atob(b64Data);
			var sliceSize = 512;
			var byteArrays = [];
		  
			for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				var slice = byteCharacters.slice(offset, offset + sliceSize);
		  
				var byteNumbers = new Array(slice.length);
			  	for (var i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
			  	}
		  
				var byteArray = new $window.Uint8Array(byteNumbers);
			 	byteArrays.push(byteArray);
			}
			  
			var blob = new Blob(byteArrays, {type: contentType});
			return $window.URL.createObjectURL(blob);
		}

		function isTifType(content) {
			return content.includes('image/tif');
		}

		function isPdfType(content) {
			return content.includes('application/pdf');
		}
        function confirm() {
            if (vm.proofData.isPhoto) {
                vm.data.fullContent = null;
            }
			
            GuidedProofService.ConfirmReviewProof(vm.data);
			
			vm.nextStep({ options: { doSubmit: true }});
			
			
        }

        function discardProof() {
            GuidedProofService.DiscardReviewProof();
			
			vm.nextStep();
        }
    }
})();
