(function () {
    'use strict';

    angular.module('l4b')
        .factory('MyAccountAuthProvider', MyAccountAuthProvider);

    function MyAccountAuthProvider($state, Constants, StorageService) {

        return {
            authorize: authorize
        };

        function authorize(toState, toParams) {
            var isAuthenticated = StorageService.get(Constants.SK.AccountLogin);
            if (isAuthenticated) {
				return true;
            }

            var params = Object.assign({}, toParams);
            if (!params.tabSelected) {
                params.tabSelected = toState.name == Constants.Page.MyAccount 
					? Constants.Page.MyAccountDetails
					: toState.name;
            }
			
            $state.go(Constants.Page.Login, params);

            return false;
        }
    }
})();
