
(function() {
	'use strict';
	
	angular.module('l4b')
	.component('captcha', {
	  templateUrl: 'html/shared/captcha/captcha.html',
	  bindings: {
	    token: '=',
	    parentForm: '<',
		reload: '=',
		required: '='
	  },
	  controller: CaptchaController,
	  controllerAs: 'vm'
	});

	function CaptchaController(Constants, GlobalizationService, vcRecaptchaService, $rootScope, $document, $timeout) {
        var vm = this;

        angular.extend(vm, {
            // Constants
            Constants: Constants,

            // Properties
            widget: null,
			lang: GlobalizationService.getLang(),
            // Methods
            $onInit: onInit,
            reloadCaptcha: reloadCaptcha,
            setResponse: setResponse,
            onCreate: onCreate,
            onExpired: onExpired
        });

        function onInit() {
            vm.reload = reloadCaptcha;
			
        }

		function setAttributes(retry) {
			
			if (retry >= 3) {
				throw 'Unable to set reCaptcha accessible attributes';
			}
			
			var input = $document[0].querySelector('#g-recaptcha-response');

			if (!input) {
				$timeout(function() {
					setAttributes(retry + 1);
				}, 1000);
			
				return;
			}
			
			var element = angular.element(input);
			
			element.attr('aria-hidden', 'true');
			element.attr('aria-readonly', 'true');
		}
				
        function setResponse(response) {
            vm.response = response;
        }

        function onCreate(widgetId) {
            vm.widgetId = widgetId;
			
			setAttributes(0);
        }

        function onExpired() {
            vm.response = null;
        }

		function reloadCaptcha() {
			vm.response = null;
			vcRecaptchaService.reload(vm.widgetId);
			
		}
    }
})();