(function () {
    'use strict';

    angular.module('l4b')
        .directive('showFocus', ShowFocus);

    ShowFocus.$inject = ["$timeout"];

    function ShowFocus($timeout) {

    return function(scope, element, attrs) {
        scope.$watch(attrs.showFocus, 
          function (newValue) { 
            $timeout(function() {
                newValue && element[0].focus();
            },100);
          },true);
    };    

    }
})();