(function () {
    'use strict';

    angular.module('l4b')
        .service('TimeoutService', TimeoutService);

    function TimeoutService($rootScope, $timeout, $document, Constants, StorageService, MyAccountService, RestService, FlowService) {
        var self = this;
        var timeOutArray = [];
        var timeOutType = '';

        self.eventBindForCheck = function (bind) {
            var bodyElement = angular.element($document);
            angular.forEach(['keydown', 'keyup', 'click', 'mousemove', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'scroll', 'focus'],
                function (EventName) {
                    bind ? bodyElement.bind(EventName, function () {
                        if (timeOutType == 'MYSERVICE')
                            self.setTimeOutMyService();
                        if (timeOutType == 'KIOSK')
                            self.setTimeOutKiosk();
                    }) : bodyElement.unbind(EventName);
                });
        };

        self.destroyIdle = function () {
            self.cleanTimeOuts();
            self.eventBindForCheck(false);
        };

        self.cleanTimeOuts = function () {
            if (timeOutArray.length > 0) {
                angular.forEach(timeOutArray, function (timer) {
                    $timeout.cancel(timer);
                });
                timeOutArray = [];
            }
        };

        // MY SERVICE TIMEOUT
        self.checkIdle = function () {
            timeOutType = 'MYSERVICE';
            self.setTimeOutMyService();
            self.eventBindForCheck(true);
        };

        self.setTimeOutMyService = function () {
            self.cleanTimeOuts();
            var seconds = StorageService.get(Constants.SK.PublicPlace) ? Constants.SK.PublicPlaceTimeoutSeconds : Constants.SK.PrivatePlaceTimeoutSeconds;
            var timeOutSeconds = seconds * 1000;

            var timer = $timeout(function () {
                StorageService.set(Constants.SK.AirMessageSection, Constants.Section.MyServiceTimeOut);
                $rootScope.$broadcast('show-air-message');
                self.setTimeOutAirMessage();
            }, timeOutSeconds);
            timeOutArray.push(timer);
        };

        self.setTimeOutAirMessage = function () {
            var seconds = Constants.SK.AirMessageTimeoutSeconds;
            var timeOutSeconds = seconds * 1000;

            var timer = $timeout(function () {
                self.logOutMyService();
            }, timeOutSeconds);
            timeOutArray.push(timer);
        };

        self.logOutMyService = function () {
            self.cleanTimeOuts();
            $rootScope.$broadcast('close-air-message');
            $timeout(function () {
                StorageService.set(Constants.SK.AirMessageSection, Constants.Section.MyAccountLogin);
                $rootScope.$broadcast('show-air-message');
            }, 100);
            MyAccountService.LogOut({ stayInCurrentPage: true });
            FlowService.goToLogin();
        };

        // KIOSK ENROLLMENT FLOW TIMEOUT
        self.checkIdleKiosk = function () {
            timeOutType = 'KIOSK';
            self.setTimeOutKiosk();
            self.eventBindForCheck(true);
        };

        self.setTimeOutKiosk = function () {
            self.cleanTimeOuts();
            var seconds = Constants.SK.KioskTimeOutEnrollment;
            var timeOutSeconds = seconds * 1000;
            var timer = $timeout(function () {
                self.destroyIdle();
                StorageService.set(Constants.SK.AirMessageSection, Constants.Section.KioskTimeoutEnrollment);
                $rootScope.$broadcast('show-air-message');
                self.setTimeOutAirMessageKiosk();
            }, timeOutSeconds);

            timeOutArray.push(timer);
        };

        self.setTimeOutAirMessageKiosk = function () {
            var seconds = Constants.SK.AirMessageKioskSeconds;
            var timeOutSeconds = seconds * 1000;

            var timer = $timeout(function () {
                self.logOutKiosk();
            }, timeOutSeconds);
            timeOutArray.push(timer);
        };

        self.logOutKiosk = function () {
            self.cleanTimeOuts();
            $rootScope.$broadcast('close-air-message');
            FlowService.goHome();
        };
    }
})();
