(function () {
    'use strict';

    angular.module('l4b').config(Router);

    Router.$inject = ['$stateProvider', '$urlRouterProvider'];

    function Router($stateProvider, $urlRouterProvider) {

        $stateProvider

            .state('main', {
                url: '',
                templateUrl: 'html/main/main.html',
                abstract: true
            });

        $urlRouterProvider.otherwise(function ($inject, $location) {
            $location.path('/login');
        });
        
    }
})();
